// フッター
// TOPページ・下層ページ共通（〜768px）
.footer,
.footer-top {
  $logo-width: 160px;
  background: $color-primary-2;

  .footer-inner {
    padding: 40px 0;
  }

  .footer-logo {
    width: $logo-width;
    margin: 0 auto;

    .footer-logo-link {
      @include hover-opacity;
    }
  }

  .footer-menu {
    .footer-menu-item {
      .footer-menu-child {
        padding: 0 18px;
        max-height: 0;
        transition: all 0.3s;
        overflow: hidden;
      }

      .footer-menu-child-item {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .footer-menu-child-item-link {
          font-size: 14px;
          line-height: 20px;
          color: $white;
          @include hover-color($color-tertiary);
        }
      }

      .footer-menu-title {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
        color: $white;
        position: relative;
        padding: 20px 0;
        border-bottom: 2px solid $color-primary-3;

        &::before {
          content: '';
          display: block;
          width: 19px;
          height: 11px;
          background: url('../img/icon_arrow_yellow.svg') no-repeat center/cover;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.6s;
        }

        &.is-open {
          &::before {
            transform: translateY(-50%) scaleY(-1);
          }
        }
      }
    }

    .is-open {
      & + .footer-menu-child {
        max-height: 100vh;
        margin: 20px 0;
      }
    }
  }

  .footer-copy {
    font-size: 12px;
    line-height: 1;
    color: $white;
    padding: 14px 0;
    background: $color-primary;
    text-align: center;
  }
}

// 下層ページ特有（769px〜）
.footer {
  $logo-width: 160px;

  .footer-inner {
    padding-bottom: 60px;
    @include mq(md) {
      padding: 80px 0;
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-logo {
    margin-bottom: 20px;
    @include mq(md) {
      margin: 0;
    }
  }

  .footer-menu {
    @include mq(md) {
      width: calc(100% - $logo-width);
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .footer-menu-item {
      @include mq(md) {
        margin-bottom: 50px;
        margin-left: 80px;
      }

      .footer-menu-child {
        @include mq(md) {
          margin-left: 30px;
          padding: 0;
          max-height: none;
        }

        .footer-menu-child-item {
          &:not(:last-child) {
            @include mq(md) {
              margin-bottom: 12px;
            }
          }
        }
      }

      .footer-menu-title {
        @include mq(md) {
          padding: 0 0 0 30px;
          margin-bottom: 16px;
          border: none;
        }

        &::before {
          @include mq(md) {
            width: 20px;
            height: 8px;
            background-image: url('../img/icon_capsule.png');
            right: auto;
            left: 0;
          }
        }
      }
    }
  }
}

// TOPページ特有（769px〜）
.footer-top {
  .footer-menu {
    .footer-menu-item {
      .footer-menu-title {
        @include mq(md) {
          cursor: pointer;
        }
      }
    }
  }
}
