@use 'sass:math';

// メディアクエリ
$breakpoints: (
  'xs': 'screen and (min-width: 375px)',
  'sm': 'screen and (min-width: 426px)',
  'top': 'screen and (min-width: 540px)',
  'md': 'screen and (min-width: 769px)',
  'lg': 'screen and (min-width: 1000px)',
  'xl': 'screen and (min-width: 1200px)',
  'xxl': 'screen and (min-width: 1400px)',

  // 医療機関さま向けのサービス紹介ページ
  'max-xs': 'screen and (max-width: 374px)',
  'max-sm': 'screen and (max-width: 767px)',
  'max-md': 'screen and (max-width: 768px)',
  'max-lg': 'screen and (max-width: 896px)',
  'max-xl': 'screen and (max-width: 1024px)',
  'max-xxl': 'screen and (max-width: 1292px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// em
@function em($px, $fontSize: 16) {
  @return calc($px / $fontSize) * 1em;
}

// font-size
@mixin font-size($size, $base: 16) {
  font-size: math.div($size, $base) + rem;
}

// vw
$pc-base-width: 1400;
$sp-base-width: 375;
@function vwpc($pxPc) {
  @return calc($pxPc / $pc-base-width) * 100vw;
}
@function vwsp($pxSp) {
  @return calc($pxSp / $sp-base-width) * 100vw;
}

// transition
@mixin transition($seconds: 0.6s) {
  transition: ease $seconds;
}

// title
@mixin title-ribon($height, $color) {
  line-height: $height;
  background: $color;
  position: relative;
  display: inline-block;
  padding: 0 21px;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-color: $color transparent;
    border-style: solid;
    content: '';
  }

  &::before {
    left: -18px;
    border-width: calc($height / 2) 0 calc($height / 2) 18px;
  }

  &::after {
    right: -18px;
    border-width: calc($height / 2) 18px calc($height / 2) 0;
  }
}

@mixin title-line {
  position: relative;
  text-align: center;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
  }

  &::before {
    background: url('../img/icon_headline_line_left.svg') no-repeat
      center/contain;
    left: 0;
  }

  &::after {
    background: url('../img/icon_headline_line_right.svg') no-repeat
      center/contain;
    right: 0;
  }
}

// text
@mixin text-note($fontSize: 16px) {
  font-size: $fontSize;
  line-height: 1.5;
  position: relative;
  padding-left: $fontSize;

  &::before {
    content: '※';
    position: absolute;
    left: 0;
    top: 0;
  }
}

// panel
@mixin panel-border($borderWidth, $spaceWidth) {
  position: relative;
  background: $white;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    border-color: #333;
    z-index: -1;
  }

  &::before {
    width: calc(100% - 20px);
    height: calc(100% - 2px);
    border-width: 2px 0;
    top: -1px;
    left: 10px;
    @include mq(md) {
      width: calc(100% - $spaceWidth * 2);
      height: calc(100% - $borderWidth);
      border-width: $borderWidth 0;
      top: calc(-1 * $borderWidth / 2);
      left: $spaceWidth;
    }
  }

  &::after {
    width: calc(100% - 2px);
    height: calc(100% - 20px);
    border-width: 0 2px;
    top: 10px;
    left: -1px;
    @include mq(md) {
      width: calc(100% - $borderWidth);
      height: calc(100% - $spaceWidth * 2);
      border-width: 0 $borderWidth;
      top: $spaceWidth;
      left: calc(-1 * $borderWidth / 2);
    }
  }
}

@mixin panel-lead {
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin panel-lead-title {
  font-weight: bold;
  padding-bottom: 34px;
  margin-bottom: 18px;
  position: relative;
  text-align: center;
  @include mq(md) {
    padding: 0 11px 0 0;
    margin: 0 40px 0 0;
    text-align: left;
  }

  &::after {
    content: '';
    display: block;
    width: 126px;
    height: 12px;
    background: url('../img/icon_headline_tail_green.svg') no-repeat
      center/contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include mq(md) {
      left: auto;
      bottom: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(45%) rotate(-90deg);
    }
  }
}

@mixin panel-lead-text {
  font-size: 18px;
  line-height: 1.89;
  padding: 0 vwsp(8);
  margin-bottom: 30px;
  @include mq(md) {
    flex: 1 1 0;
    padding: 0;
    margin-bottom: 0;
  }
}

// button
@mixin btn($bgColor, $shadowColor, $textColor) {
  background: $bgColor;
  color: $textColor;
  font-weight: bold;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @include transition;
  box-shadow: 0 5px 0 0 $shadowColor;

  &:hover {
    @include mq(md) {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}

@mixin btn-border($color) {
  color: $color;
  box-shadow: 0 5px 0 0 $color;
  border: 3px solid $color;
  font-weight: bold;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @include transition;

  &:hover {
    @include mq(md) {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}

// hover
@mixin hover-color($color) {
  @include transition;

  &:hover {
    @include mq(md) {
      color: $color;
    }
  }
}

@mixin hover-opacity($opacity: 0.6) {
  @include transition;

  &:hover {
    @include mq(md) {
      opacity: $opacity;
    }
  }
}

// 保険薬局さま向けのサービス紹介ページ
@mixin service-title-large {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  @include mq(md) {
    font-size: 46px;
    margin-bottom: 42px;
  }
}

@mixin service-text-detail {
  font-size: 20px;
  line-height: 1.85;
  font-weight: bold;
  @include mq(md) {
    font-size: 22px;
    line-height: 2;
  }
}

// 医療機関さま向けのサービス紹介ページ
@mixin service-title-dot {
  @include font-size(40);
  letter-spacing: 1.6px;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
  padding-bottom: 32px;
  text-align: center;
  @include mq(max-md) {
    @include font-size(24);
    line-height: 1.4;
    letter-spacing: 1.2px;
    padding-bottom: 22px;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 68px;
    height: 12px;
    background: url('../img/title_dots.svg') no-repeat center/cover;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include mq(max-md) {
      width: 55px;
      height: 10px;
    }
  }
}

@mixin service-list-circle {
  display: flex;
  flex-direction: column;
  gap: 26px 0;

  li {
    @include font-size(24);
    letter-spacing: 0.96px;
    font-weight: 700;
    position: relative;
    padding-left: 40px;
    @include mq(max-md) {
      @include font-size(16);
      padding-left: 31px;
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      border-radius: 50%;
      position: absolute;
    }

    &::before {
      width: 28px;
      height: 28px;
      background: $color-primary-2;
      top: 5px;
      left: 0;
      @include mq(max-md) {
        width: 23px;
        height: 23px;
        top: 1px;
      }
    }

    &::after {
      width: 16px;
      height: 16px;
      background: $white;
      top: 11px;
      left: 6px;
      @include mq(max-md) {
        width: 13px;
        height: 13px;
        top: 6px;
        left: 5px;
      }
    }
  }
}

@mixin service-list-cross($pattern: null) {
  display: flex;
  flex-direction: column;
  gap: 32px 0;

  li {
    @include font-size(24);
    letter-spacing: 0.96px;
    font-weight: 700;
    position: relative;
    padding-left: 50px;
    @include mq(max-md) {
      @include font-size(18);
      letter-spacing: 1.08px;
      padding-left: 31px;
    }

    &::before {
      content: '';
      width: 34px;
      height: 34px;
      background: url('../img/icon_cross.svg') no-repeat center/cover;
      position: absolute;
      top: 2px;
      left: 0;
      @include mq(max-md) {
        width: 23px;
        height: 23px;
      }
    }

    .item-detail {
      @include font-size(18);
      line-height: 1.8;
      letter-spacing: 1.08px;
      font-weight: 500;
      margin-top: 8px;
      @include mq(max-md) {
        @include font-size(14);
        text-align: left;
        letter-spacing: 0.84px;
      }
    }
  }
  @if $pattern == 'small' {
    li {
      padding-left: 30px;

      &::before {
        width: 20px;
        height: 20px;
        top: 8px;
        @include mq(max-md) {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
}

@mixin service-panel($pattern: null, $width: null) {
  background: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 960px;
  box-sizing: border-box;
  margin: 0 auto;

  @if $pattern == 'border' {
    border: 3px solid $color-text;
    border-radius: 12px;
  }

  @if $width == 'wide' {
    padding: 80px 61px;
    @include mq(max-xxl) {
      padding: 80px vwpc(61);
    }
    @include mq(max-md) {
      padding: 32px vwsp(21);
    }
  } @else if $width == 'medium' {
    padding: 80px 77px;
    @include mq(max-xxl) {
      padding: 80px vwpc(77);
    }
    @include mq(max-md) {
      padding: 32px vwsp(21);
    }
  } @else if $width == 'narrow' {
    padding: 80px;
    @include mq(max-xxl) {
      padding: 80px vwpc(80);
    }
    @include mq(max-md) {
      padding: 80px vwpc(80);
    }
  }
}

@mixin service-graph {
  border: 2px solid #eaeaea;
  border-radius: 8px;
  background: url('../img/service/medical_institution/bg_plaid.png') center top -1px/25px
    auto;
  @include mq(max-md) {
    background-size: 17px auto;
  }

  .graph-figcaption {
    font-weight: 700;
    letter-spacing: 0.96px;
    text-align: center;
    margin-top: 39px;
    @include mq(max-md) {
      @include font-size(14);
      line-height: 1.6;
      letter-spacing: 0.72px;
      margin-top: 16px;
    }
    @include mq(max-sm) {
      @include font-size(12);
    }
  }
}

@mixin service-balloon($pattern) {
  border: 3px solid $color-text;
  border-radius: 12px;
  position: relative;
  background: #fff;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-style: solid;
    @include mq(max-md) {
      top: auto;
      bottom: 0;
      right: auto;
      left: 50%;
      translate: -50% 100%;
    }
  }
  @if $pattern == 'bottom' {
    &::before,
    &::after {
      bottom: 0;
      translate: -50% 100%;
    }

    &::before {
      border-width: 12px 10px 0;
      border-color: $color-text transparent transparent;
    }

    &::after {
      border-width: 7.3px 6.1px 0;
      border-color: #fff transparent transparent;
    }
  } @else if $pattern == 'right' {
    &::before,
    &::after {
      top: 50%;
      right: 0;
      translate: 100% -50%;
    }

    &::before {
      border-width: 10px 0 10px 12px;
      border-color: transparent transparent transparent $color-text;
    }

    &::after {
      border-width: 6.1px 0 6.1px 7.3px;
      border-color: transparent transparent transparent #fff;
    }
  } @else if $pattern == 'left' {
    &::before,
    &::after {
      top: 50%;
      left: 0;
      translate: -100% -50%;
    }

    &::before {
      border-width: 10px 12px 10px 0;
      border-color: transparent $color-text transparent transparent;
    }

    &::after {
      border-width: 6.1px 7.3px 6.1px 0;
      border-color: transparent #fff transparent transparent;
    }
  }
}

@mixin service-section($pattern: null) {
  padding: 80px 0 120px;
  @include mq(max-md) {
    padding: 64px 0;
  }
  @if $pattern == 'btn-above' {
    padding-bottom: 158px;
    @include mq(max-xl) {
      padding-bottom: 201px;
    }
    @include mq(max-md) {
      padding-bottom: 140px;
    }
    @include mq(max-sm) {
      padding-bottom: 201px;
    }
  } @else if $pattern == 'btn-below' {
    margin-top: -69px;
    padding-top: 152px;
    @include mq(max-md) {
      margin-top: -62px;
      padding-top: 128px;
    }
  }
}
