// ヘッダー
// TOPページ・下層ページ共通（〜768px）
.header,
.header-top {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: ease 0.4s;

  &.is-scroll {
    background: rgba($color-secondary, 0.9);
  }

  &.is-open {
    background: $color-secondary;
  }

  .header-inner {
    margin: 0 auto;
    padding: 0 vwsp(20);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: inherit;
    position: relative;
  }

  .header-logo {
    width: 116px;
  }

  .header-nav {
    position: absolute;
    right: 0;
    left: 0;
    top: 50px;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    background: $color-secondary;
    transition: ease 0.4s;
    background-image: linear-gradient(
      to right,
      $color-primary,
      $color-primary 2px,
      transparent 2px,
      transparent 12px
    );
    background-size: 12px 2px;
    background-position: left top;
    background-repeat: repeat-x;

    .header-nav-menu {
      .menu-item {
        background-image: linear-gradient(
          to right,
          $color-primary,
          $color-primary 2px,
          transparent 2px,
          transparent 12px
        );
        background-size: 12px 2px;
        background-position: left bottom;
        background-repeat: repeat-x;

        .menu-item-inner {
          padding: 30px vwsp(20);
        }

        .menu-item-link {
          display: block;
          font-size: 16px;
          font-weight: bold;
          color: $color-text;
          @include hover-color($color-primary);

          &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 15px;
            background: url('../img/icon_arrow.svg') no-repeat center/cover;
            margin-right: 12px;
          }
        }

        .menu-item-headline {
          font-size: 15px;
          line-height: 23px;
          font-weight: bold;
          margin-bottom: 20px;
          text-align: center;
          cursor: pointer;
          @include hover-color($color-primary);
        }

        .icon-headline-line {
          @include title-line;
          padding: 0 25px;

          &::before,
          &::after {
            width: 12px;
            height: 22px;
          }
        }
      }
    }
  }

  .header-toggle {
    position: absolute;
    right: vwsp(20);
    top: 9px;
    width: 26px;
    height: 19px;
    margin-right: 2px;

    span {
      width: 100%;
      height: 3px;
      background-color: $color-text;
      border-radius: 10px;
      transition: ease 0.4s;
      display: block;

      &::before,
      &::after {
        content: '';
        height: 3px;
        display: block;
        position: absolute;
        left: 0;
        background-color: $color-text;
        border-radius: 10px;
        transition: ease 0.4s;
      }

      &::before {
        top: 0;
        width: 100%;
      }

      &::after {
        bottom: 0;
        width: 60%;
      }
    }

    .toggle-menu,
    .toggle-close {
      font-size: 11px;
      font-weight: 600;
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      transition: ease 0.4s;
    }

    .toggle-close {
      display: none;
    }

    &.is-open {
      span {
        background: transparent;

        &::before,
        &::after {
          top: 9px;
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          width: 100%;
          transform: rotate(45deg);
        }
      }

      .toggle-menu {
        display: none;
      }

      .toggle-close {
        display: block;
      }
    }
  }
}

// 下層ページ特有（769px〜）
.header {
  @include mq(md) {
    height: 80px;
  }

  .header-logo {
    width: 116px;
    @include mq(md) {
      width: 12vw;
    }
    @include mq(lg) {
      width: 120px;
    }
    @include mq(xl) {
      width: 162px;
    }
  }

  .header-inner {
    box-sizing: border-box;
    max-width: 1400px;
    @include mq(md) {
      padding: 0 32px;
    }
  }

  .header-toggle {
    @include mq(md) {
      display: none;
    }
  }

  .header-nav {
    @include mq(md) {
      position: static;
      width: auto;
      height: auto;
      transform: none;
      display: flex;
      align-items: center;
      background: transparent;
    }

    .header-nav-menu {
      @include mq(md) {
        background: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 0 2vw;
      }
      @include mq(lg) {
        gap: 0 20px;
      }
      @include mq(xl) {
        gap: 0 30px;
      }

      .menu-item {
        @include mq(md) {
          background: none;
          position: relative;
        }

        .menu-item-inner {
          @include mq(md) {
            padding: 0;
          }
        }

        .menu-item-link {
          @include mq(md) {
            font-size: 1.4vw;
          }
          @include mq(lg) {
            font-size: 14px;
          }
          @include mq(xl) {
            font-size: 16px;
          }

          &::before {
            @include mq(md) {
              display: none;
            }
          }
        }

        .menu-item-headline {
          @include mq(xs) {
            font-size: 18px;
          }
          @include mq(md) {
            font-size: 1.4vw;
            line-height: 1;
            margin-bottom: 0;
            padding: 20px 0;
          }
          @include mq(lg) {
            font-size: 14px;
          }
          @include mq(xl) {
            font-size: 16px;
          }
        }

        .icon-headline-line {
          @include mq(xs) {
            padding: 0 32px;
          }
          @include mq(md) {
            padding: 0;
          }

          &::before,
          &::after {
            @include mq(xs) {
              width: 15px;
              height: 25px;
            }
            @include mq(md) {
              display: none;
            }
          }
        }

        .header-nav-menu-child {
          &::before,
          &::after {
            @include mq(md) {
              content: '';
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &::before {
            @include mq(md) {
              border: 10px solid transparent;
              border-bottom: 11px solid $white;
              z-index: 2;
            }
          }

          &::after {
            @include mq(md) {
              border: 12px solid transparent;
              border-bottom: 13px solid $color-primary-2;
              z-index: 1;
            }
          }

          &.list-service {
            @include mq(md) {
              display: block;
              width: 160px;
              max-width: none;
              position: absolute;
              left: 50%;
              top: 100%;
              padding: 30px;
              background: $white;
              border: 1px solid $color-primary-2;
              border-radius: 5px;
              box-shadow: 0 0 6px 0 rgba($color-primary-2, 0.4);
              transition: all 0.6s;
              transform: translateX(-50%) scaleY(0);
              transform-origin: center top;
            }

            .service-item {
              @include mq(md) {
                width: 100%;
              }

              &:not(:last-child) {
                @include mq(md) {
                  margin: 0 0 20px;
                }
              }

              &.item-pharmacy {
                @include mq(md) {
                  max-width: 160px;
                  margin-top: 0;
                }

                .service-item-link {
                  @include mq(md) {
                    height: 75px;
                    flex-direction: column;
                  }
                }

                .service-item-icon {
                  @include mq(md) {
                    margin: 0 0 8px;
                  }
                }
              }
            }
          }
        }

        &:hover {
          .header-nav-menu-child {
            @include mq(md) {
              transform: translateX(-50%) scaleY(1);
            }
          }
        }
      }
    }
  }

  .is-open + .header-nav {
    transform: translateX(0);
  }
}
