@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  color: #333;
  background: #f6f6f6;
  font-family: Noto Sans JP, sans-serif;
}

a {
  color: #0066c0;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
}

sup {
  vertical-align: top;
  font-size: 60%;
  position: relative;
  top: -.3125em;
}

wbr + span {
  display: inline-block;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5.33333vw;
}

@media screen and (width >= 769px) {
  .container-pc {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 5.33333vw;
  }
}

.container-md {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5.33333vw;
}

@media screen and (width >= 769px) {
  .container-md {
    padding: 0;
  }
}

.visible-md {
  display: none;
}

@media screen and (width >= 769px) {
  .visible-md {
    display: block;
  }
}

.visible-xs {
  display: none;
}

@media screen and (width >= 375px) {
  .visible-xs {
    display: block;
  }
}

@media screen and (width >= 769px) {
  .hidden-md {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.offset-header {
  padding-top: 60px;
}

@media screen and (width >= 769px) {
  .offset-header {
    padding-top: 80px;
  }
}

.fc-primary {
  color: #4b9c84;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text {
  font-size: 16px;
  line-height: 31px;
}

@media screen and (width >= 769px) {
  .text {
    font-size: 18px;
    line-height: 60px;
  }
}

.text-marker-orange {
  background: linear-gradient(#0000 0% 55%, #f7c9b8 55% 100%);
  padding-bottom: 2px;
}

.link-green {
  color: #4b9c84;
  transition: all .6s;
}

@media screen and (width >= 769px) {
  .link-green:hover {
    opacity: .7;
  }
}

.link-underline {
  text-decoration: underline;
}

.link-underline:hover {
  text-decoration: none;
}

.anchor-link {
  color: #333;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.anchor-link:hover {
  color: #4b9c84;
}

.anchor-link:before {
  content: "";
  background-image: url("arrow_bottom.930580b2.svg");
  width: 17px;
  height: 11px;
  margin-right: 10px;
  display: block;
}

.table {
  margin: 25px 0 20px;
}

.table th, .table td {
  word-break: break-all;
  border: 1px solid #333;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.table thead {
  background-color: #97c4b9;
}

.table thead th {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  border-bottom: none;
  font-weight: bold;
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.table tbody tr:nth-child(2n) {
  background-color: #fff;
}

.panel-white {
  background: #fff;
}

[class^="icon-deco-"] {
  z-index: 2;
  position: absolute;
}

[class^="icon-deco-tablet-"] {
  width: 37px;
}

@media screen and (width >= 769px) {
  [class^="icon-deco-tablet-"] {
    width: 4.07143vw;
  }
}

@media screen and (width >= 1400px) {
  [class^="icon-deco-tablet-"] {
    width: 57px;
  }
}

[class^="icon-deco-capsule-"] {
  width: 60px;
}

@media screen and (width >= 769px) {
  [class^="icon-deco-capsule-"] {
    width: 6.42857vw;
  }
}

@media screen and (width >= 1400px) {
  [class^="icon-deco-capsule-"] {
    width: 90px;
  }
}

.list-number {
  margin-top: .625em;
  margin-left: 1.5em;
  list-style-type: decimal;
}

.list-number > li {
  padding-left: .5em;
}

.list-number-parentheses {
  margin-top: .625em;
  margin-left: 1.3em;
}

.list-number-parentheses > li {
  counter-increment: cnt-parentheses;
  padding-left: .5em;
  position: relative;
}

.list-number-parentheses > li:before {
  content: "(" counter(cnt-parentheses) ")";
  text-align: right;
  width: 3em;
  margin-left: -3em;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.list-number-round {
  margin-top: .625em;
}

.list-number-round > li {
  counter-increment: cnt-round;
  padding-left: 1.5em;
  position: relative;
}

.list-number-round > li:before {
  content: counter(cnt-round);
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.3em;
  height: 1.3em;
  font-size: 85%;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.list-disc {
  margin-top: .625em;
  margin-left: 1.5em;
  list-style-type: disc;
}

.list-service {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
  display: flex;
}

.list-service .service-item {
  width: 100%;
}

.list-service .service-item .service-item-link {
  background: #4b9c84;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 75px;
  margin-bottom: 5px;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #327763;
}

.list-service .service-item .service-item-link:hover {
  background: #327763;
}

.list-service .service-item .service-item-icon {
  margin-bottom: 8px;
}

.list-service .service-item .service-item-text {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.list-service .service-item.item-hospital, .list-service .service-item.item-clinic {
  max-width: 42.667vw;
}

@media screen and (width >= 375px) {
  .list-service .service-item.item-hospital, .list-service .service-item.item-clinic {
    max-width: 160px;
  }
}

.list-service .service-item.item-hospital .service-item-link, .list-service .service-item.item-clinic .service-item-link {
  flex-direction: column;
}

.list-service .service-item.item-hospital {
  margin-right: 4vw;
}

@media screen and (width >= 375px) {
  .list-service .service-item.item-hospital {
    margin-right: 15px;
  }
}

.list-service .service-item.item-hospital .service-item-icon {
  width: 37px;
}

.list-service .service-item.item-clinic .service-item-icon {
  width: 22px;
}

.list-service .service-item.item-pharmacy {
  margin-top: 10px;
}

.list-service .service-item.item-pharmacy .service-item-link {
  height: 50px;
}

.list-service .service-item.item-pharmacy .service-item-icon {
  width: 26px;
  margin: 0 10px 0 -36px;
}

.btn-search {
  color: #333;
  letter-spacing: 2.4px;
  background: #f4e276;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  max-width: 335px;
  height: 55px;
  margin: 0 auto;
  font-size: 6.66667vw;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #bfb470;
}

@media screen and (width >= 769px) {
  .btn-search:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

@media screen and (width >= 375px) {
  .btn-search {
    font-size: 25px;
  }
}

.btn-install {
  color: #333;
  letter-spacing: 2.4px;
  background: #f4e276;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  max-width: 295px;
  height: 55px;
  margin: 0 auto;
  font-size: 5.33333vw;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #bfb470;
}

@media screen and (width >= 769px) {
  .btn-install:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

@media screen and (width >= 375px) {
  .btn-install {
    font-size: 20px;
  }
}

.btn-back {
  color: #42866d;
  border: 3px solid #42866d;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  max-width: 285px;
  height: 65px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #42866d;
}

@media screen and (width >= 769px) {
  .btn-back:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

[class^="btn-request"] {
  flex-direction: column;
  max-width: 610px;
  height: 24vw;
  font-size: 5.86667vw;
}

@media screen and (width >= 375px) {
  [class^="btn-request"] {
    height: 90px;
    font-size: 22px;
  }
}

@media screen and (width >= 769px) {
  [class^="btn-request"] {
    flex-direction: row;
    height: 70px;
    font-size: 26px;
  }
}

[class^="btn-request"] .logo {
  margin: -1.33333vw 0 1.6vw;
}

@media screen and (width >= 375px) {
  [class^="btn-request"] .logo {
    margin: -5px 0 6px;
  }
}

@media screen and (width >= 769px) {
  [class^="btn-request"] .logo {
    margin: -5px 8px 0 0;
  }
}

.btn-request-yakubato {
  color: #333;
  background: #f4e276;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #bfb470;
}

@media screen and (width >= 769px) {
  .btn-request-yakubato:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.btn-request-yakubato .logo {
  width: 33.3333vw;
}

@media screen and (width >= 375px) {
  .btn-request-yakubato .logo {
    width: 125px;
  }
}

@media screen and (width >= 769px) {
  .btn-request-yakubato .logo {
    width: 154px;
  }
}

.btn-request-yakubato-hospital {
  color: #fff;
  background: #4b9c84;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #327763;
}

@media screen and (width >= 769px) {
  .btn-request-yakubato-hospital:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.btn-request-yakubato-pharmacy {
  color: #333;
  background: #f4e276;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #bfb470;
}

@media screen and (width >= 769px) {
  .btn-request-yakubato-pharmacy:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.btn-request-yakubato-hospital .logo, .btn-request-yakubato-pharmacy .logo {
  width: 61.0667vw;
}

@media screen and (width >= 375px) {
  .btn-request-yakubato-hospital .logo, .btn-request-yakubato-pharmacy .logo {
    width: 229px;
  }
}

@media screen and (width >= 769px) {
  .btn-request-yakubato-hospital .logo, .btn-request-yakubato-pharmacy .logo {
    width: 283px;
  }
}

.btn-download:after, .btn-contact:after {
  content: "";
  width: 32px;
  height: 32px;
  margin-left: 4px;
}

@media screen and (width <= 768px) {
  .btn-download:after, .btn-contact:after {
    width: 28px;
    height: 28px;
  }
}

.btn-download {
  color: #fff;
  letter-spacing: 1.32px;
  background: #4b9c84;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 70px;
  margin: 0 auto 5px;
  font-size: 1.375rem;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #327763;
}

@media screen and (width >= 769px) {
  .btn-download:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

@media screen and (width <= 768px) {
  .btn-download {
    letter-spacing: 1.08px;
    width: 320px;
    height: 56px;
    font-size: 1.125rem;
  }
}

@media screen and (width <= 767px) {
  .btn-download {
    width: 100%;
    max-width: 320px;
  }
}

.btn-download:after {
  background: url("icon_download_white.e8259e69.svg") center / cover no-repeat;
}

.btn-contact {
  color: #333;
  letter-spacing: 1.32px;
  background: #f4e276;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 70px;
  margin: 0 auto 5px;
  font-size: 1.375rem;
  font-weight: bold;
  transition: all .6s;
  display: flex;
  box-shadow: 0 5px #bfb470;
}

@media screen and (width >= 769px) {
  .btn-contact:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

@media screen and (width <= 768px) {
  .btn-contact {
    letter-spacing: 1.08px;
    width: 320px;
    height: 56px;
    font-size: 1.125rem;
  }
}

@media screen and (width <= 767px) {
  .btn-contact {
    width: 100%;
    max-width: 320px;
  }
}

.btn-contact:after {
  background: url("icon_contact.1585440a.svg") center / cover no-repeat;
}

.btn-contact-text {
  letter-spacing: .0625em;
  text-align: center;
  margin-top: 15px;
  font-size: 1rem;
}

@media screen and (width <= 768px) {
  .btn-contact-text {
    margin-top: 11px;
    font-size: .875rem;
  }
}

@media screen and (width <= 374px) {
  .btn-contact-text {
    font-size: 3.733vw;
  }
}

.header, .header-top {
  z-index: 999;
  width: 100%;
  height: 50px;
  transition: all .4s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header.is-scroll, .header-top.is-scroll {
  background: #f6f6f6e6;
}

.header.is-open, .header-top.is-open {
  background: #f6f6f6;
}

.header .header-inner, .header-top .header-inner {
  height: inherit;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 5.33333vw;
  display: flex;
  position: relative;
}

.header .header-logo, .header-top .header-logo {
  width: 116px;
}

.header .header-nav, .header-top .header-nav {
  background: #f6f6f6 linear-gradient(to right, #42866d, #42866d 2px, #0000 2px 12px) 0 0 / 12px 2px repeat-x;
  width: 100%;
  height: 100vh;
  transition: all .4s;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  transform: translateX(100%);
}

.header .header-nav .header-nav-menu .menu-item, .header-top .header-nav .header-nav-menu .menu-item {
  background-image: linear-gradient(to right, #42866d, #42866d 2px, #0000 2px 12px);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 12px 2px;
}

.header .header-nav .header-nav-menu .menu-item .menu-item-inner, .header-top .header-nav .header-nav-menu .menu-item .menu-item-inner {
  padding: 30px 5.33333vw;
}

.header .header-nav .header-nav-menu .menu-item .menu-item-link, .header-top .header-nav .header-nav-menu .menu-item .menu-item-link {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  transition: all .6s;
  display: block;
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-link:hover, .header-top .header-nav .header-nav-menu .menu-item .menu-item-link:hover {
    color: #42866d;
  }
}

.header .header-nav .header-nav-menu .menu-item .menu-item-link:before, .header-top .header-nav .header-nav-menu .menu-item .menu-item-link:before {
  content: "";
  background: url("icon_arrow.71290273.svg") center / cover no-repeat;
  width: 10px;
  height: 15px;
  margin-right: 12px;
  display: inline-block;
}

.header .header-nav .header-nav-menu .menu-item .menu-item-headline, .header-top .header-nav .header-nav-menu .menu-item .menu-item-headline {
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
  line-height: 23px;
  transition: all .6s;
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-headline:hover, .header-top .header-nav .header-nav-menu .menu-item .menu-item-headline:hover {
    color: #42866d;
  }
}

.header .header-nav .header-nav-menu .menu-item .icon-headline-line, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line {
  text-align: center;
  padding: 0 25px;
  position: relative;
}

.header .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header .header-nav .header-nav-menu .menu-item .icon-headline-line:after, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.header .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:before {
  background: url("icon_headline_line_left.0dda61f7.svg") center / contain no-repeat;
  left: 0;
}

.header .header-nav .header-nav-menu .menu-item .icon-headline-line:after, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
  background: url("icon_headline_line_right.1d7ee239.svg") center / contain no-repeat;
  right: 0;
}

.header .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header .header-nav .header-nav-menu .menu-item .icon-headline-line:after, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
  width: 12px;
  height: 22px;
}

.header .header-toggle, .header-top .header-toggle {
  width: 26px;
  height: 19px;
  margin-right: 2px;
  position: absolute;
  top: 9px;
  right: 5.33333vw;
}

.header .header-toggle span, .header-top .header-toggle span {
  background-color: #333;
  border-radius: 10px;
  width: 100%;
  height: 3px;
  transition: all .4s;
  display: block;
}

.header .header-toggle span:before, .header .header-toggle span:after, .header-top .header-toggle span:before, .header-top .header-toggle span:after {
  content: "";
  background-color: #333;
  border-radius: 10px;
  height: 3px;
  transition: all .4s;
  display: block;
  position: absolute;
  left: 0;
}

.header .header-toggle span:before, .header-top .header-toggle span:before {
  width: 100%;
  top: 0;
}

.header .header-toggle span:after, .header-top .header-toggle span:after {
  width: 60%;
  bottom: 0;
}

.header .header-toggle .toggle-menu, .header .header-toggle .toggle-close, .header-top .header-toggle .toggle-menu, .header-top .header-toggle .toggle-close {
  font-size: 11px;
  font-weight: 600;
  transition: all .4s;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
}

.header .header-toggle .toggle-close, .header-top .header-toggle .toggle-close {
  display: none;
}

.header .header-toggle.is-open span, .header-top .header-toggle.is-open span {
  background: none;
}

.header .header-toggle.is-open span:before, .header .header-toggle.is-open span:after, .header-top .header-toggle.is-open span:before, .header-top .header-toggle.is-open span:after {
  top: 9px;
}

.header .header-toggle.is-open span:before, .header-top .header-toggle.is-open span:before {
  transform: rotate(-45deg);
}

.header .header-toggle.is-open span:after, .header-top .header-toggle.is-open span:after {
  width: 100%;
  transform: rotate(45deg);
}

.header .header-toggle.is-open .toggle-menu, .header-top .header-toggle.is-open .toggle-menu {
  display: none;
}

.header .header-toggle.is-open .toggle-close, .header-top .header-toggle.is-open .toggle-close {
  display: block;
}

@media screen and (width >= 769px) {
  .header {
    height: 80px;
  }
}

.header .header-logo {
  width: 116px;
}

@media screen and (width >= 769px) {
  .header .header-logo {
    width: 12vw;
  }
}

@media screen and (width >= 1000px) {
  .header .header-logo {
    width: 120px;
  }
}

@media screen and (width >= 1200px) {
  .header .header-logo {
    width: 162px;
  }
}

.header .header-inner {
  box-sizing: border-box;
  max-width: 1400px;
}

@media screen and (width >= 769px) {
  .header .header-inner {
    padding: 0 32px;
  }

  .header .header-toggle {
    display: none;
  }

  .header .header-nav {
    background: none;
    align-items: center;
    width: auto;
    height: auto;
    display: flex;
    position: static;
    transform: none;
  }

  .header .header-nav .header-nav-menu {
    background: none;
    align-items: center;
    gap: 0 2vw;
    margin-bottom: 0;
    display: flex;
  }
}

@media screen and (width >= 1000px) {
  .header .header-nav .header-nav-menu {
    gap: 0 20px;
  }
}

@media screen and (width >= 1200px) {
  .header .header-nav .header-nav-menu {
    gap: 0 30px;
  }
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item {
    background: none;
    position: relative;
  }

  .header .header-nav .header-nav-menu .menu-item .menu-item-inner {
    padding: 0;
  }

  .header .header-nav .header-nav-menu .menu-item .menu-item-link {
    font-size: 1.4vw;
  }
}

@media screen and (width >= 1000px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-link {
    font-size: 14px;
  }
}

@media screen and (width >= 1200px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-link {
    font-size: 16px;
  }
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-link:before {
    display: none;
  }
}

@media screen and (width >= 375px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-headline {
    font-size: 18px;
  }
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-headline {
    margin-bottom: 0;
    padding: 20px 0;
    font-size: 1.4vw;
    line-height: 1;
  }
}

@media screen and (width >= 1000px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-headline {
    font-size: 14px;
  }
}

@media screen and (width >= 1200px) {
  .header .header-nav .header-nav-menu .menu-item .menu-item-headline {
    font-size: 16px;
  }
}

@media screen and (width >= 375px) {
  .header .header-nav .header-nav-menu .menu-item .icon-headline-line {
    padding: 0 32px;
  }
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item .icon-headline-line {
    padding: 0;
  }
}

@media screen and (width >= 375px) {
  .header .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
    width: 15px;
    height: 25px;
  }
}

@media screen and (width >= 769px) {
  .header .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
    display: none;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child:before, .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child:before {
    z-index: 2;
    border: 10px solid #0000;
    border-bottom: 11px solid #fff;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child:after {
    z-index: 1;
    border: 12px solid #0000;
    border-bottom: 13px solid #4b9c84;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service {
    transform-origin: top;
    background: #fff;
    border: 1px solid #4b9c84;
    border-radius: 5px;
    width: 160px;
    max-width: none;
    padding: 30px;
    transition: all .6s;
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%)scaleY(0);
    box-shadow: 0 0 6px #4b9c8466;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item {
    width: 100%;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item:not(:last-child) {
    margin: 0 0 20px;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-pharmacy {
    max-width: 160px;
    margin-top: 0;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-pharmacy .service-item-link {
    flex-direction: column;
    height: 75px;
  }

  .header .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-pharmacy .service-item-icon {
    margin: 0 0 8px;
  }

  .header .header-nav .header-nav-menu .menu-item:hover .header-nav-menu-child {
    transform: translateX(-50%)scaleY(1);
  }
}

.header .is-open + .header-nav {
  transform: translateX(0);
}

@media screen and (width >= 1000px) {
  .header-top {
    z-index: 1;
    height: 100%;
    position: static;
  }
}

@media screen and (width >= 769px) {
  .header-top.is-scroll {
    background: none;
  }

  .header-top .header-inner {
    padding: 0 2.14286vw;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-logo {
    width: 199px;
    position: absolute;
    top: 30px;
    left: 0;
  }

  .header-top .header-toggle {
    display: none;
  }

  .header-top .header-nav {
    background: none;
    width: 100%;
    height: 100%;
    position: static;
    transform: none;
  }

  .header-top .header-nav .header-nav-right {
    margin-bottom: 303px;
    position: absolute;
    bottom: 0;
    right: 32px;
  }

  .header-top .header-nav .header-nav-right:after {
    content: "";
    z-index: -1;
    background: url("menu_nomal_bg.ec590a6c.png") center / cover no-repeat;
    width: 295px;
    height: 401px;
    position: absolute;
    top: -68px;
    right: -55px;
  }

  .header-top .header-nav .header-nav-right .header-nav-right-menu {
    margin-bottom: 329px;
    position: relative;
  }
}

.header-top .header-nav .header-nav-right .header-nav-right-title {
  display: none;
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-right .header-nav-right-title {
    color: #4b9c84;
    text-align: center;
    margin-bottom: 23px;
    font-size: 24px;
    font-weight: bold;
    display: block;
  }

  .header-top .header-nav .header-nav-left {
    position: absolute;
    top: 120px;
    left: -15px;
  }
}

@media screen and (width >= 1100px) {
  .header-top .header-nav .header-nav-left {
    top: 127px;
    left: 1vw;
  }
}

@media screen and (width >= 1300px) {
  .header-top .header-nav .header-nav-left {
    left: 55px;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-left:after {
    content: "";
    z-index: -1;
    background: url("menu_service_bg.45b0f263.png") center / cover no-repeat;
    width: 450px;
    height: 312px;
    position: absolute;
    top: -50px;
    left: -92px;
  }
}

@media screen and (width >= 1100px) {
  .header-top .header-nav .header-nav-left:after {
    width: 505px;
    height: 384px;
    top: -74px;
    left: -105px;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu {
    background: none;
    flex-direction: column;
    align-items: center;
    gap: 13px 0;
    margin-bottom: 0;
    display: flex;
  }

  .header-top .header-nav .header-nav-menu .menu-item {
    background: none;
    position: relative;
  }

  .header-top .header-nav .header-nav-menu .menu-item .menu-item-inner {
    padding: 0;
  }
}

.header-top .header-nav .header-nav-menu .menu-item .menu-item-link {
  transition: all .6s;
}

@media screen and (width >= 769px) {
  .header-top .header-nav .header-nav-menu .menu-item .menu-item-link:hover {
    color: #333;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu .menu-item .menu-item-link {
    font-size: 16px;
    line-height: 2;
    position: relative;
  }

  .header-top .header-nav .header-nav-menu .menu-item .menu-item-link:before {
    opacity: 0;
    background: #4b9c84;
    border-radius: 3px;
    width: 8px;
    height: 3px;
    transition: all .6s;
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
  }
}

.header-top .header-nav .header-nav-menu .menu-item .menu-item-link:hover:before {
  opacity: 1;
}

@media screen and (width >= 375px) {
  .header-top .header-nav .header-nav-menu .menu-item .menu-item-headline {
    font-size: 18px;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu .menu-item .menu-item-headline {
    cursor: auto;
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 1.56;
    transition: all .6s;
  }
}

@media screen and (width >= 1000px) and (width >= 769px) {
  .header-top .header-nav .header-nav-menu .menu-item .menu-item-headline:hover {
    color: #333;
  }
}

@media screen and (width >= 1100px) {
  .header-top .header-nav .header-nav-menu .menu-item .menu-item-headline {
    font-size: 18px;
  }
}

@media screen and (width >= 375px) {
  .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line {
    padding: 0 32px;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line {
    padding: 0;
  }
}

@media screen and (width >= 375px) {
  .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
    width: 15px;
    height: 25px;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:before, .header-top .header-nav .header-nav-menu .menu-item .icon-headline-line:after {
    display: none;
  }

  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service {
    width: 275px;
    max-width: none;
  }
}

@media screen and (width >= 1100px) {
  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service {
    width: 290px;
  }
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item .service-item-link {
    height: 65px;
  }

  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item .service-item-icon {
    margin-bottom: 5px;
  }

  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-hospital, .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-clinic {
    max-width: 130px;
  }
}

@media screen and (width >= 1100px) {
  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-hospital, .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-clinic {
    max-width: 135px;
  }

  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-hospital {
    margin-right: 20px;
  }
}

.header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-pharmacy .service-item-link {
  height: 43px;
}

@media screen and (width >= 1000px) {
  .header-top .header-nav .header-nav-menu .menu-item .header-nav-menu-child.list-service .service-item.item-pharmacy .service-item-link .service-item-icon {
    margin: 0 5px 0 -31px;
  }
}

.header-top .is-open + .header-nav {
  transform: translateX(0);
}

@media screen and (width >= 1000px) {
  .header-top .is-open + .header-nav {
    transform: none;
  }
}

.footer, .footer-top {
  background: #4b9c84;
}

.footer .footer-inner, .footer-top .footer-inner {
  padding: 40px 0;
}

.footer .footer-logo, .footer-top .footer-logo {
  width: 160px;
  margin: 0 auto;
}

.footer .footer-logo .footer-logo-link, .footer-top .footer-logo .footer-logo-link {
  transition: all .6s;
}

@media screen and (width >= 769px) {
  .footer .footer-logo .footer-logo-link:hover, .footer-top .footer-logo .footer-logo-link:hover {
    opacity: .6;
  }
}

.footer .footer-menu .footer-menu-item .footer-menu-child, .footer-top .footer-menu .footer-menu-item .footer-menu-child {
  max-height: 0;
  padding: 0 18px;
  transition: all .3s;
  overflow: hidden;
}

.footer .footer-menu .footer-menu-item .footer-menu-child-item:not(:last-child), .footer-top .footer-menu .footer-menu-item .footer-menu-child-item:not(:last-child) {
  margin-bottom: 20px;
}

.footer .footer-menu .footer-menu-item .footer-menu-child-item .footer-menu-child-item-link, .footer-top .footer-menu .footer-menu-item .footer-menu-child-item .footer-menu-child-item-link {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  transition: all .6s;
}

@media screen and (width >= 769px) {
  .footer .footer-menu .footer-menu-item .footer-menu-child-item .footer-menu-child-item-link:hover, .footer-top .footer-menu .footer-menu-item .footer-menu-child-item .footer-menu-child-item-link:hover {
    color: #f4e276;
  }
}

.footer .footer-menu .footer-menu-item .footer-menu-title, .footer-top .footer-menu .footer-menu-item .footer-menu-title {
  color: #fff;
  border-bottom: 2px solid #97c4b9;
  padding: 20px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  position: relative;
}

.footer .footer-menu .footer-menu-item .footer-menu-title:before, .footer-top .footer-menu .footer-menu-item .footer-menu-title:before {
  content: "";
  background: url("icon_arrow_yellow.8ec81b37.svg") center / cover no-repeat;
  width: 19px;
  height: 11px;
  transition: all .6s;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.footer .footer-menu .footer-menu-item .footer-menu-title.is-open:before, .footer-top .footer-menu .footer-menu-item .footer-menu-title.is-open:before {
  transform: translateY(-50%)scaleY(-1);
}

.footer .footer-menu .is-open + .footer-menu-child, .footer-top .footer-menu .is-open + .footer-menu-child {
  max-height: 100vh;
  margin: 20px 0;
}

.footer .footer-copy, .footer-top .footer-copy {
  color: #fff;
  text-align: center;
  background: #42866d;
  padding: 14px 0;
  font-size: 12px;
  line-height: 1;
}

.footer .footer-inner {
  padding-bottom: 60px;
}

@media screen and (width >= 769px) {
  .footer .footer-inner {
    justify-content: space-between;
    padding: 80px 0;
    display: flex;
  }
}

.footer .footer-logo {
  margin-bottom: 20px;
}

@media screen and (width >= 769px) {
  .footer .footer-logo {
    margin: 0;
  }

  .footer .footer-menu {
    flex-wrap: wrap;
    justify-content: flex-end;
    width: calc(100% - 160px);
    display: flex;
  }

  .footer .footer-menu .footer-menu-item {
    margin-bottom: 50px;
    margin-left: 80px;
  }

  .footer .footer-menu .footer-menu-item .footer-menu-child {
    max-height: none;
    margin-left: 30px;
    padding: 0;
  }

  .footer .footer-menu .footer-menu-item .footer-menu-child .footer-menu-child-item:not(:last-child) {
    margin-bottom: 12px;
  }

  .footer .footer-menu .footer-menu-item .footer-menu-title {
    border: none;
    margin-bottom: 16px;
    padding: 0 0 0 30px;
  }

  .footer .footer-menu .footer-menu-item .footer-menu-title:before {
    background-image: url("icon_capsule.ef1ed224.png");
    width: 20px;
    height: 8px;
    left: 0;
    right: auto;
  }

  .footer-top .footer-menu .footer-menu-item .footer-menu-title {
    cursor: pointer;
  }
}

.toppage {
  overflow: hidden;
}

@media screen and (width >= 540px) {
  .toppage {
    background-color: #0000;
    background-image: url("bg_bottom.79cc653d.png"), url("bg_cloud.e3ece1f4.png"), url("bg.db2bc113.png");
    background-position: left -17px bottom 30px, bottom, center;
    background-repeat: repeat-x, repeat, repeat;
    background-size: 1834px, 1455px, 1400px;
    background-attachment: fixed;
    background-origin: padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box;
  }
}

.toppage.main {
  padding-top: 50px;
}

@media screen and (width >= 769px) {
  .toppage.main {
    padding-top: 0;
  }
}

.toppage .container-top {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5.33333vw;
}

@media screen and (width >= 540px) {
  .toppage .container-top {
    padding: 0 20px;
  }
}

.toppage .title-balloon {
  letter-spacing: 1.6px;
  text-align: center;
  background: #fff;
  border: 1px solid #4b9c84;
  border-radius: 5px;
  margin-bottom: 11px;
  padding: 13px 10px 14px;
  font-size: 4.26667vw;
  font-weight: bold;
  position: relative;
  box-shadow: 0 0 6px #4b9c8466;
}

@media screen and (width >= 375px) {
  .toppage .title-balloon {
    font-size: 16px;
  }
}

.toppage .title-balloon:before, .toppage .title-balloon:after {
  content: "";
  border: solid #0000;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.toppage .title-balloon:before {
  border-width: 7px;
  border-color: #4b9c84 #0000 #0000;
}

.toppage .title-balloon:after {
  border-width: 5px;
  border-color: #fff #0000 #0000;
}

@media screen and (width >= 540px) {
  .toppage .top-contents {
    z-index: 500;
    background: #f6f6f6;
    max-width: 375px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 25px #3333;
  }
}

.toppage .kv .kv-top {
  z-index: 1;
  background: url("kv_bg.4b45333f.png") center / 408px no-repeat;
  height: 300px;
  position: relative;
}

.toppage .kv .kv-top .kv-title-ribon {
  color: #fff;
  letter-spacing: 1px;
  background: #4b9c84;
  margin-bottom: 15px;
  padding: 0 13px;
  font-size: 5.33333vw;
  font-weight: bold;
  line-height: 40px;
  display: inline-block;
  position: relative;
}

.toppage .kv .kv-top .kv-title-ribon:before, .toppage .kv .kv-top .kv-title-ribon:after {
  content: "";
  border-style: solid;
  border-color: #4b9c84 #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.toppage .kv .kv-top .kv-title-ribon:before {
  border-width: 20px 0 20px 18px;
  left: -18px;
}

.toppage .kv .kv-top .kv-title-ribon:after {
  border-width: 20px 18px 20px 0;
  right: -18px;
}

@media screen and (width >= 375px) {
  .toppage .kv .kv-top .kv-title-ribon {
    font-size: 20px;
  }
}

.toppage .kv .kv-top .kv-title {
  text-align: center;
  max-width: 288px;
  margin: 0 auto;
  font-size: 8.53333vw;
  font-weight: bold;
  line-height: 1.37;
}

@media screen and (width >= 375px) {
  .toppage .kv .kv-top .kv-title {
    font-size: 32px;
  }
}

.toppage .section-use {
  background: url("use_bg_bottom.a1355022.png") bottom / auto 45px repeat-x, #97c4b9;
  margin-top: -38px;
  padding: 58px 0 75px;
  position: relative;
}

.toppage .section-use .use-title {
  color: #fff;
  z-index: 2;
  background: #4b9c84;
  margin-left: -5.33333vw;
  padding: 0 21px;
  font-size: 6.93333vw;
  font-weight: bold;
  line-height: 50px;
  display: inline-block;
  position: relative;
}

.toppage .section-use .use-title:before, .toppage .section-use .use-title:after {
  content: "";
  border-style: solid;
  border-color: #4b9c84 #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.toppage .section-use .use-title:before {
  border-width: 25px 0 25px 18px;
  left: -18px;
}

.toppage .section-use .use-title:after {
  border-width: 25px 18px 25px 0;
  right: -18px;
}

@media screen and (width >= 375px) {
  .toppage .section-use .use-title {
    font-size: 26px;
  }
}

@media screen and (width >= 540px) {
  .toppage .section-use .use-title {
    margin-left: -20px;
  }
}

.toppage .section-use .use-panel {
  z-index: 1;
  background: #fff;
  margin: -25px 0 20px;
  padding: 42px 20px 15px;
  position: relative;
}

.toppage .section-use .use-panel:before, .toppage .section-use .use-panel:after {
  content: "";
  z-index: -1;
  border-style: solid;
  border-color: #333;
  display: block;
  position: absolute;
}

.toppage .section-use .use-panel:before {
  border-width: 2px 0;
  width: calc(100% - 20px);
  height: calc(100% - 2px);
  top: -1px;
  left: 10px;
}

@media screen and (width >= 769px) {
  .toppage .section-use .use-panel:before {
    border-width: 2px 0;
    width: calc(100% - 20px);
    height: calc(100% - 2px);
    top: -1px;
    left: 10px;
  }
}

.toppage .section-use .use-panel:after {
  border-width: 0 2px;
  width: calc(100% - 2px);
  height: calc(100% - 20px);
  top: 10px;
  left: -1px;
}

@media screen and (width >= 769px) {
  .toppage .section-use .use-panel:after {
    border-width: 0 2px;
    width: calc(100% - 2px);
    height: calc(100% - 20px);
    top: 10px;
    left: -1px;
  }
}

.toppage .section-use .use-list {
  display: flex;
}

.toppage .section-use .use-list .use-item {
  flex-direction: column;
  width: 33.3333%;
  display: flex;
}

.toppage .section-use .use-list .use-item .use-item-img {
  margin-bottom: 6px;
}

.toppage .section-use .use-list .use-item .use-item-text {
  text-align: center;
  margin: auto 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
}

.toppage .section-recommend {
  background: #d6e8e4;
  padding: 35px 0 30px;
  position: relative;
}

.toppage .section-recommend .icon-deco-tablet-recommend {
  width: 106px;
  bottom: -22px;
  right: -10px;
}

.toppage .section-recommend .recommend-title {
  text-align: center;
  margin-bottom: 26px;
  font-size: 6.93333vw;
  font-weight: bold;
}

@media screen and (width >= 375px) {
  .toppage .section-recommend .recommend-title {
    font-size: 26px;
  }
}

.toppage .section-recommend .recommend-title .icon-headline-line {
  text-align: center;
  padding: 0 9.86667vw;
  position: relative;
}

.toppage .section-recommend .recommend-title .icon-headline-line:before, .toppage .section-recommend .recommend-title .icon-headline-line:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.toppage .section-recommend .recommend-title .icon-headline-line:before {
  background: url("icon_headline_line_left.0dda61f7.svg") center / contain no-repeat;
  left: 0;
}

.toppage .section-recommend .recommend-title .icon-headline-line:after {
  background: url("icon_headline_line_right.1d7ee239.svg") center / contain no-repeat;
  right: 0;
}

@media screen and (width >= 375px) {
  .toppage .section-recommend .recommend-title .icon-headline-line {
    padding: 0 37px;
  }
}

.toppage .section-recommend .recommend-title .icon-headline-line:before, .toppage .section-recommend .recommend-title .icon-headline-line:after {
  width: 18px;
  height: 28px;
}

.toppage .section-recommend .recommend-list .recommend-item {
  background: #fff;
  padding: 20px 23px;
}

.toppage .section-recommend .recommend-list .recommend-item:not(:last-child), .toppage .section-recommend .recommend-list .recommend-item .recommend-item-img {
  margin-bottom: 20px;
}

.toppage .section-recommend .recommend-list .recommend-item .recommend-item-title, .toppage .section-recommend .recommend-list .recommend-item .recommend-item-text {
  text-align: center;
  font-weight: bold;
}

.toppage .section-recommend .recommend-list .recommend-item .recommend-item-title {
  margin-bottom: 11px;
  font-size: 22px;
  line-height: 1.45;
}

.toppage .section-recommend .recommend-list .recommend-item .recommend-item-text {
  font-size: 16px;
  line-height: 1.56;
}

.toppage .section-try {
  padding: 30px 0 20px;
  position: relative;
}

.toppage .section-try .try-title {
  text-align: center;
  margin-bottom: 15px;
  padding-bottom: 10.6667vw;
  font-size: 6.93333vw;
  font-weight: bold;
  line-height: 1.46;
  position: relative;
}

@media screen and (width >= 375px) {
  .toppage .section-try .try-title {
    padding-bottom: 40px;
    font-size: 26px;
  }
}

.toppage .section-try .try-title:before {
  content: "";
  background: url("try_title.5ed54606.png") center / cover no-repeat;
  width: 93.0667vw;
  height: 10.4vw;
  position: absolute;
  bottom: 0;
  left: calc(50% - 2.13333vw);
  transform: translateX(-50%);
}

@media screen and (width >= 375px) {
  .toppage .section-try .try-title:before {
    width: 349px;
    height: 39px;
    left: calc(50% - 8px);
  }
}

.toppage .section-try .try-list {
  margin-bottom: 30px;
  display: flex;
}

.toppage .section-try .try-list .try-item {
  z-index: 1;
  background: #fff;
  flex-direction: column;
  width: calc(50% - 12px);
  padding: 21px 10px;
  display: flex;
  position: relative;
}

.toppage .section-try .try-list .try-item:before, .toppage .section-try .try-list .try-item:after {
  content: "";
  z-index: -1;
  border-style: solid;
  border-color: #333;
  display: block;
  position: absolute;
}

.toppage .section-try .try-list .try-item:before {
  border-width: 2px 0;
  width: calc(100% - 20px);
  height: calc(100% - 2px);
  top: -1px;
  left: 10px;
}

@media screen and (width >= 769px) {
  .toppage .section-try .try-list .try-item:before {
    border-width: 2px 0;
    width: calc(100% - 20px);
    height: calc(100% - 2px);
    top: -1px;
    left: 10px;
  }
}

.toppage .section-try .try-list .try-item:after {
  border-width: 0 2px;
  width: calc(100% - 2px);
  height: calc(100% - 20px);
  top: 10px;
  left: -1px;
}

@media screen and (width >= 769px) {
  .toppage .section-try .try-list .try-item:after {
    border-width: 0 2px;
    width: calc(100% - 2px);
    height: calc(100% - 20px);
    top: 10px;
    left: -1px;
  }
}

.toppage .section-try .try-list .try-item:not(:last-child) {
  margin-right: 23px;
}

.toppage .section-try .try-list .try-item .try-item-img {
  width: 76px;
  margin: 0 auto 12px;
}

.toppage .section-try .try-list .try-item .try-item-text {
  text-align: center;
  margin: auto 0;
  font-size: 18px;
  font-weight: bold;
}

.toppage .section-app {
  padding: 20px 0 35px;
  position: relative;
}

.toppage .section-app .app-panel {
  background: #fff;
  padding: 30px 3.125vw 40px;
}

@media screen and (width >= 375px) {
  .toppage .section-app .app-panel {
    padding: 30px 20px 40px;
  }
}

.toppage .section-app .app-panel .app-title {
  text-align: center;
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
}

.toppage .section-app .app-panel .app-wrap {
  align-items: center;
  max-width: 364px;
  margin: 0 auto;
  display: flex;
}

.toppage .section-app .app-panel .app-wrap .app-img {
  width: 34.667vw;
}

@media screen and (width >= 375px) {
  .toppage .section-app .app-panel .app-wrap .app-img {
    width: 130px;
  }
}

.toppage .section-app .app-panel .app-wrap .app-list {
  flex: 1 1 0;
}

.toppage .section-app .app-panel .app-wrap .app-list .app-item {
  padding-left: 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.7;
  position: relative;
}

.toppage .section-app .app-panel .app-wrap .app-list .app-item:not(:last-child) {
  margin-bottom: 15px;
}

.toppage .section-app .app-panel .app-wrap .app-list .app-item:before {
  content: "";
  border-bottom: 3px solid #4b9c84;
  border-left: 3px solid #4b9c84;
  width: 11px;
  height: 6px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  transform: rotate(-45deg);
}

.toppage .section-service {
  background: #d6e8e4;
  padding: 30px 0 80px;
  position: relative;
}

.toppage .section-service .icon-deco-smartphone-service {
  width: 77px;
  top: -25px;
  left: -18px;
}

.toppage .section-service .icon-deco-capsule-service {
  width: 103px;
  bottom: -19px;
  left: -14px;
}

.toppage .section-service .icon-deco-cafe-service {
  width: 102px;
  bottom: -19px;
  right: -6px;
}

.toppage .section-service .service-title {
  text-align: center;
  margin-bottom: 22px;
  font-size: 6.93333vw;
  font-weight: bold;
  line-height: 1.46;
}

@media screen and (width >= 375px) {
  .toppage .section-service .service-title {
    font-size: 26px;
  }
}

@media screen and (width >= 1000px) {
  .toppage .top-menu {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .toppage .top-menu .top-menu-inner {
    max-width: 1287px;
    height: 100%;
    margin: 0 auto;
    padding: 0 3.57143vw;
  }

  .toppage .top-menu .top-menu-inner .top-menu-wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .toppage .top-menu .top-menu-inner .top-menu-wrap:after {
    content: "";
    background: url("yakubato.44d44deb.png") center / cover repeat-x;
    width: 268px;
    height: 268px;
    position: absolute;
    top: 300px;
    left: calc(50% - 202px);
    transform: translateX(-50%);
  }
}

.toppage .top-menu .top-menu-inner .top-menu-bottom {
  display: none;
}

@media screen and (width >= 769px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom {
    width: 160px;
    display: block;
    position: fixed;
    bottom: 0;
    right: 2.14286vw;
  }
}

@media screen and (width >= 1000px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom {
    width: 210px;
    position: absolute;
    right: 0;
  }
}

@media screen and (width >= 769px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr {
    filter: drop-shadow(0 0 15px #3333331a);
    background: linear-gradient(#0000 0% 10%, #fff 10% 100%);
    padding-bottom: 15px;
    position: relative;
  }

  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr:after {
    content: "";
    z-index: -1;
    border: 80px solid #0000;
    border-bottom: 53px solid #fff;
    position: absolute;
    bottom: calc(100% - 15px);
    left: 0;
  }
}

@media screen and (width >= 1000px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr:after {
    border-width: 0 105px 58px;
  }
}

@media screen and (width >= 769px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr .qr-text {
    text-align: center;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.38;
  }
}

@media screen and (width >= 1000px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr .qr-text {
    font-size: 16px;
  }
}

@media screen and (width >= 769px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr .qr-img {
    width: 75px;
    margin: 0 auto;
  }
}

@media screen and (width >= 769px) and (width >= 1000px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-qr .qr-img {
    width: 80px;
  }
}

@media screen and (width >= 769px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-pagetop {
    cursor: pointer;
    background: #97c4b9;
    justify-content: center;
    align-items: center;
    height: 35px;
    display: flex;
  }

  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-pagetop .pagetop-text {
    letter-spacing: 2px;
    color: #fff;
    padding-left: 26px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
  }
}

@media screen and (width >= 1000px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-pagetop .pagetop-text {
    font-size: 16px;
  }
}

@media screen and (width >= 769px) {
  .toppage .top-menu .top-menu-inner .top-menu-bottom .top-menu-bottom-pagetop .pagetop-text:before {
    content: "";
    background: url("icon_arrow_white.f770b6d5.svg") center / cover repeat-x;
    width: 16px;
    height: 11px;
    position: absolute;
    top: calc(50% + 1px);
    left: 0;
    transform: translateY(-50%);
  }
}

.subpage.main {
  padding-top: 50px;
}

@media screen and (width >= 769px) {
  .subpage.main {
    padding-top: 80px;
  }
}

.subpage .section {
  padding-top: 40px;
}

@media screen and (width >= 769px) {
  .subpage .section {
    padding-top: 25px;
  }
}

.subpage .title-large {
  text-align: center;
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
}

@media screen and (width >= 769px) {
  .subpage .title-large {
    font-size: 36px;
    line-height: 54px;
  }
}

.subpage .title-middle {
  margin-bottom: 25px;
  font-size: 26px;
  font-weight: bold;
  line-height: 39px;
}

@media screen and (width >= 769px) {
  .subpage .title-middle {
    font-size: 30px;
    line-height: 45px;
  }
}

.subpage .title-small {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (width >= 769px) {
  .subpage .title-small {
    font-size: 26px;
  }
}

.subpage .title-xsmall {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (width >= 769px) {
  .subpage .title-xsmall {
    font-size: 24px;
  }
}

.subpage .text-lead {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.88;
}

@media screen and (width >= 769px) {
  .subpage .text-lead {
    text-align: center;
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 1.67;
  }
}

.subpage .contents_area {
  padding: 87px 0 100px;
  position: relative;
}

@media screen and (width >= 769px) {
  .subpage .contents_area {
    padding: 135px 0 170px;
  }
}

.subpage .contents_area:before {
  content: "";
  background: url("subpage_line.f161e7ae.png") center / 1400px repeat-x, #f6f6f6;
  width: 100%;
  height: 56px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.subpage .panel-white {
  padding: 60px 5.33333vw;
}

@media screen and (width >= 769px) {
  .subpage .panel-white {
    padding: 60px;
  }
}

.medical_institution .contents_area {
  padding-bottom: 0;
}

@media screen and (width >= 769px) {
  .medical_institution .contents_area {
    padding-bottom: 170px;
  }
}

.terms .text, .terms ol, .terms ul {
  font-size: 16px;
  line-height: 31px;
}

@media screen and (width >= 769px) {
  .terms .text, .terms ol, .terms ul {
    font-size: 18px;
    line-height: 34px;
  }
}

.terms ol > li:not(:last-child) {
  margin-bottom: 10px;
}

.terms ol.list-number-round li:before {
  top: 6px;
}

@media screen and (width >= 769px) {
  .terms ol.list-number-round li:before {
    top: 8px;
  }
}

.terms ol.list-number > li:not(:last-child) {
  margin-bottom: 30px;
}

.terms ol ol {
  margin-top: 10px;
}

.terms .terms-contents {
  word-break: break-all;
  max-width: 1000px;
  margin: 0 auto;
}

.terms .terms-item {
  max-width: 1000px;
  margin-bottom: 70px;
}

@media screen and (width >= 769px) {
  .terms .terms-item {
    margin-bottom: 87px;
  }
}

.terms .terms-item.is-anchor {
  margin-top: -50px;
  padding-top: 50px;
}

@media screen and (width >= 769px) {
  .terms .terms-item.is-anchor {
    margin-top: -80px;
    padding-top: 80px;
  }
}

.terms .terms-anchors {
  margin-bottom: 30px;
}

.terms .terms-table-wrapper {
  overflow-x: auto;
}

.terms .terms-table-wrapper .table {
  width: 1000px;
}

.terms .terms-cell-company, .terms .terms-cell-service, .terms .terms-cell-data {
  width: 180px;
}

.terms .terms-cell-purpose {
  width: 190px;
}

.terms .terms-cell-optout {
  width: auto;
}

.privacy-policy dl.business-operator {
  margin-top: 30px;
  display: flex;
}

.privacy-policy dl.consultation-desk {
  margin-top: 30px;
}

.privacy-policy .period {
  margin-bottom: 20px;
}

.notfound .notfound-contents {
  padding-top: 30px;
}

@media screen and (width >= 769px) {
  .notfound .notfound-contents {
    padding-top: 20px;
  }
}

.notfound .notfound-img {
  width: 100%;
  max-width: 324px;
  margin: 0 auto 50px;
}

@media screen and (width >= 769px) {
  .notfound .notfound-img {
    max-width: 603px;
  }
}

.notfound .notfound-title {
  text-align: center;
  margin-bottom: 60px;
  font-size: 20px;
  font-weight: bold;
  line-height: 35px;
}

#medical-institution {
  line-height: 1.5;
}

#medical-institution .container {
  padding: 0 6.4vw;
}

#medical-institution .btn_area {
  margin-top: -38px;
}

@media screen and (width <= 1024px) {
  #medical-institution .btn_area {
    margin-top: -134px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .btn_area {
    margin-top: -30px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .btn_area {
    margin-top: -108px;
  }
}

#medical-institution .btn_area .btn-list {
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 15px 25px;
  display: flex;
  position: relative;
}

@media screen and (width <= 767px) {
  #medical-institution .btn_area .btn-list li {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

#medical-institution .btn_area .btn-list.btn-bg {
  background: #dfeeea;
  border-radius: 60px;
  padding: 46px 10px 35px;
}

@media screen and (width <= 767px) {
  #medical-institution .btn_area .btn-list.btn-bg {
    border-radius: 20px;
    padding: 40px 10px;
  }
}

#medical-institution .panel-example {
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  position: relative;
}

#medical-institution .panel-example .panel-example-title {
  letter-spacing: 1.08px;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .panel-example .panel-example-title {
    letter-spacing: .84px;
    font-size: .875rem;
    top: -8px;
  }
}

#medical-institution .panel-example .panel-example-title span {
  background: #4b9c84;
  border-radius: 999px;
  padding: 8px 24px;
}

@media screen and (width <= 768px) {
  #medical-institution .panel-example .panel-example-title span {
    padding: 8px 5.33333vw;
  }
}

#medical-institution .kv_area {
  padding: 0 0 102px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area {
    padding: 64px 0;
  }
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area {
    padding-bottom: 201px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area {
    padding-bottom: 140px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .kv_area {
    padding-bottom: 157px;
  }
}

#medical-institution .kv_area:after {
  content: "";
  z-index: -1;
  background: url("kv_bg.4c3e8756.png") center / contain no-repeat;
  width: 1320px;
  height: 588px;
  display: inline-block;
  position: absolute;
  top: 24px;
  left: calc(50% + 164px);
  transform: translateX(-50%);
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area:after {
    width: 99.414vw;
    height: 43.945vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area:after {
    display: none;
  }
}

#medical-institution .kv_area .kv-contents {
  padding: 85px 0 91px;
  position: relative;
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area .kv-contents {
    padding-top: 6.714vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents {
    padding: 0;
  }
}

#medical-institution .kv_area .kv-contents:before, #medical-institution .kv_area .kv-contents:after {
  content: "";
  z-index: 1;
  position: absolute;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents:before, #medical-institution .kv_area .kv-contents:after {
    display: none;
  }
}

#medical-institution .kv_area .kv-contents:before {
  background: url("kv_deco_kapsel.b44d9d82.png") center / contain no-repeat;
  width: 13vw;
  height: 8.5vw;
  top: 5px;
  left: 50%;
}

@media screen and (width >= 1400px) {
  #medical-institution .kv_area .kv-contents:before {
    width: 182px;
    height: 119px;
  }
}

#medical-institution .kv_area .kv-contents:after {
  background: url("kv_deco_tablet.1b09603b.png") center / contain no-repeat;
  width: 9.28571vw;
  height: 7.57143vw;
  bottom: 22px;
  left: calc(50% + 405px);
}

@media screen and (width >= 1400px) {
  #medical-institution .kv_area .kv-contents:after {
    width: 130px;
    height: 106px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title {
  max-width: 480px;
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area .kv-contents .kv-title {
    max-width: 39.844vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title {
    z-index: 1;
    width: 100%;
    max-width: none;
    position: relative;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-logo {
  width: 255px;
  margin: 0 auto 16px;
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-logo {
    width: 37.305vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-logo {
    width: 100%;
    max-width: 260px;
    margin-bottom: 16px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-logo {
    max-width: 198px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon {
  text-align: center;
  z-index: 1;
  background: #4b9c84;
  justify-content: center;
  align-items: center;
  width: 306px;
  height: 84px;
  margin: 0 auto 32px;
  display: flex;
  position: relative;
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon {
    margin-bottom: 6px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon {
    width: 250px;
    height: 64px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon:before, #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon:after {
  content: "";
  border-style: solid;
  border-color: #4b9c84 #0000;
  position: absolute;
  top: 0;
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon:before {
  border-width: 42px 0 42px 20px;
  left: -20px;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon:before {
    border-width: 32px 0 32px 15px;
    left: -15px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon:after {
  border-width: 42px 20px 42px 0;
  right: -20px;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon:after {
    border-width: 32px 15px 32px 0;
    right: -15px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text {
  color: #fff;
  letter-spacing: 1.44px;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text {
    letter-spacing: 1.08px;
    font-size: 1.125rem;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:before, #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:after {
  content: "";
  z-index: 1;
  background: #fff;
  width: 316px;
  height: 1px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:before, #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:after {
    width: 256px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:before {
  top: 8px;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:before {
    top: 6px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:after {
  bottom: 8px;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text:after {
    bottom: 6px;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text .fs-small {
  letter-spacing: .96px;
  font-size: 1rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text .fs-small {
    letter-spacing: .72px;
    font-size: .75rem;
    line-height: 1.4;
  }
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-ribon .kv-title-ribon-text sup {
  font-size: 70%;
  top: .25em;
}

#medical-institution .kv_area .kv-contents .kv-title .kv-title-text {
  letter-spacing: 2.88px;
  text-align: center;
  margin-bottom: 32px;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.8;
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-text {
    letter-spacing: .281vw;
    margin-bottom: 3.125vw;
    font-size: 3.906vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-title .kv-title-text {
    letter-spacing: 1.68px;
    margin-bottom: 16px;
    font-size: 1.75rem;
  }
}

#medical-institution .kv_area .kv-contents .kv-notes {
  letter-spacing: .72px;
  font-size: .75rem;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-notes {
    text-align: center;
    font-size: .625rem;
    line-height: 1.8;
  }
}

#medical-institution .kv_area .kv-contents .kv-img {
  width: 67%;
  max-width: 800px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% + 305px);
  transform: translate(-50%, -50%);
}

@media screen and (width <= 1024px) {
  #medical-institution .kv_area .kv-contents .kv-img {
    max-width: 58.594vw;
    left: auto;
    right: -85px;
    transform: translateY(-50%);
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-contents .kv-img {
    z-index: -1;
    width: 102.133vw;
    max-width: none;
    margin-top: -19px;
    position: relative;
    top: auto;
    right: 10px;
    transform: none;
  }

  #medical-institution .kv_area .kv-point {
    margin-top: 16px;
    position: relative;
  }
}

#medical-institution .kv_area .kv-point .kv-point-list {
  counter-reset: cnt;
  justify-content: center;
  gap: 20px 2.143vw;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-point .kv-point-list {
    flex-direction: column;
    gap: 16px 0;
    max-width: 390px;
    margin: 0 auto;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .kv_area .kv-point .kv-point-list {
    max-width: 327px;
  }
}

#medical-institution .kv_area .kv-point .kv-point-list .kv-point-item {
  box-sizing: border-box;
  background: #fff;
  border-radius: 23px;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 121px;
  padding: 16px 10px;
  display: flex;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-point .kv-point-list .kv-point-item {
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 12px 16px;
  }
}

#medical-institution .kv_area .kv-point .kv-point-list .kv-point-item:before {
  counter-increment: cnt;
  content: counter(cnt);
  color: #fff;
  background: #4b9c84;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-point .kv-point-list .kv-point-item:before {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    font-size: .75rem;
    position: static;
    transform: none;
  }
}

#medical-institution .kv_area .kv-point .kv-point-list .kv-point-item .point-text {
  letter-spacing: 1.32px;
  text-align: center;
  font-size: 1.375rem;
  font-weight: bold;
}

@media screen and (width <= 896px) {
  #medical-institution .kv_area .kv-point .kv-point-list .kv-point-item .point-text {
    font-size: 1.25rem;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .kv_area .kv-point .kv-point-list .kv-point-item .point-text {
    text-align: left;
    flex: 1 1 0;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .kv_area .kv-point .kv-point-list .kv-point-item .point-text {
    font-size: 1rem;
  }
}

#medical-institution .about_area {
  margin-top: -69px;
  padding: 0;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area {
    margin-top: -62px;
    padding: 0;
  }
}

#medical-institution .about_area .about-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .about_area .about-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .about_area .about-title {
    margin-bottom: 48px;
  }
}

#medical-institution .about_area .about-balloon {
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  margin: 0 auto;
  padding: 22px 14px 24px;
  position: relative;
}

#medical-institution .about_area .about-balloon:before, #medical-institution .about_area .about-balloon:after {
  content: "";
  border-style: solid;
  position: absolute;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon:before, #medical-institution .about_area .about-balloon:after {
    inset: auto auto 0 50%;
    translate: -50% 100%;
  }
}

#medical-institution .about_area .about-balloon:before, #medical-institution .about_area .about-balloon:after {
  bottom: 0;
  translate: -50% 100%;
}

#medical-institution .about_area .about-balloon:before {
  border-width: 12px 10px 0;
  border-color: #333 #0000 #0000;
}

#medical-institution .about_area .about-balloon:after {
  border-width: 7.3px 6.1px 0;
  border-color: #fff #0000 #0000;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon {
    padding: 16px 20px;
  }
}

#medical-institution .about_area .about-balloon:before, #medical-institution .about_area .about-balloon:after {
  right: 22px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon:before, #medical-institution .about_area .about-balloon:after {
    left: 50%;
    right: auto;
  }
}

#medical-institution .about_area .about-balloon:after {
  right: 30px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon:after {
    left: 50%;
    right: auto;
  }
}

#medical-institution .about_area .about-balloon .about-balloon-text {
  letter-spacing: 1.2px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon .about-balloon-text {
    letter-spacing: .96px;
    font-size: 1rem;
  }
}

#medical-institution .about_area .about-balloon .about-balloon-img {
  z-index: 2;
  width: 208px;
  position: absolute;
  bottom: -112px;
  right: -128px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon .about-balloon-img {
    width: 132px;
    bottom: -89px;
    left: calc(50% + 136px);
    transform: translateX(-50%);
  }
}

#medical-institution .about_area .about-balloon .about-balloon-deco {
  width: 93px;
  position: absolute;
  bottom: 15px;
  right: -140px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-balloon .about-balloon-deco {
    display: none;
  }
}

#medical-institution .about_area .about-problem {
  background: #eaeaea;
  padding: 152px 0 120px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem {
    padding: 128px 0 64px;
  }
}

#medical-institution .about_area .about-problem .about-problem-list {
  flex-direction: column;
  gap: 54px 0;
  max-width: 710px;
  margin: 0 auto 48px;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list {
    gap: 36px 0;
    max-width: 610px;
    margin-bottom: 36px;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item {
  display: flex;
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item .problem-item-box {
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  padding: 20px 29px;
  position: relative;
}

@media screen and (width <= 767px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item .problem-item-box {
    padding: 13px 16px;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item .problem-item-img {
  width: 128px;
  position: absolute;
  bottom: -62px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item .problem-item-img {
    width: 92px;
    bottom: -45px;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item .problem-item-text {
  letter-spacing: 1.2px;
  font-size: 1.25rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item .problem-item-text {
    letter-spacing: .96px;
    font-size: 1rem;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item:last-child .problem-item-img {
  bottom: -65px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item:last-child .problem-item-img {
    bottom: -45px;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(odd) {
  justify-content: flex-end;
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(odd) .problem-item-box {
  width: 588px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(odd) .problem-item-box {
    width: 70.1333vw;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(odd) .problem-item-img {
  left: -102px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(odd) .problem-item-img {
    left: -63px;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(2n) .problem-item-box {
  width: 592px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(2n) .problem-item-box {
    width: 70.1333vw;
  }
}

#medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(2n) .problem-item-img {
  right: -102px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-list .problem-item:nth-child(2n) .problem-item-img {
    right: -63px;
  }
}

#medical-institution .about_area .about-problem .about-problem-summary {
  z-index: 2;
  background: #4b9c84;
  border-radius: 12px;
  max-width: 744px;
  margin: 0 auto;
  padding: 20px 10px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-summary {
    padding: 20px 18px;
  }
}

#medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text {
  letter-spacing: 1.28px;
  color: #fff;
  max-width: 499px;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 700;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text {
    max-width: 335px;
    font-size: 1.125rem;
  }
}

#medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:before, #medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:after {
  content: "";
  background: url("icon_quotation.cb2533c8.svg") center / cover no-repeat;
  width: 32px;
  height: 28px;
  position: absolute;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:before, #medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:after {
    width: 24px;
    height: 21px;
  }
}

#medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:before {
  top: -30px;
  left: -60px;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:before {
    left: 0;
  }
}

#medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:after {
  bottom: -30px;
  right: -60px;
  transform: rotate(-180deg);
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-problem .about-problem-summary .about-problem-summary-text:after {
    right: 0;
  }
}

#medical-institution .about_area .about-cause {
  padding: 80px 0 87px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause {
    padding: 64px 0 72px;
  }
}

#medical-institution .about_area .about-cause .about-cause-title {
  letter-spacing: 1.6px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-title {
    letter-spacing: .96px;
    font-size: 1.5rem;
  }
}

#medical-institution .about_area .about-cause .about-cause-load {
  margin-bottom: 70px;
  padding: 60px 0 70px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-load {
    margin-bottom: 32px;
    padding: 32px 0 52px;
  }
}

#medical-institution .about_area .about-cause .about-cause-load:before {
  content: "";
  z-index: -1;
  background: url("bg_plaid.aead6740.png") top / 25px;
  width: 100%;
  height: 337px;
  display: inline-block;
  position: absolute;
  bottom: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-load:before {
    background-size: 16px;
    height: 112%;
  }
}

#medical-institution .about_area .about-cause .about-cause-load:after {
  content: "";
  border: 36px solid #0000;
  border-top: 32px solid #4b9c84;
  border-bottom-width: 0;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-load:after {
    border-width: 20px 22.5px 0;
    bottom: 0;
  }
}

#medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list {
  flex-direction: column;
  gap: 32px 0;
  max-width: 580px;
  margin: 0 auto;
  display: flex;
}

#medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list li {
  letter-spacing: .96px;
  padding-left: 50px;
  font-size: 1.5rem;
  font-weight: 700;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list li {
    letter-spacing: 1.08px;
    padding-left: 31px;
    font-size: 1.125rem;
  }
}

#medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list li:before {
  content: "";
  background: url("icon_cross.56148737.svg") center / cover no-repeat;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 2px;
  left: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list li:before {
    width: 23px;
    height: 23px;
  }
}

#medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list li .item-detail {
  letter-spacing: 1.08px;
  margin-top: 8px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list li .item-detail {
    text-align: left;
    letter-spacing: .84px;
    font-size: .875rem;
  }

  #medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list {
    width: 64.2667vw;
    max-width: 437px;
  }
}

@media screen and (width <= 374px) {
  #medical-institution .about_area .about-cause .about-cause-load .about-cause-load-list {
    width: 100%;
    max-width: 241px;
  }
}

#medical-institution .about_area .about-cause .about-cause-result .about-cause-result-tilte {
  letter-spacing: 1.12px;
  color: #4b9c84;
  text-align: center;
  margin-bottom: 32px;
  font-size: 1.75rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-cause .about-cause-result .about-cause-result-tilte {
    letter-spacing: 1.32px;
    font-size: 1.375rem;
  }
}

#medical-institution .about_area .about-cause .about-balloon {
  max-width: 440px;
}

#medical-institution .about_area .about-rundown {
  background: #4b9c84;
  padding: 112px 0 87px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-rundown {
    padding: 80px 0 72px;
  }
}

#medical-institution .about_area .about-rundown:before, #medical-institution .about_area .about-rundown:after {
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#medical-institution .about_area .about-rundown:before {
  content: "";
  border: 92px solid #0000;
  border-top: 64px solid #f6f6f6;
  border-bottom-width: 0;
  top: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-rundown:before {
    border-width: 48px 68px 0;
  }
}

#medical-institution .about_area .about-rundown:after {
  content: "だからこそ";
  letter-spacing: .1em;
  font-size: 1.1875rem;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  top: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-rundown:after {
    font-size: 1rem;
  }
}

#medical-institution .about_area .about-rundown .about-rundown-title {
  letter-spacing: 1.12px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.75rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-rundown .about-rundown-title {
    letter-spacing: 1.32px;
    font-size: 1.375rem;
  }
}

#medical-institution .about_area .about-rundown .about-rundown-point {
  letter-spacing: 1.28px;
  text-align: center;
  background: #fff;
  border-radius: 999px;
  max-width: 920px;
  margin: 0 auto 64px;
  padding: 16px 30px;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .about_area .about-rundown .about-rundown-point {
    margin-bottom: 48px;
    font-size: 1.375rem;
  }
}

#medical-institution .about_area .about-rundown .about-balloon {
  max-width: 598px;
}

#medical-institution .about_area .about-rundown .about-balloon .about-balloon-text {
  line-height: 1.8;
}

#medical-institution .reserve_area {
  background: #dfeeea;
  padding: 80px 0 120px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area {
    padding: 64px 0;
  }
}

#medical-institution .reserve_area .reserve-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .reserve_area .reserve-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .reserve_area .reserve-title {
    margin-bottom: 48px;
  }
}

#medical-institution .reserve_area .reserve-list {
  border: 3px solid #333;
  border-radius: 8px;
  max-width: 960px;
  margin: 0 auto 48px;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-list {
    flex-direction: column;
  }
}

#medical-institution .reserve_area .reserve-list .reserve-item {
  background: #fff;
  width: 33.3333%;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-list .reserve-item {
    width: 100%;
  }
}

#medical-institution .reserve_area .reserve-list .reserve-item:not(:last-child) {
  border-right: 3px solid #333;
}

#medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-number {
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background: #4b9c84;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 0 10px;
  font-size: 1.25rem;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-number {
    height: 51px;
    font-size: 1.125rem;
  }
}

#medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes {
  text-align: center;
  padding: 24px 20px 20px;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes {
    padding: 16px 6.4vw 20px;
  }
}

#medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes .reserve-item-contentes-title {
  letter-spacing: .96px;
  margin-bottom: 12px;
  font-size: 1.5rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes .reserve-item-contentes-title {
    font-size: 1.25rem;
  }
}

#medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes .reserve-item-contentes-detail {
  letter-spacing: 1.08px;
  font-size: 1.125rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes .reserve-item-contentes-detail {
    font-size: 1rem;
  }
}

#medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes .reserve-item-contentes-detail sup {
  top: .25em;
}

#medical-institution .reserve_area .reserve-list .reserve-item .reserve-item-contentes .reserve-item-contentes-notes {
  letter-spacing: .72px;
  text-align: left;
  width: fit-content;
  margin: 17px auto 0;
  font-size: .625rem;
}

#medical-institution .reserve_area .reserve-point {
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 38px 64px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-point {
    padding: 48px 6.4vw 60px;
  }
}

#medical-institution .reserve_area .reserve-point .reserve-point-list {
  flex-direction: column;
  gap: 26px 0;
  width: 84%;
  max-width: 652px;
  display: flex;
}

#medical-institution .reserve_area .reserve-point .reserve-point-list li {
  letter-spacing: .96px;
  padding-left: 40px;
  font-size: 1.5rem;
  font-weight: 700;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-point .reserve-point-list li {
    padding-left: 31px;
    font-size: 1rem;
  }
}

#medical-institution .reserve_area .reserve-point .reserve-point-list li:before, #medical-institution .reserve_area .reserve-point .reserve-point-list li:after {
  content: "";
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

#medical-institution .reserve_area .reserve-point .reserve-point-list li:before {
  background: #4b9c84;
  width: 28px;
  height: 28px;
  top: 5px;
  left: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-point .reserve-point-list li:before {
    width: 23px;
    height: 23px;
    top: 1px;
  }
}

#medical-institution .reserve_area .reserve-point .reserve-point-list li:after {
  background: #fff;
  width: 16px;
  height: 16px;
  top: 11px;
  left: 6px;
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-point .reserve-point-list li:after {
    width: 13px;
    height: 13px;
    top: 6px;
    left: 5px;
  }
}

@media screen and (width <= 896px) {
  #medical-institution .reserve_area .reserve-point .reserve-point-list {
    width: 95%;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-point .reserve-point-list {
    width: 100%;
  }
}

#medical-institution .reserve_area .reserve-point .reserve-point-img {
  width: 197px;
  position: absolute;
  bottom: -14px;
  left: calc(50% + 350px);
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .reserve_area .reserve-point .reserve-point-img {
    width: 105px;
    bottom: -34px;
    left: auto;
    right: 15px;
    transform: none;
  }
}

#medical-institution .merit_area {
  padding: 80px 0 158px;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area {
    padding: 64px 0;
  }
}

@media screen and (width <= 1024px) {
  #medical-institution .merit_area {
    padding-bottom: 201px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area {
    padding-bottom: 140px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .merit_area {
    padding-bottom: 201px;
  }
}

#medical-institution .merit_area .merit-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 48px;
  font-size: 2.5rem;
  font-weight: 700;
  display: inline-block;
  position: relative;
  left: calc(50% + 30px);
  transform: translateX(-50%);
}

@media screen and (width <= 1024px) {
  #medical-institution .merit_area .merit-title {
    font-size: 2.1875rem;
  }
}

@media screen and (width <= 896px) {
  #medical-institution .merit_area .merit-title {
    font-size: 2rem;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-title {
    letter-spacing: 1.32px;
    text-align: left;
    max-width: 304px;
    margin-bottom: 33px;
    font-size: 1.375rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#medical-institution .merit_area .merit-panel {
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 80px 77px 120px;
  position: relative;
}

@media screen and (width <= 1292px) {
  #medical-institution .merit_area .merit-panel {
    padding: 80px 5.5vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel {
    padding: 32px 5.6vw 48px;
  }
}

#medical-institution .merit_area .merit-panel:before {
  content: "";
  background: url("merit_img.140a5102.png") center / contain no-repeat;
  width: 10vw;
  height: 14.3571vw;
  position: absolute;
  top: -12.5vw;
  left: 4.07143vw;
}

@media screen and (width >= 1400px) {
  #medical-institution .merit_area .merit-panel:before {
    width: 140px;
    height: 201px;
    top: -175px;
    left: 57px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel:before {
    width: 74px;
    height: 106px;
    top: -88px;
    left: calc(50% + 114px);
    transform: translateX(-50%);
  }
}

#medical-institution .merit_area .merit-panel .merit-panel-section:not(:last-child) {
  margin-bottom: 64px;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel .merit-panel-section:not(:last-child) {
    margin-bottom: 32px;
  }
}

#medical-institution .merit_area .merit-panel .merit-panel-title {
  letter-spacing: 1.12px;
  margin-bottom: 32px;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel .merit-panel-title {
    letter-spacing: .54px;
    margin-bottom: 24px;
    font-size: 1.125rem;
    line-height: 1.6;
  }
}

#medical-institution .merit_area .merit-panel .merit-panel-text {
  letter-spacing: 1.08px;
  margin-bottom: 20px;
  font-size: 1.125rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel .merit-panel-text {
    letter-spacing: .84px;
    margin-bottom: 16px;
    font-size: .875rem;
  }
}

#medical-institution .merit_area .merit-panel .merit-panel-list-dot {
  margin-bottom: 20px;
  margin-left: 1.75em;
  list-style-type: disc;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel .merit-panel-list-dot {
    margin-bottom: 16px;
    margin-left: 1.25em;
  }
}

#medical-institution .merit_area .merit-panel .merit-panel-list-dot li {
  letter-spacing: 1.08px;
  font-size: 1.125rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-panel .merit-panel-list-dot li {
    letter-spacing: .84px;
    font-size: .875rem;
  }
}

#medical-institution .merit_area .merit-graph {
  background: url("bg_plaid.aead6740.png") 50% -1px / 25px;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 48px;
  padding: 37px 10px 22px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-graph {
    background-size: 17px;
  }
}

#medical-institution .merit_area .merit-graph .graph-figcaption {
  letter-spacing: .96px;
  text-align: center;
  margin-top: 39px;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-graph .graph-figcaption {
    letter-spacing: .72px;
    margin-top: 16px;
    font-size: .875rem;
    line-height: 1.6;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .merit_area .merit-graph .graph-figcaption {
    font-size: .75rem;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-graph {
    margin-bottom: 24px;
    padding: 121px 11px 22px;
  }
}

#medical-institution .merit_area .merit-graph .graph-figure {
  max-width: 718px;
  position: relative;
  left: calc(50% + 9px);
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-graph .graph-figure {
    max-width: 400px;
    left: 50%;
  }
}

#medical-institution .merit_area .merit-graph .graph-balloon {
  letter-spacing: 1.32px;
  text-align: center;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  padding: 22px 2.78571vw 26px;
  font-size: 1.375rem;
  font-weight: 700;
  display: inline-block;
  position: absolute;
  top: 47px;
  left: calc(50% - 133px);
  transform: translateX(-50%);
}

#medical-institution .merit_area .merit-graph .graph-balloon:before, #medical-institution .merit_area .merit-graph .graph-balloon:after {
  content: "";
  border-style: solid;
  position: absolute;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-graph .graph-balloon:before, #medical-institution .merit_area .merit-graph .graph-balloon:after {
    inset: auto auto 0 50%;
    translate: -50% 100%;
  }
}

#medical-institution .merit_area .merit-graph .graph-balloon:before, #medical-institution .merit_area .merit-graph .graph-balloon:after {
  top: 50%;
  right: 0;
  translate: 100% -50%;
}

#medical-institution .merit_area .merit-graph .graph-balloon:before {
  border-width: 10px 0 10px 12px;
  border-color: #0000 #0000 #0000 #333;
}

#medical-institution .merit_area .merit-graph .graph-balloon:after {
  border-width: 6.1px 0 6.1px 7.3px;
  border-color: #0000 #0000 #0000 #fff;
}

@media screen and (width >= 1400px) {
  #medical-institution .merit_area .merit-graph .graph-balloon {
    padding: 22px 39px 26px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-graph .graph-balloon {
    letter-spacing: .96px;
    box-sizing: border-box;
    width: 100%;
    max-width: 240px;
    padding: 12px 10px;
    font-size: 1rem;
    top: 29px;
    left: 50%;
    transform: translateX(-50%);
  }

  #medical-institution .merit_area .merit-graph .graph-balloon:before, #medical-institution .merit_area .merit-graph .graph-balloon:after {
    bottom: 0;
    left: 50%;
    right: auto;
    translate: -50% 100%;
  }

  #medical-institution .merit_area .merit-graph .graph-balloon:before {
    border-width: 12px 10px 0;
    border-color: #000 #0000 #0000;
  }

  #medical-institution .merit_area .merit-graph .graph-balloon:after {
    border-width: 7.3px 6.1px 0;
    border-color: #fff #0000 #0000;
  }
}

#medical-institution .merit_area .merit-introduce {
  background: #dfeeea;
  border-radius: 12px;
  padding: 26px 20px 28px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-introduce {
    padding: 32px 20px 58px;
  }
}

#medical-institution .merit_area .merit-introduce .merit-introduce-contents {
  text-align: center;
  max-width: 477px;
  position: relative;
  left: calc(50% - 35px);
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-introduce .merit-introduce-contents {
    text-align: left;
    max-width: none;
    position: static;
    transform: none;
  }
}

#medical-institution .merit_area .merit-introduce .merit-introduce-contents .contents-text {
  letter-spacing: 1.32px;
  font-size: 1.375rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-introduce .merit-introduce-contents .contents-text {
    letter-spacing: 1.08px;
    font-size: 1.125rem;
  }
}

#medical-institution .merit_area .merit-introduce .merit-introduce-contents .contents-text:first-of-type {
  margin-bottom: 12px;
}

#medical-institution .merit_area .merit-introduce .merit-introduce-contents .contents-text sub {
  font-size: 72%;
}

#medical-institution .merit_area .merit-introduce .merit-introduce-contents .contents-notes {
  letter-spacing: .72px;
  text-align: left;
  margin-top: 4px;
  font-size: .75rem;
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-introduce .merit-introduce-contents .contents-notes {
    letter-spacing: .6px;
    font-size: .625rem;
  }
}

#medical-institution .merit_area .merit-introduce .merit-introduce-img {
  width: 14.0714vw;
  position: absolute;
  top: -1.14286vw;
  right: -1.78571vw;
}

@media screen and (width >= 1400px) {
  #medical-institution .merit_area .merit-introduce .merit-introduce-img {
    width: 197px;
    top: -16px;
    right: -25px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .merit_area .merit-introduce .merit-introduce-img {
    width: 105px;
    top: auto;
    bottom: -27px;
    right: 5px;
  }
}

#medical-institution .point_area {
  background: #dfeeea;
  margin-top: -69px;
  padding: 152px 0 120px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area {
    margin-top: -62px;
    padding: 128px 0 64px;
  }
}

#medical-institution .point_area .point-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 80px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .point_area .point-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .point_area .point-title {
    margin-bottom: 80px;
  }
}

#medical-institution .point_area .point-list {
  flex-direction: column;
  gap: 80px 0;
  display: flex;
}

#medical-institution .point_area .point-list .point-item {
  box-sizing: border-box;
  counter-increment: cnt;
  background: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 80px;
  position: relative;
}

@media screen and (width <= 1292px) {
  #medical-institution .point_area .point-list .point-item {
    padding: 80px 5.71429vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item {
    padding: 56px 6.4vw 48px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-title {
  letter-spacing: 1.28px;
  justify-content: center;
  align-items: center;
  gap: 0 16px;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-title {
    letter-spacing: 1.32px;
    align-items: flex-start;
    font-size: 1.375rem;
    line-height: 1.4;
    top: -26px;
    left: calc(50% - 8px);
  }
}

#medical-institution .point_area .point-list .point-item .point-item-title:before {
  content: "0" counter(cnt);
  letter-spacing: 5.76px;
  color: #4b9c84;
  font-family: Montserrat, sans-serif;
  font-size: 4.5rem;
  line-height: 1;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-title:before {
    letter-spacing: 3.84px;
    font-size: 3rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-lead {
  justify-content: center;
  align-items: center;
  gap: 0 24px;
  max-width: 704px;
  margin: 0 auto 51px;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-lead {
    flex-direction: column;
    gap: 12px 0;
    margin-bottom: 32px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-lead .lead-text {
  letter-spacing: 1.2px;
  flex: 1 1 0;
  font-size: 1.25rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-lead .lead-text {
    letter-spacing: .84px;
    font-size: .875rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list {
  padding-top: 12px;
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item {
  padding: 32px;
}

@media screen and (width <= 767px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item {
    padding: 20px 4.53333vw 24px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item:not(:last-child) {
  border-bottom: 3px solid #333;
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross {
  flex-direction: column;
  gap: 32px 0;
  display: flex;
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li {
  letter-spacing: .96px;
  padding-left: 50px;
  font-size: 1.5rem;
  font-weight: 700;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li {
    letter-spacing: 1.08px;
    padding-left: 31px;
    font-size: 1.125rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li:before {
  content: "";
  background: url("icon_cross.56148737.svg") center / cover no-repeat;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 2px;
  left: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li:before {
    width: 23px;
    height: 23px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li .item-detail {
  letter-spacing: 1.08px;
  margin-top: 8px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li .item-detail {
    text-align: left;
    letter-spacing: .84px;
    font-size: .875rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li {
  padding-left: 30px;
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li:before {
  width: 20px;
  height: 20px;
  top: 8px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li:before {
    width: 23px;
    height: 23px;
  }

  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li {
    text-align: center;
    padding: 27px 0 0;
  }

  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-cross li:before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-number {
  letter-spacing: 1.08px;
  color: #4b9c84;
  text-align: center;
  margin-bottom: 4px;
  font-size: 1.125rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-number {
    letter-spacing: .96px;
    margin-bottom: 2px;
    font-size: 1rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-text {
  letter-spacing: .96px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-example-list .example-item .example-item-text {
    letter-spacing: 1.08px;
    width: 96%;
    margin: 0 auto;
    font-size: 1.125rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-notes {
  letter-spacing: .96px;
  margin-top: 12px;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-notes {
    margin-top: 8px;
    font-size: .875rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice {
  margin-top: 64px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice {
    margin-top: 48px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-title {
  letter-spacing: 1.28px;
  color: #4b9c84;
  text-align: center;
  margin-bottom: 48px;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-title {
    margin-bottom: 26px;
    font-size: 1.375rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list {
  flex-direction: column;
  gap: 48px 0;
  max-width: 880px;
  margin: 0 auto;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list {
    gap: 24px 0;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item {
  align-items: flex-end;
  gap: 0 20px;
  max-width: 768px;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item {
    padding-bottom: 35px;
    position: relative;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-img {
  width: 136px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-img {
    z-index: 1;
    width: 90px;
    position: absolute;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-text {
  flex: 1 1 0;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-text .voice-item-text-balloon {
  letter-spacing: 1.08px;
  background: #fff;
  border: 3px solid #333;
  border-radius: 17px;
  padding: 18px 32px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.8;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-text .voice-item-text-balloon {
    letter-spacing: .96px;
    font-size: 1rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-text .voice-item-text-balloon {
    padding: 16px 17px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-text .voice-item-text-figcaption {
  letter-spacing: .96px;
  margin-top: 16px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item .voice-item-text .voice-item-text-figcaption {
    letter-spacing: .84px;
    width: 42.6667vw;
    min-height: 42px;
    margin-top: 18px;
    font-size: .875rem;
    display: flex;
    position: relative;
    transform: translateX(-50%);
  }

  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-img {
    bottom: 0;
    left: 0;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon {
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  position: relative;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:after {
  content: "";
  border-style: solid;
  position: absolute;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:after {
    inset: auto auto 0 50%;
    translate: -50% 100%;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:after {
  top: 50%;
  left: 0;
  translate: -100% -50%;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:before {
  border-width: 10px 12px 10px 0;
  border-color: #0000 #333 #0000 #0000;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:after {
  border-width: 6.1px 7.3px 6.1px 0;
  border-color: #0000 #fff #0000 #0000;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:after {
  top: 75%;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-balloon:after {
    display: none;
  }

  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(odd) .voice-item-text-figcaption {
    left: calc(50% + 36px);
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) {
  flex-direction: row-reverse;
  margin: 0 0 0 auto;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) {
    flex-direction: column-reverse;
  }

  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-img {
    bottom: 0;
    right: 0;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon {
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  position: relative;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:after {
  content: "";
  border-style: solid;
  position: absolute;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:after {
    inset: auto auto 0 50%;
    translate: -50% 100%;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:after {
  top: 50%;
  right: 0;
  translate: 100% -50%;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:before {
  border-width: 10px 0 10px 12px;
  border-color: #0000 #0000 #0000 #333;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:after {
  border-width: 6.1px 0 6.1px 7.3px;
  border-color: #0000 #0000 #0000 #fff;
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:after {
  top: 75%;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:before, #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-balloon:after {
    display: none;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-figcaption {
  text-align: right;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-voice .voice-list .voice-item:nth-child(2n) .voice-item-text .voice-item-text-figcaption {
    text-align: left;
    justify-content: flex-end;
    left: calc(50% - 20px);
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph {
  background: url("bg_plaid.aead6740.png") 50% -1px / 25px;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  margin-top: 48px;
  padding: 37px 10px 25px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph {
    background-size: 17px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figcaption {
  letter-spacing: .96px;
  text-align: center;
  margin-top: 39px;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figcaption {
    letter-spacing: .72px;
    margin-top: 16px;
    font-size: .875rem;
    line-height: 1.6;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figcaption {
    font-size: .75rem;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph {
    margin-top: 32px;
    padding: 154px 12px 22px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure {
  width: 90%;
  max-width: 644px;
  position: relative;
  left: calc(50% + 2.14286vw);
  transform: translateX(-50%);
}

@media screen and (width >= 1400px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure {
    left: calc(50% + 30px);
  }
}

@media screen and (width <= 896px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure {
    width: 96%;
    left: calc(50% + 10px);
  }
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure {
    width: 100%;
    max-width: 400px;
    left: 50%;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-figcaption {
  text-align: left;
  margin-top: 11px;
  padding-left: 1.64286vw;
}

@media screen and (width >= 1400px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-figcaption {
    padding-left: 23px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-figcaption {
    margin-top: 35px;
    padding-left: 0;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type {
  align-items: center;
  gap: 0 16px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 50px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type {
    gap: 0 13px;
    bottom: 26px;
    right: 23px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item {
  letter-spacing: .96px;
  align-items: center;
  gap: 0 8px;
  font-weight: 700;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item {
    letter-spacing: .6px;
    gap: 0 6px;
    font-size: .75rem;
    line-height: 1.8;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item {
    font-size: .625rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item:before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item:before {
    width: 12px;
    height: 12px;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item.green:before {
  background: #4b9c84;
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-figure .graph-type .graph-type-item.yellow:before {
  background: #d5c356;
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon {
  letter-spacing: 1.32px;
  text-align: center;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  padding: 1.28571vw 1.85714vw 1.07143vw;
  font-size: 1.42857vw;
  font-weight: 700;
  display: inline-block;
  position: absolute;
  top: 3.85714vw;
  left: calc(50% - 17vw);
  transform: translateX(-50%);
}

@media screen and (width >= 1400px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon {
    padding: 18px 26px 15px;
    font-size: 1.25rem;
    top: 54px;
    left: calc(50% - 238px);
  }
}

@media screen and (width <= 1024px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon {
    left: calc(50% - 180px);
  }
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon {
    letter-spacing: .96px;
    box-sizing: border-box;
    width: 100%;
    max-width: 240px;
    padding: 12px 10px 8px;
    font-size: .875rem;
    top: 29px;
    left: 50%;
    transform: translateX(-50%);
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon .text-number {
  letter-spacing: 1.6px;
  color: #d5c356;
  font-size: 2.5rem;
  line-height: 1.4;
  display: block;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon .text-number {
    font-size: 1.75rem;
  }
}

#medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon .text-number sup {
  color: #333;
  font-size: 35%;
  top: .625em;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item .point-item-graph .graph-balloon .text-number sup {
    font-size: 45%;
  }
}

#medical-institution .point_area .point-list .point-item:first-child .point-item-lead .lead-img {
  width: 43%;
  max-width: 300px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item:first-child .point-item-lead .lead-img {
    width: 100%;
    max-width: 188px;
  }
}

#medical-institution .point_area .point-list .point-item:nth-child(2) .point-item-lead .lead-img {
  width: 39%;
  max-width: 270px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item:nth-child(2) .point-item-lead .lead-img {
    width: 100%;
    max-width: 180px;
  }
}

#medical-institution .point_area .point-list .point-item:nth-child(3) .point-item-lead {
  gap: 0 32px;
  max-width: 728px;
}

#medical-institution .point_area .point-list .point-item:nth-child(3) .point-item-lead .lead-img {
  width: 49%;
  max-width: 351px;
}

@media screen and (width <= 768px) {
  #medical-institution .point_area .point-list .point-item:nth-child(3) .point-item-lead .lead-img {
    width: 100%;
    max-width: 234px;
  }
}

#medical-institution .cases_area {
  background: #fff;
  padding: 80px 0 158px;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area {
    padding: 64px 0;
  }
}

@media screen and (width <= 1024px) {
  #medical-institution .cases_area {
    padding-bottom: 201px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area {
    padding-bottom: 140px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .cases_area {
    padding-bottom: 201px;
  }
}

#medical-institution .cases_area .cases-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .cases_area .cases-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .cases_area .cases-title {
    margin-bottom: 48px;
  }
}

#medical-institution .cases_area .cases-panel {
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 80px 61px;
}

@media screen and (width <= 1292px) {
  #medical-institution .cases_area .cases-panel {
    padding: 80px 4.35714vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-panel {
    padding: 32px 5.6vw;
  }
}

#medical-institution .cases_area .cases-panel .cases-panel-section:not(:last-child) {
  margin-bottom: 48px;
}

#medical-institution .cases_area .cases-panel .cases-panel-title {
  letter-spacing: 1.12px;
  text-align: center;
  margin-bottom: 8px;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-panel .cases-panel-title {
    letter-spacing: 1.2px;
    font-size: 1.25rem;
  }
}

#medical-institution .cases_area .cases-panel .cases-panel-lead {
  letter-spacing: .96px;
  color: #868686;
  text-align: center;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-panel .cases-panel-lead {
    letter-spacing: .72px;
    font-size: .75rem;
  }
}

#medical-institution .cases_area .cases-list .cases-item-institution, #medical-institution .cases_area .cases-others-list .cases-item-institution {
  color: #4b9c84;
}

#medical-institution .cases_area .cases-list .cases-item-institution, #medical-institution .cases_area .cases-list .cases-item-title, #medical-institution .cases_area .cases-others-list .cases-item-institution, #medical-institution .cases_area .cases-others-list .cases-item-title {
  font-weight: 700;
  text-decoration: underline;
  transition: all .6s;
}

#medical-institution .cases_area .cases-list .cases-item-institution:hover, #medical-institution .cases_area .cases-list .cases-item-title:hover, #medical-institution .cases_area .cases-others-list .cases-item-institution:hover, #medical-institution .cases_area .cases-others-list .cases-item-title:hover {
  opacity: .6;
  text-decoration: none;
}

#medical-institution .cases_area .cases-list {
  grid-template-columns: repeat(auto-fit, minmax(0, 256px));
  justify-content: center;
  gap: 32px;
  display: grid;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-list {
    grid-template-columns: repeat(auto-fit, minmax(0, 300px));
  }
}

#medical-institution .cases_area .cases-list .cases-item .cases-item-img {
  border-radius: 8px;
  margin-bottom: 12px;
  overflow: hidden;
}

#medical-institution .cases_area .cases-list .cases-item .cases-item-institution, #medical-institution .cases_area .cases-list .cases-item .cases-item-soon {
  letter-spacing: 1.2px;
  font-size: 1.25rem;
  font-weight: 700;
}

#medical-institution .cases_area .cases-list .cases-item .cases-item-soon {
  color: #868686;
}

#medical-institution .cases_area .cases-list .cases-item .cases-item-title {
  color: #333;
  letter-spacing: .96px;
  margin-top: 8px;
}

#medical-institution .cases_area .cases-list .cases-item .cases-item-notes {
  letter-spacing: .84px;
  color: #868686;
  margin-top: 8px;
  font-size: .875rem;
}

#medical-institution .cases_area .cases-others-list {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 24px;
  margin-top: 32px;
  display: grid;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-others-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

#medical-institution .cases_area .cases-others-list .cases-others-item {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 16px;
}

#medical-institution .cases_area .cases-others-list .cases-others-item .cases-item-institution {
  letter-spacing: 1.08px;
  font-size: 1.125rem;
}

@media screen and (width <= 768px) {
  #medical-institution .cases_area .cases-others-list .cases-others-item .cases-item-institution {
    letter-spacing: .96px;
    font-size: 1rem;
  }
}

#medical-institution .support_area {
  margin-top: -69px;
  padding: 152px 0 120px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area {
    margin-top: -62px;
    padding: 128px 0 64px;
  }
}

#medical-institution .support_area .support-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 80px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .support_area .support-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .support_area .support-title {
    margin-bottom: 48px;
  }
}

#medical-institution .support_area .support-panel {
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 48px 61px 80px;
}

@media screen and (width <= 1292px) {
  #medical-institution .support_area .support-panel {
    padding: 80px 4.35714vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-panel {
    padding: 32px 5.6vw 48px;
  }
}

#medical-institution .support_area .support-lead {
  align-items: center;
  gap: 12px 16px;
  max-width: 792px;
  margin: 0 auto 32px;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-lead {
    flex-direction: column;
    margin-bottom: 40px;
  }
}

#medical-institution .support_area .support-lead .support-lead-text {
  letter-spacing: .96px;
  flex: 1 1 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-lead .support-lead-text {
    text-align: center;
    font-size: 1rem;
  }
}

#medical-institution .support_area .support-lead .support-lead-img {
  width: 47%;
  max-width: 376px;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-lead .support-lead-img {
    width: 100%;
    max-width: 248px;
  }
}

#medical-institution .support_area .support-list {
  flex-direction: column;
  gap: 26px 0;
  padding: 64px 3.35714vw 48px;
  display: flex;
}

#medical-institution .support_area .support-list li {
  letter-spacing: .96px;
  padding-left: 40px;
  font-size: 1.5rem;
  font-weight: 700;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-list li {
    padding-left: 31px;
    font-size: 1rem;
  }
}

#medical-institution .support_area .support-list li:before, #medical-institution .support_area .support-list li:after {
  content: "";
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

#medical-institution .support_area .support-list li:before {
  background: #4b9c84;
  width: 28px;
  height: 28px;
  top: 5px;
  left: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-list li:before {
    width: 23px;
    height: 23px;
    top: 1px;
  }
}

#medical-institution .support_area .support-list li:after {
  background: #fff;
  width: 16px;
  height: 16px;
  top: 11px;
  left: 6px;
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-list li:after {
    width: 13px;
    height: 13px;
    top: 6px;
    left: 5px;
  }
}

@media screen and (width >= 1400px) {
  #medical-institution .support_area .support-list {
    padding: 64px 47px 48px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .support_area .support-list {
    padding: 32px 5.33333vw 24px;
  }
}

#medical-institution .faq_area {
  background: #fff;
  padding: 80px 0 120px;
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area {
    padding: 64px 0;
  }
}

#medical-institution .faq_area .faq-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area .faq-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .faq_area .faq-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area .faq-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .faq_area .faq-title {
    margin-bottom: 48px;
  }
}

#medical-institution .faq_area .faq-panel {
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 80px 61px;
}

@media screen and (width <= 1292px) {
  #medical-institution .faq_area .faq-panel {
    padding: 80px 4.35714vw;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area .faq-panel {
    padding: 32px 5.6vw;
  }
}

#medical-institution .faq_area .faq-panel .faq-list {
  max-width: 870px;
  margin: 0 auto;
}

#medical-institution .faq_area .faq-panel .faq-list .faq-question {
  letter-spacing: .96px;
  margin-bottom: 17px;
  padding-left: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area .faq-panel .faq-list .faq-question {
    letter-spacing: 1.2px;
    margin-bottom: 8px;
    padding-left: 36px;
    font-size: 1.25rem;
  }
}

#medical-institution .faq_area .faq-panel .faq-list .faq-question:before {
  content: "Q";
  color: #4b9c84;
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: -4px;
  left: 0;
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area .faq-panel .faq-list .faq-question:before {
    font-size: 1.75rem;
    top: 0;
  }
}

#medical-institution .faq_area .faq-panel .faq-list .faq-answer {
  letter-spacing: 1.08px;
  margin-left: 50px;
  font-size: 1.125rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .faq_area .faq-panel .faq-list .faq-answer {
    letter-spacing: .96px;
    margin-left: 36px;
    font-size: 1rem;
  }
}

#medical-institution .faq_area .faq-panel .faq-list .faq-answer:not(:last-of-type) {
  background: url("line_dot.e759f85d.png") bottom / 11px repeat-x;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

@media screen and (width <= 767px) {
  #medical-institution .faq_area .faq-panel .faq-list .faq-answer:not(:last-of-type) {
    margin-bottom: 20px;
    padding-bottom: 16px;
  }
}

#medical-institution .feature_area {
  padding: 80px 0 158px;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area {
    padding: 64px 0;
  }
}

@media screen and (width <= 1024px) {
  #medical-institution .feature_area {
    padding-bottom: 201px;
  }
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area {
    padding-bottom: 140px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .feature_area {
    padding-bottom: 201px;
  }
}

#medical-institution .feature_area .feature-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .feature_area .feature-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .feature_area .feature-title {
    margin-bottom: 48px;
  }
}

#medical-institution .feature_area .feature-wrap {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-wrap {
    flex-direction: column;
    gap: 32px 0;
  }
}

#medical-institution .feature_area .feature-table-reserve, #medical-institution .feature_area .feature-table-cost {
  border-collapse: separate;
  background: #fff;
}

#medical-institution .feature_area .feature-table-reserve th, #medical-institution .feature_area .feature-table-reserve td, #medical-institution .feature_area .feature-table-cost th, #medical-institution .feature_area .feature-table-cost td {
  letter-spacing: 1.2px;
  vertical-align: middle;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
  padding: 19px 24px;
  font-size: 1.25rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-reserve th, #medical-institution .feature_area .feature-table-reserve td, #medical-institution .feature_area .feature-table-cost th, #medical-institution .feature_area .feature-table-cost td {
    letter-spacing: .96px;
    padding: 15px 16px;
    font-size: 1rem;
  }
}

#medical-institution .feature_area .feature-table-reserve th, #medical-institution .feature_area .feature-table-cost th {
  text-align: center;
}

#medical-institution .feature_area .feature-table-reserve thead th, #medical-institution .feature_area .feature-table-cost thead th {
  color: #fff;
  background: #4b9c84;
  border-top: 2px solid #333;
}

#medical-institution .feature_area .feature-table-reserve tbody th, #medical-institution .feature_area .feature-table-cost tbody th {
  background: #dfeeea;
}

#medical-institution .feature_area .feature-table-reserve {
  border-left: 2px solid #333;
  border-radius: 8px 0 0 8px;
  width: 63%;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-reserve {
    width: 100%;
  }
}

#medical-institution .feature_area .feature-table-reserve thead .reserve-heading:first-child {
  border-radius: 8px 0 0;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-reserve thead .reserve-heading:last-child {
    border-radius: 0 8px 0 0;
  }
}

#medical-institution .feature_area .feature-table-reserve tbody tr:last-child .reserve-heading {
  border-radius: 0 0 0 8px;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-reserve tbody tr:last-child .reserve-data {
    border-radius: 0 0 8px;
  }
}

#medical-institution .feature_area .feature-table-cost {
  width: 37%;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-cost {
    border-left: 2px solid #333;
    border-radius: 8px 0 0 8px;
    width: 100%;
  }
}

#medical-institution .feature_area .feature-table-cost thead .cost-heading:first-child {
  border-radius: 0 8px 0 0;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-cost thead .cost-heading:first-child {
    border-radius: 8px 8px 0 0;
  }
}

#medical-institution .feature_area .feature-table-cost tbody .cost-data:first-child {
  border-radius: 0 0 8px;
}

@media screen and (width <= 768px) {
  #medical-institution .feature_area .feature-table-cost tbody .cost-data:first-child {
    border-radius: 0 0 8px 8px;
  }
}

#medical-institution .flow_area {
  background: #dfeeea;
  margin-top: -69px;
  padding: 152px 0 120px;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area {
    margin-top: -62px;
    padding: 128px 0 64px;
  }
}

#medical-institution .flow_area .flow-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .flow_area .flow-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .flow_area .flow-title {
    margin-bottom: 40px;
  }
}

#medical-institution .flow_area .flow-list {
  box-sizing: border-box;
  flex-direction: column;
  gap: 48px 0;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
}

@media screen and (width <= 767px) {
  #medical-institution .flow_area .flow-list {
    gap: 32px 0;
    max-width: 400px;
  }
}

#medical-institution .flow_area .flow-list .flow-item {
  background: #fff;
  border: 3px solid #333;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  #medical-institution .flow_area .flow-list .flow-item {
    border-radius: 8px;
    flex-direction: column;
  }
}

#medical-institution .flow_area .flow-list .flow-item .flow-item-step {
  box-sizing: border-box;
  letter-spacing: 1.44px;
  color: #fff;
  background: #4b9c84;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.25;
  display: flex;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-step {
    width: 160px;
    font-size: 1.25rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-step {
    flex-direction: row;
    width: 100%;
    height: 52px;
    min-height: auto;
    font-size: 1.125rem;
  }
}

#medical-institution .flow_area .flow-list .flow-item .flow-item-step .step-number {
  font-size: 2.5rem;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-step .step-number {
    font-size: 2rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-step .step-number {
    margin: 0 0 0 5px;
    font-size: 1.125rem;
  }
}

#medical-institution .flow_area .flow-list .flow-item .flow-item-contents {
  flex: 1 1 0;
  padding: 32px;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-contents {
    padding: 30px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-contents {
    padding: 20px 24px;
  }
}

#medical-institution .flow_area .flow-list .flow-item .flow-item-contents .flow-item-title {
  letter-spacing: 1.12px;
  color: #4b9c84;
  margin-bottom: 8px;
  font-size: 1.75rem;
  font-weight: bold;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-contents .flow-item-title {
    font-size: 1.25rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-contents .flow-item-title {
    letter-spacing: 1.08px;
    text-align: center;
    font-size: 1.125rem;
  }
}

#medical-institution .flow_area .flow-list .flow-item .flow-item-contents .flow-item-text {
  letter-spacing: 1.2px;
  font-size: 1.25rem;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .flow_area .flow-list .flow-item .flow-item-contents .flow-item-text {
    letter-spacing: .96px;
    font-size: 1rem;
    line-height: 1.8;
  }
}

#medical-institution .aboutus_area {
  background: #fff;
  padding: 80px 0 120px;
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area {
    padding: 64px 0;
  }
}

#medical-institution .aboutus_area .aboutus-title {
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 64px;
  padding-bottom: 32px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area .aboutus-title {
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

#medical-institution .aboutus_area .aboutus-title:after {
  content: "";
  background: url("title_dots.fd10018c.svg") center / cover no-repeat;
  width: 68px;
  height: 12px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area .aboutus-title:after {
    width: 55px;
    height: 10px;
  }

  #medical-institution .aboutus_area .aboutus-title {
    margin-bottom: 48px;
  }
}

#medical-institution .aboutus_area .aboutus-lead {
  letter-spacing: 1.2px;
  text-align: center;
  max-width: 790px;
  margin: 0 auto 48px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area .aboutus-lead {
    font-size: 1.125rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .aboutus_area .aboutus-lead {
    letter-spacing: .96px;
    margin-bottom: 16px;
    font-size: 1rem;
    font-weight: 500;
  }
}

#medical-institution .aboutus_area .aboutus-logo {
  max-width: 333px;
  margin: 0 auto 64px;
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area .aboutus-logo {
    max-width: 250px;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .aboutus_area .aboutus-logo {
    max-width: 196px;
    margin-bottom: 48px;
  }
}

#medical-institution .aboutus_area .aboutus-list {
  background: url("line_dot.e759f85d.png") 0 0 / 11px repeat-x;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 0 auto;
  padding-top: 4px;
  display: flex;
}

@media screen and (width <= 767px) {
  #medical-institution .aboutus_area .aboutus-list {
    background-position: top;
  }
}

#medical-institution .aboutus_area .aboutus-list .aboutus-term, #medical-institution .aboutus_area .aboutus-list .aboutus-description {
  letter-spacing: 1.2px;
  box-sizing: border-box;
  background: url("line_dot.e759f85d.png") 0 100% / 11px repeat-x;
  font-size: 1.25rem;
  line-height: 1.8;
}

@media screen and (width <= 767px) {
  #medical-institution .aboutus_area .aboutus-list .aboutus-term, #medical-institution .aboutus_area .aboutus-list .aboutus-description {
    background-position: bottom;
    line-height: 1.73;
  }
}

#medical-institution .aboutus_area .aboutus-list .aboutus-term {
  width: 256px;
  padding: 32px 24px;
  font-weight: bold;
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area .aboutus-list .aboutus-term {
    font-size: 1.125rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .aboutus_area .aboutus-list .aboutus-term {
    letter-spacing: 1.08px;
    background: none;
    width: 100%;
    padding: 16px 12px 0;
  }
}

#medical-institution .aboutus_area .aboutus-list .aboutus-description {
  letter-spacing: .03125em;
  width: calc(100% - 256px);
  padding: 32px 24px;
}

@media screen and (width <= 768px) {
  #medical-institution .aboutus_area .aboutus-list .aboutus-description {
    font-size: 1.125rem;
  }
}

@media screen and (width <= 767px) {
  #medical-institution .aboutus_area .aboutus-list .aboutus-description {
    letter-spacing: .96px;
    width: 100%;
    padding: 0 12px 16px;
    font-size: 1rem;
  }
}

#demo {
  background: #dfeeea;
}

#demo .demo-contents {
  padding: 5.35714vw 0;
  position: relative;
}

@media screen and (width >= 1400px) {
  #demo .demo-contents {
    padding: 75px 0;
  }
}

@media screen and (width <= 768px) {
  #demo .demo-contents {
    padding: 48px 0 220px;
  }
}

#demo .demo-contents .demo-contents-text {
  width: 400px;
  margin-left: 3.57143vw;
}

@media screen and (width >= 1400px) {
  #demo .demo-contents .demo-contents-text {
    margin-left: 50px;
  }
}

@media screen and (width <= 1292px) {
  #demo .demo-contents .demo-contents-text {
    margin-left: 0;
  }
}

@media screen and (width <= 768px) {
  #demo .demo-contents .demo-contents-text {
    text-align: center;
    order: 2;
    width: 100%;
    margin-top: 0;
  }
}

#demo .demo-contents .demo-contents-text .demo-title {
  color: #4b9c84;
  text-align: center;
  margin-bottom: 32px;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.46;
}

@media screen and (width <= 1024px) {
  #demo .demo-contents .demo-contents-text .demo-title {
    font-size: 1.5rem;
  }
}

@media screen and (width <= 768px) {
  #demo .demo-contents .demo-contents-text .demo-title {
    margin-bottom: 24px;
    font-size: 1.25rem;
  }
}

#demo .demo-contents .demo-contents-text .demo-btn {
  flex-direction: column;
  align-items: center;
  gap: 27px 0;
  display: flex;
}

@media screen and (width <= 768px) {
  #demo .demo-contents .demo-contents-text .demo-btn {
    gap: 20px 0;
  }
}

#demo .demo-contents .demo-contents-text .demo-btn .demo-btn-item {
  width: 400px;
}

@media screen and (width <= 768px) {
  #demo .demo-contents .demo-contents-text .demo-btn .demo-btn-item {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 320px;
    display: flex;
  }
}

#demo .demo-contents .demo-contents-img {
  width: 49.1429vw;
  position: absolute;
  top: calc(50% + 40px);
  left: calc(50% + 20vw);
  transform: translate(-50%, -50%);
}

@media screen and (width >= 1400px) {
  #demo .demo-contents .demo-contents-img {
    width: 688px;
    left: calc(50% + 280px);
  }
}

@media screen and (width <= 1024px) {
  #demo .demo-contents .demo-contents-img {
    left: calc(50% + 240px);
  }
}

@media screen and (width <= 896px) {
  #demo .demo-contents .demo-contents-img {
    width: 44.643vw;
  }
}

@media screen and (width <= 768px) {
  #demo .demo-contents .demo-contents-img {
    width: calc(100% + 37px);
    max-width: 361px;
    top: auto;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.pharmacy {
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 769px) {
  .pharmacy {
    overflow: visible;
  }
}

.pharmacy .section {
  padding: 0;
}

.pharmacy .kv {
  padding: 18.6667vw 0 16.2667vw;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .kv {
    margin-bottom: 52px;
    padding: 1.42857vw 0 4.85714vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .kv {
    padding: 20px 0 68px;
  }
}

.pharmacy .kv:before, .pharmacy .kv:after {
  content: "";
  z-index: -1;
  position: absolute;
}

@media screen and (width >= 769px) {
  .pharmacy .kv:before, .pharmacy .kv:after {
    z-index: 1;
  }
}

.pharmacy .kv:before {
  background: url("kv_icon_capsule_sp.1829387b.png") center / contain no-repeat;
  width: 26.4vw;
  max-width: 150px;
  height: 28.8vw;
  top: -6.66667vw;
  right: 20vw;
}

@media screen and (width >= 769px) {
  .pharmacy .kv:before {
    background-image: url("kv_icon_capsule.1edc40ad.png");
    width: 12.3571vw;
    height: 11.1429vw;
    top: -7.71429vw;
    left: calc(50% - 21.7143vw);
    right: auto;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .kv:before {
    width: 173px;
    height: 156px;
    top: -108px;
    left: calc(50% - 304px);
  }
}

.pharmacy .kv:after {
  display: none;
}

@media screen and (width >= 769px) {
  .pharmacy .kv:after {
    background: url("kv_icon_tablet.7813ede6.png") center / contain no-repeat;
    width: 9.5vw;
    height: 8.57143vw;
    display: block;
    bottom: 0;
    left: calc(50% + 12.2857vw);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .kv:after {
    width: 133px;
    height: 120px;
    left: calc(50% + 172px);
  }
}

@media screen and (width >= 769px) {
  .pharmacy .kv .kv_inner {
    position: relative;
  }
}

.pharmacy .kv .kv-img {
  z-index: -1;
  width: 92vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .kv .kv-img {
    width: 90.2143vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .kv .kv-img {
    width: 1263px;
  }
}

.pharmacy .kv .kv-title {
  letter-spacing: .96px;
  color: #4b9c84;
  text-align: center;
  width: 81.0667vw;
  margin: -2.13333vw auto 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 37px;
}

@media screen and (width >= 769px) {
  .pharmacy .kv .kv-title {
    text-align: left;
    width: 29.7857vw;
    margin: 0;
    font-size: 3.14286vw;
    line-height: 5.85714vw;
    position: absolute;
    top: 50%;
    right: 5.71429vw;
    transform: translateY(-50%);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .kv .kv-title {
    width: 417px;
    font-size: 44px;
    line-height: 82px;
    right: 80px;
  }
}

.pharmacy .section-about {
  background-color: #0000;
  background-image: url("bg_wavy_line_green.46838664.png"), linear-gradient(#f6f6f6 0% 50%, #dfeeea 50% 100%);
  background-position: 50%;
  background-repeat: repeat-x;
  background-size: 1400px;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.pharmacy .section-about .about-panel {
  z-index: 1;
  background: #fff;
  padding: 39px 5.86667vw;
  position: relative;
}

.pharmacy .section-about .about-panel:before, .pharmacy .section-about .about-panel:after {
  content: "";
  z-index: -1;
  border-style: solid;
  border-color: #333;
  display: block;
  position: absolute;
}

.pharmacy .section-about .about-panel:before {
  border-width: 2px 0;
  width: calc(100% - 20px);
  height: calc(100% - 2px);
  top: -1px;
  left: 10px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel:before {
    border-width: 4px 0;
    width: calc(100% - 40px);
    height: calc(100% - 4px);
    top: -2px;
    left: 20px;
  }
}

.pharmacy .section-about .about-panel:after {
  border-width: 0 2px;
  width: calc(100% - 2px);
  height: calc(100% - 20px);
  top: 10px;
  left: -1px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel:after {
    border-width: 0 4px;
    width: calc(100% - 4px);
    height: calc(100% - 40px);
    top: 20px;
    left: -2px;
  }

  .pharmacy .section-about .about-panel {
    padding: 5vw 5.57143vw 60px;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-about .about-panel {
    padding: 70px 78px 60px;
  }
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel .about-panel-lead {
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;
    display: flex;
  }
}

.pharmacy .section-about .about-panel .about-panel-lead-title {
  text-align: center;
  margin-bottom: 18px;
  padding-bottom: 34px;
  font-size: 8.53333vw;
  font-weight: bold;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel .about-panel-lead-title {
    text-align: left;
    margin: 0 40px 0 0;
    padding: 0 11px 0 0;
  }
}

.pharmacy .section-about .about-panel .about-panel-lead-title:after {
  content: "";
  background: url("icon_headline_tail_green.6ae02568.svg") center / contain no-repeat;
  width: 126px;
  height: 12px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel .about-panel-lead-title:after {
    inset: 50% 0 auto auto;
    transform: translateY(-50%)translateX(45%)rotate(-90deg);
  }
}

@media screen and (width >= 375px) {
  .pharmacy .section-about .about-panel .about-panel-lead-title {
    font-size: 32px;
  }
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel .about-panel-lead-title {
    width: 32.3571vw;
    margin-right: 2.85714vw;
    font-size: 3.28571vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-about .about-panel .about-panel-lead-title {
    width: 453px;
    margin-right: 40px;
    font-size: 46px;
  }
}

.pharmacy .section-about .about-panel .about-panel-lead-text {
  margin-bottom: 30px;
  padding: 0 2.13333vw;
  font-size: 18px;
  line-height: 1.89;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-panel .about-panel-lead-text {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0;
  }
}

.pharmacy .section-about .about-img {
  max-width: 284px;
  margin: 0 auto;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .about-img {
    max-width: 947px;
  }
}

.pharmacy .section-about .icon-deco-medicine-left-about {
  width: 100px;
  top: -19px;
  left: -39px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .icon-deco-medicine-left-about {
    width: 11.4286vw;
    top: -2vw;
    left: -4.14286vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-about .icon-deco-medicine-left-about {
    width: 160px;
    top: -28px;
    left: -58px;
  }
}

.pharmacy .section-about .icon-deco-medicine-right-about {
  width: 74px;
  bottom: -18px;
  right: -35px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .icon-deco-medicine-right-about {
    width: 11.7857vw;
    bottom: -2.42857vw;
    right: -2.71429vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-about .icon-deco-medicine-right-about {
    width: 165px;
    bottom: -34px;
    right: -38px;
  }
}

.pharmacy .section-about .icon-deco-tablet-about {
  top: 41px;
  right: -30px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-about .icon-deco-tablet-about {
    display: none;
  }
}

.pharmacy .section-image {
  background: #dfeeea;
  padding-top: 60px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image {
    padding-top: 120px;
  }
}

.pharmacy .section-image .image-title {
  text-align: center;
  margin-bottom: 43px;
  font-size: 32px;
  font-weight: bold;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-title {
    margin-bottom: 60px;
    font-size: 46px;
  }
}

.pharmacy .section-image .image-list .image-item {
  z-index: 1;
  position: relative;
}

.pharmacy .section-image .image-list .image-item .image-item-inner {
  box-sizing: border-box;
  z-index: 1;
  background: #fff;
  width: calc(100% - 10.6667vw);
  padding: 44px 5.33333vw 40px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .image-item-inner {
    align-items: center;
    max-width: 1000px;
    padding: 47px 1.71429vw 40px 2.85714vw;
    display: flex;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .image-item-inner {
    padding: 47px 24px 40px 40px;
  }
}

.pharmacy .section-image .image-list .image-item:nth-child(odd) .image-item-inner {
  border-radius: 0 40px 40px 0;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item:nth-child(odd) .image-item-inner {
    border-radius: 40px;
  }
}

.pharmacy .section-image .image-list .image-item:nth-child(odd) .image-item-inner:after {
  left: calc(50% - 10px);
}

.pharmacy .section-image .image-list .image-item:nth-child(2n) .image-item-inner {
  border-radius: 40px 0 0 40px;
  margin-left: auto;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item:nth-child(2n) .image-item-inner {
    border-radius: 40px;
  }
}

.pharmacy .section-image .image-list .image-item:not(:last-child) {
  padding-bottom: 40px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item:not(:last-child) {
    padding-bottom: 60px;
  }
}

.pharmacy .section-image .image-list .image-item:not(:last-child):after {
  content: "";
  z-index: 0;
  background: url("image_bg_arrow.a68bd7fa.svg") center / contain no-repeat;
  width: 5px;
  height: 60px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.pharmacy .section-image .image-list .image-item:nth-last-child(2):after {
  background-image: url("image_bg_arrow_last.c2754a00.svg");
  width: 46px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .image-item-text {
    flex: 1 1 0;
  }
}

.pharmacy .section-image .image-list .image-item .image-item-text .image-item-text-title {
  margin-bottom: 20px;
  padding-left: 67px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .image-item-text .image-item-text-title {
    padding-left: 94px;
    font-size: 30px;
  }
}

.pharmacy .section-image .image-list .image-item .image-item-text .image-item-text-detail {
  font-size: 18px;
  line-height: 1.89;
}

.pharmacy .section-image .image-list .image-item .image-item-text .image-item-text-note {
  margin-top: 8px;
  padding-left: 16px;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
}

.pharmacy .section-image .image-list .image-item .image-item-text .image-item-text-note:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}

.pharmacy .section-image .image-list .image-item .image-item-img {
  max-width: 400px;
  margin: 20px auto 0;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .image-item-img {
    width: 29%;
    max-width: 266px;
    margin-top: 0;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-01 {
  top: -37px;
  left: 15px;
  transform: rotate(265deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-01 {
    top: -3.92857vw;
    left: 2.85714vw;
    transform: rotate(285deg);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-01 {
    top: -55px;
    left: 40px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-image-01 {
  bottom: -17px;
  right: 67px;
  transform: rotate(10deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-image-01 {
    bottom: -2vw;
    right: 17.1429vw;
    transform: none;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-image-01 {
    bottom: -28px;
    right: 240px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-left-image-02 {
  display: none;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-left-image-02 {
    display: block;
    top: 6.85714vw;
    left: -14.2857vw;
    transform: rotate(285deg);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-left-image-02 {
    top: 96px;
    left: -200px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-right-image-02 {
  top: -27px;
  right: 63px;
  transform: rotate(90deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-right-image-02 {
    top: -2.85714vw;
    right: 20.3571vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-right-image-02 {
    top: -40px;
    right: 285px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-03 {
  top: -44px;
  right: 30px;
  transform: rotate(270deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-03 {
    top: -4.5vw;
    right: 15vw;
    transform: rotate(265deg);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-03 {
    top: -63px;
    right: 210px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-left-image-03 {
  bottom: -18px;
  left: 20px;
  transform: rotate(10deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-left-image-03 {
    bottom: -2.14286vw;
    left: 16.8571vw;
    transform: none;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-left-image-03 {
    bottom: -30px;
    left: 236px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-right-image-03 {
  display: none;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-right-image-03 {
    display: block;
    right: -14.2857vw;
    transform: rotate(315deg);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-right-image-03 {
    right: -200px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-04 {
  display: none;
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-04 {
    display: block;
    top: 60px;
    left: -10vw;
    transform: rotate(190deg);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-capsule-image-04 {
    right: -140px;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-top-image-04 {
  top: -10px;
  right: 20px;
  transform: rotate(90deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-top-image-04 {
    display: none;
  }
}

.pharmacy .section-image .image-list .image-item .icon-deco-tablet-bottom-image-04 {
  bottom: -25px;
  left: 60px;
  transform: rotate(90deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-bottom-image-04 {
    bottom: -2.14286vw;
    left: auto;
    right: 19.2857vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-image .image-list .image-item .icon-deco-tablet-bottom-image-04 {
    bottom: -30px;
    right: 270px;
  }
}

.pharmacy .section-value {
  background: #dfeeea;
  padding-top: 50px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-value {
    padding-top: 187px;
  }
}

.pharmacy .section-value .value-wrap {
  position: relative;
}

.pharmacy .section-value .value-text {
  z-index: 0;
  background: #fff;
  border-radius: 0 0 60px 60px;
  padding: 60px 6.66667vw 50px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .section-value .value-text {
    box-sizing: border-box;
    border-radius: 60px;
    width: 62%;
    max-width: 740px;
    margin: 0 0 0 auto;
    padding: 12.3571vw 5.57143vw 11.6429vw 15.8571vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-value .value-text {
    padding: 173px 78px 163px 222px;
  }
}

.pharmacy .section-value .value-title {
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: bold;
}

@media screen and (width >= 769px) {
  .pharmacy .section-value .value-title {
    margin-bottom: 42px;
    font-size: 46px;
  }
}

.pharmacy .section-value .value-detail {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.85;
}

@media screen and (width >= 769px) {
  .pharmacy .section-value .value-detail {
    font-size: 22px;
    line-height: 2;
  }
}

.pharmacy .section-value .value-img {
  box-sizing: border-box;
  background: #f6f6f6;
  border-radius: 60px 60px 0 0;
  padding: 8vw 5.06667vw;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 769px) {
  .pharmacy .section-value .value-img {
    z-index: 1;
    border-radius: 60px;
    width: 50%;
    max-width: 600px;
    padding: 52px 28px;
    position: absolute;
    top: -70px;
    left: 0;
  }
}

.pharmacy .section-value .icon-deco-capsule-value {
  top: -93.3333vw;
  right: 0;
  transform: rotate(270deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-value .icon-deco-capsule-value {
    top: -2.42857vw;
    left: calc(50% - 5.35714vw);
    right: auto;
    transform: translateX(-50%)rotate(290deg);
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-value .icon-deco-capsule-value {
    top: -34px;
    left: calc(50% - 75px);
  }
}

.pharmacy .section-value .icon-deco-tablet-value {
  bottom: -20px;
  right: 38px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-value .icon-deco-tablet-value {
    bottom: -1.42857vw;
    right: 2.42857vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-value .icon-deco-tablet-value {
    bottom: -20px;
    right: 34px;
  }
}

.pharmacy .section-price {
  background: #dfeeea;
  padding: 61px 0 170px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price {
    padding: 125px 0 160px;
  }
}

.pharmacy .section-price .price-panel {
  z-index: 1;
  background: #fff;
  padding: 49px 5.86667vw 46px;
  position: relative;
}

.pharmacy .section-price .price-panel:before, .pharmacy .section-price .price-panel:after {
  content: "";
  z-index: -1;
  border-style: solid;
  border-color: #333;
  display: block;
  position: absolute;
}

.pharmacy .section-price .price-panel:before {
  border-width: 2px 0;
  width: calc(100% - 20px);
  height: calc(100% - 2px);
  top: -1px;
  left: 10px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel:before {
    border-width: 4px 0;
    width: calc(100% - 40px);
    height: calc(100% - 4px);
    top: -2px;
    left: 20px;
  }
}

.pharmacy .section-price .price-panel:after {
  border-width: 0 2px;
  width: calc(100% - 2px);
  height: calc(100% - 20px);
  top: 10px;
  left: -1px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel:after {
    border-width: 0 4px;
    width: calc(100% - 4px);
    height: calc(100% - 40px);
    top: 20px;
    left: -2px;
  }

  .pharmacy .section-price .price-panel {
    padding: 4.71429vw 3.71429vw 4.64286vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .price-panel {
    padding: 66px 52px 65px;
  }
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel .price-panel-lead {
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    display: flex;
  }
}

.pharmacy .section-price .price-panel .price-panel-lead-title {
  text-align: center;
  margin-bottom: 18px;
  padding-bottom: 34px;
  font-size: 7.46667vw;
  font-weight: bold;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel .price-panel-lead-title {
    text-align: left;
    margin: 0 40px 0 0;
    padding: 0 11px 0 0;
  }
}

.pharmacy .section-price .price-panel .price-panel-lead-title:after {
  content: "";
  background: url("icon_headline_tail_green.6ae02568.svg") center / contain no-repeat;
  width: 126px;
  height: 12px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel .price-panel-lead-title:after {
    inset: 50% 0 auto auto;
    transform: translateY(-50%)translateX(45%)rotate(-90deg);
  }
}

@media screen and (width >= 375px) {
  .pharmacy .section-price .price-panel .price-panel-lead-title {
    font-size: 28px;
  }
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel .price-panel-lead-title {
    width: 11.8571vw;
    margin-right: 2.71429vw;
    font-size: 2.28571vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .price-panel .price-panel-lead-title {
    width: 166px;
    margin-right: 38px;
    font-size: 32px;
  }
}

.pharmacy .section-price .price-panel .price-panel-lead-text {
  margin-bottom: 35px;
  padding: 0 2.13333vw;
  font-size: 18px;
  line-height: 1.89;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .price-panel .price-panel-lead-text {
    flex: 1 1 0;
    max-width: 470px;
    margin-bottom: 0;
    padding: 0;
  }
}

.pharmacy .section-price .icon-deco-capsule-left-price {
  top: 65px;
  left: -38px;
  transform: rotate(110deg);
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .icon-deco-capsule-left-price {
    width: 6.5vw;
    top: 2.14286vw;
    left: -3.35714vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .icon-deco-capsule-left-price {
    width: 91px;
    top: 30px;
    left: -47px;
  }
}

.pharmacy .section-price .icon-deco-tablet-left-price {
  bottom: 161px;
  left: -30px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .icon-deco-tablet-left-price {
    bottom: 3.78571vw;
    left: -2.14286vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .icon-deco-tablet-left-price {
    bottom: 53px;
    left: -30px;
  }
}

.pharmacy .section-price .icon-deco-tablet-right-price {
  top: -19px;
  right: 90px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .icon-deco-tablet-right-price {
    top: -2.14286vw;
    right: 8.42857vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .icon-deco-tablet-right-price {
    top: -30px;
    right: 118px;
  }
}

.pharmacy .section-price .icon-deco-tablet-bottom-price {
  display: none;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .icon-deco-tablet-bottom-price {
    display: block;
    bottom: -2.57143vw;
    left: 6vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .icon-deco-tablet-bottom-price {
    bottom: -36px;
    left: 84px;
  }
}

.pharmacy .section-price .icon-deco-capsule-right-price {
  width: 139px;
  bottom: -38px;
  right: -49px;
}

@media screen and (width >= 769px) {
  .pharmacy .section-price .icon-deco-capsule-right-price {
    width: 15.4286vw;
    bottom: -3.5vw;
    right: -2.85714vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .section-price .icon-deco-capsule-right-price {
    width: 216px;
    bottom: -49px;
    right: -40px;
  }
}

.pharmacy .service-icon {
  z-index: 2;
  position: relative;
}

.pharmacy .service-icon .icon-deco-yakubato-service {
  width: 205px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.pharmacy .service-icon .icon-deco-tablet-service {
  display: none;
}

@media screen and (width >= 769px) {
  .pharmacy .service-icon .icon-deco-tablet-service {
    display: block;
    bottom: -2vw;
    left: 3.28571vw;
  }
}

@media screen and (width >= 1400px) {
  .pharmacy .service-icon .icon-deco-tablet-service {
    display: block;
    bottom: -28px;
    left: 46px;
  }
}

.pharmacy .icon-no-01-green {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-01-green {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-01-green:before {
  content: "";
  background: url("icon_01_green.e9629871.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-01-green:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-02-green {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-02-green {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-02-green:before {
  content: "";
  background: url("icon_02_green.0ecc72fb.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-02-green:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-03-green {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-03-green {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-03-green:before {
  content: "";
  background: url("icon_03_green.c891ee4e.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-03-green:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-04-green {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-04-green {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-04-green:before {
  content: "";
  background: url("icon_04_green.8b00913f.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-04-green:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-01-yellow {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-01-yellow {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-01-yellow:before {
  content: "";
  background: url("icon_01_yellow.db2cc5fb.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-01-yellow:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-02-yellow {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-02-yellow {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-02-yellow:before {
  content: "";
  background: url("icon_02_yellow.7e2a4d62.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-02-yellow:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-03-yellow {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-03-yellow {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-03-yellow:before {
  content: "";
  background: url("icon_03_yellow.22f46037.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-03-yellow:before {
    width: 73px;
    height: 49px;
  }
}

.pharmacy .icon-no-04-yellow {
  padding-left: 74px;
  position: relative;
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-04-yellow {
    padding-left: 96px;
  }
}

.pharmacy .icon-no-04-yellow:before {
  content: "";
  background: url("icon_04_yellow.bd882e4e.svg") center / contain no-repeat;
  width: 53px;
  height: 37px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width >= 769px) {
  .pharmacy .icon-no-04-yellow:before {
    width: 73px;
    height: 49px;
  }
}
/*# sourceMappingURL=index.e857389f.css.map */
