// 共通
.container {
  max-width: 1200px;
  padding: 0 vwsp(20);
  margin: 0 auto;
}

.container-pc {
  @include mq(md) {
    max-width: 1200px;
    padding: 0 vwsp(20);
    margin: 0 auto;
  }
}

.container-md {
  max-width: 1200px;
  padding: 0 vwsp(20);
  margin: 0 auto;
  @include mq(md) {
    padding: 0;
  }
}

.visible-md {
  display: none;
  @include mq(md) {
    display: block;
  }
}

.visible-xs {
  display: none;
  @include mq(xs) {
    display: block;
  }
}

.hidden-md {
  @include mq(md) {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.offset-header {
  padding-top: 60px;
  @include mq(md) {
    padding-top: 80px;
  }
}

.fc-primary {
  color: $color-primary-2;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text {
  font-size: 16px;
  line-height: 31px;
  @include mq(md) {
    font-size: 18px;
    line-height: 60px;
  }
}

.text-marker-orange {
  background: linear-gradient(
    transparent 0%,
    transparent 55%,
    #f7c9b8 55%,
    #f7c9b8 100%
  );
  padding-bottom: 2px;
}

.link-green {
  color: $color-primary-2;
  @include hover-opacity(0.7);
}

.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.anchor-link {
  display: flex;
  align-items: center;
  color: $color-text;
  font-weight: bold;

  &:hover {
    color: $color-primary-2;
  }

  &::before {
    content: '';
    display: block;
    width: 17px;
    height: 11px;
    margin-right: 10px;
    background-image: url('../img/arrow_bottom.svg');
  }
}

.table {
  margin: 25px 0 20px;

  th,
  td {
    font-size: 14px;
    padding: 15px 10px;
    border: 1px solid $color-text;
    line-height: 1.5;
    word-break: break-all;
  }

  thead {
    background-color: $color-primary-3;

    th {
      border-bottom: none;
      color: $white;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }

      &:nth-child(odd) {
        background-color: $color-secondary;
      }

      &:nth-child(even) {
        background-color: $white;
      }
    }
  }
}

.panel-white {
  background: $white;
}

[class^='icon-deco-'] {
  position: absolute;
  z-index: 2;
}

[class^='icon-deco-tablet-'] {
  width: 37px;
  @include mq(md) {
    width: vwpc(57);
  }
  @include mq(xxl) {
    width: 57px;
  }
}

[class^='icon-deco-capsule-'] {
  width: 60px;
  @include mq(md) {
    width: vwpc(90);
  }
  @include mq(xxl) {
    width: 90px;
  }
}
