// 医療機関さま向けのサービス紹介ページ
#medical-institution {
  line-height: 1.5;

  .container {
    padding: 0 vwsp(24);
  }

  // CTAボタン
  .btn_area {
    margin-top: -38px;
    @include mq(max-xl) {
      margin-top: -134px;
    }
    @include mq(max-md) {
      margin-top: -30px;
    }
    @include mq(max-sm) {
      margin-top: -108px;
    }

    .btn-list {
      display: flex;
      gap: 15px 25px;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;

      li {
        @include mq(max-sm) {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &.btn-bg {
        padding: 46px 10px 35px;
        background: $color-primary-7;
        border-radius: 60px;
        @include mq(max-sm) {
          padding: 40px 10px;
          border-radius: 20px;
        }
      }
    }
  }

  // 例
  .panel-example {
    @include service-panel(border);
    position: relative;

    .panel-example-title {
      width: 100%;
      @include font-size(18);
      letter-spacing: 1.08px;
      font-weight: 700;
      color: $white;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -11px;
      @include mq(max-md) {
        @include font-size(14);
        letter-spacing: 0.84px;
        top: -8px;
      }

      span {
        background: $color-primary-2;
        border-radius: 999px;
        padding: 8px 24px;
        @include mq(max-md) {
          padding: 8px vwsp(20);
        }
      }
    }
  }

  // kv
  .kv_area {
    @include service-section(btn-above);
    position: relative;
    padding: 0 0 102px;
    @include mq(max-sm) {
      padding-bottom: 157px;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1320px;
      height: 588px;
      background: url('../img/service/medical_institution/kv_bg.png') no-repeat
        center/contain;
      position: absolute;
      top: 24px;
      left: calc(50% + 164px);
      transform: translateX(-50%);
      z-index: -1;
      @include mq(max-xl) {
        width: 99.414vw;
        height: 43.945vw;
      }
      @include mq(max-md) {
        display: none;
      }
    }

    .kv-contents {
      position: relative;
      padding: 85px 0 91px;
      @include mq(max-xl) {
        padding-top: 6.714vw;
      }
      @include mq(max-md) {
        padding: 0;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        transform: translateX(-50%);
        @include mq(max-md) {
          display: none;
        }
      }

      &::before {
        width: vwpc(182);
        height: vwpc(119);
        background: url('../img/service/medical_institution/kv_deco_kapsel.png')
          no-repeat center/contain;
        top: 5px;
        left: 50%;
        @include mq(xxl) {
          width: 182px;
          height: 119px;
        }
      }

      &::after {
        width: vwpc(130);
        height: vwpc(106);
        background: url('../img/service/medical_institution/kv_deco_tablet.png')
          no-repeat center/contain;
        bottom: 22px;
        left: calc(50% + 405px);
        @include mq(xxl) {
          width: 130px;
          height: 106px;
        }
      }

      .kv-title {
        max-width: 480px;
        @include mq(max-xl) {
          max-width: 39.844vw;
        }
        @include mq(max-md) {
          width: 100%;
          max-width: none;
          position: relative;
          z-index: 1;
        }

        .kv-title-logo {
          width: 255px;
          margin: 0 auto 16px;
          @include mq(max-xl) {
            width: 37.305vw;
          }
          @include mq(max-md) {
            width: 100%;
            max-width: 260px;
            margin-bottom: 16px;
          }
          @include mq(max-sm) {
            max-width: 198px;
          }
        }

        .kv-title-ribon {
          background: $color-primary-2;
          position: relative;
          text-align: center;
          margin: 0 auto 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 306px;
          height: 84px;
          z-index: 1;
          @include mq(max-xl) {
            margin-bottom: 3.125vw;
            margin-bottom: 6px;
          }
          @include mq(max-md) {
            width: 250px;
            height: 64px;
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            border-color: $color-primary-2 transparent;
            border-style: solid;
          }

          &::before {
            left: -20px;
            border-width: 42px 0 42px 20px;
            @include mq(max-md) {
              left: -15px;
              border-width: 32px 0 32px 15px;
            }
          }

          &::after {
            right: -20px;
            border-width: 42px 20px 42px 0;
            @include mq(max-md) {
              right: -15px;
              border-width: 32px 15px 32px 0;
            }
          }

          .kv-title-ribon-text {
            @include font-size(24);
            color: $white;
            font-weight: 700;
            line-height: 1.4;
            letter-spacing: 1.44px;
            width: 100%;
            @include mq(max-md) {
              @include font-size(18);
              letter-spacing: 1.08px;
            }

            &::before,
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              height: 1px;
              width: 316px;
              background: $white;
              z-index: 1;
              @include mq(max-md) {
                width: 256px;
              }
            }

            &::before {
              top: 8px;
              @include mq(max-md) {
                top: 6px;
              }
            }

            &::after {
              bottom: 8px;
              @include mq(max-md) {
                bottom: 6px;
              }
            }

            .fs-small {
              @include font-size(16);
              line-height: 1.8;
              letter-spacing: 0.96px;
              @include mq(max-md) {
                @include font-size(12);
                line-height: 1.4;
                letter-spacing: 0.72px;
              }
            }

            sup {
              font-size: 70%;
              top: em(4);
            }
          }
        }

        .kv-title-text {
          @include font-size(48);
          line-height: 1.8;
          letter-spacing: 2.88px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 32px;

          @include mq(max-xl) {
            font-size: 3.906vw;
            letter-spacing: 0.281vw;
            margin-bottom: 3.125vw;
          }

          @include mq(max-md) {
            @include font-size(28);
            letter-spacing: 1.68px;
            margin-bottom: 16px;
          }
        }
      }

      .kv-notes {
        @include font-size(12);
        letter-spacing: 0.72px;
        @include mq(max-md) {
          @include font-size(10);
          line-height: 1.8;
          text-align: center;
        }
      }

      .kv-img {
        width: 67%;
        max-width: 800px;
        position: absolute;
        left: calc(50% + 305px);
        top: calc(50% - 20px);
        transform: translate(-50%, -50%);
        @include mq(max-xl) {
          max-width: 58.594vw;
          left: auto;
          right: -85px;
          transform: translateY(-50%);
        }
        @include mq(max-md) {
          width: vwsp(383);
          max-width: none;
          position: relative;
          top: auto;
          right: 10px;
          transform: none;
          margin-top: -19px;
          z-index: -1;
        }
      }
    }

    .kv-point {
      @include mq(max-md) {
        margin-top: 16px;
        position: relative;
      }

      .kv-point-list {
        display: flex;
        justify-content: center;
        gap: 20px 2.143vw;
        counter-reset: cnt;
        @include mq(max-md) {
          flex-direction: column;
          gap: 16px 0;
          max-width: 390px;
          margin: 0 auto;
        }
        @include mq(max-sm) {
          max-width: 327px;
        }

        .kv-point-item {
          width: 280px;
          height: 121px;
          padding: 16px 10px;
          box-sizing: border-box;
          background: $white;
          border-radius: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          @include mq(max-md) {
            width: 100%;
            height: auto;
            padding: 12px 16px;
            justify-content: flex-start;
          }

          &::before {
            counter-increment: cnt;
            content: counter(cnt);
            @include font-size(16);
            font-weight: 700;
            color: $white;
            background: $color-primary-2;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -16px;
            left: 50%;
            transform: translateX(-50%);
            @include mq(max-md) {
              @include font-size(12);
              width: 24px;
              height: 24px;
              position: static;
              transform: none;
              margin-right: 8px;
            }
          }

          .point-text {
            @include font-size(22);
            letter-spacing: 1.32px;
            font-weight: bold;
            text-align: center;
            @include mq(max-lg) {
              @include font-size(20);
            }
            @include mq(max-md) {
              flex: 1 1 0;
              text-align: left;
            }
            @include mq(max-sm) {
              @include font-size(16);
            }
          }
        }
      }
    }
  }

  // Web予約運用のよくあるお悩み
  .about_area {
    @include service-section(btn-below);
    padding: 0;
    position: relative;
    @include mq(max-md) {
      padding: 0;
    }

    .about-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    // 吹き出し
    .about-balloon {
      @include service-balloon(bottom);
      padding: 22px 14px 24px;
      box-sizing: border-box;
      margin: 0 auto;
      @include mq(max-md) {
        padding: 16px 20px;
      }

      &::before,
      &::after {
        right: 22px;
        @include mq(max-md) {
          right: auto;
          left: 50%;
        }
      }

      &::after {
        right: 30px;
        @include mq(max-md) {
          right: auto;
          left: 50%;
        }
      }

      .about-balloon-text {
        @include font-size(20);
        letter-spacing: 1.2px;
        font-weight: bold;
        text-align: center;
        @include mq(max-md) {
          @include font-size(16);
          letter-spacing: 0.96px;
        }
      }

      .about-balloon-img {
        width: 208px;
        position: absolute;
        right: -128px;
        bottom: -112px;
        z-index: 2;
        @include mq(max-md) {
          width: 132px;
          bottom: -89px;
          left: calc(50% + 136px);
          transform: translateX(-50%);
        }
      }

      .about-balloon-deco {
        width: 93px;
        position: absolute;
        right: -140px;
        bottom: 15px;
        @include mq(max-md) {
          display: none;
        }
      }
    }

    // お悩み
    .about-problem {
      padding: 152px 0 120px;
      background: #eaeaea;
      @include mq(max-md) {
        padding: 128px 0 64px;
      }

      .about-problem-list {
        max-width: 710px;
        margin: 0 auto 48px;
        display: flex;
        flex-direction: column;
        gap: 54px 0;
        @include mq(max-md) {
          max-width: 610px;
          margin-bottom: 36px;
          gap: 36px 0;
        }

        .problem-item {
          display: flex;

          .problem-item-box {
            padding: 20px 29px;
            background: $white;
            border: 3px solid $color-text;
            border-radius: 12px;
            box-sizing: border-box;
            position: relative;
            @include mq(max-sm) {
              padding: 13px 16px;
            }
          }

          .problem-item-img {
            width: 128px;
            position: absolute;
            bottom: -62px;
            @include mq(max-md) {
              width: 92px;
              bottom: -45px;
            }
          }

          .problem-item-text {
            @include font-size(20);
            font-weight: 700;
            letter-spacing: 1.2px;
            @include mq(max-md) {
              @include font-size(16);
              letter-spacing: 0.96px;
            }
          }

          &:last-child {
            .problem-item-img {
              bottom: -65px;
              @include mq(max-md) {
                bottom: -45px;
              }
            }
          }

          &:nth-child(odd) {
            justify-content: flex-end;

            .problem-item-box {
              width: 588px;
              @include mq(max-md) {
                width: vwsp(263);
              }
            }

            .problem-item-img {
              left: -102px;
              @include mq(max-md) {
                left: -63px;
              }
            }
          }

          &:nth-child(even) {
            .problem-item-box {
              width: 592px;
              @include mq(max-md) {
                width: vwsp(263);
              }
            }

            .problem-item-img {
              right: -102px;
              @include mq(max-md) {
                right: -63px;
              }
            }
          }
        }
      }

      .about-problem-summary {
        max-width: 744px;
        background: $color-primary-2;
        margin: 0 auto;
        border-radius: 12px;
        padding: 20px 10px;
        position: relative;
        z-index: 2;
        @include mq(max-md) {
          padding: 20px 18px;
        }

        .about-problem-summary-text {
          max-width: 499px;
          @include font-size(32);
          letter-spacing: 1.28px;
          font-weight: 700;
          color: $white;
          margin: 0 auto;
          position: relative;
          @include mq(max-md) {
            max-width: 335px;
            @include font-size(18);
          }

          &::before,
          &::after {
            content: '';
            width: 32px;
            height: 28px;
            background: url('../img/icon_quotation.svg') no-repeat center/cover;
            position: absolute;
            @include mq(max-md) {
              width: 24px;
              height: 21px;
            }
          }

          &::before {
            top: -30px;
            left: -60px;
            @include mq(max-md) {
              left: 0;
            }
          }

          &::after {
            bottom: -30px;
            right: -60px;
            transform: rotate(-180deg);
            @include mq(max-md) {
              right: 0;
            }
          }
        }
      }
    }

    // Web予約枠の運用負荷が高い要因
    .about-cause {
      padding: 80px 0 87px;
      position: relative;
      @include mq(max-md) {
        padding: 64px 0 72px;
      }

      .about-cause-title {
        @include font-size(40);
        font-weight: 700;
        letter-spacing: 1.6px;
        text-align: center;
        @include mq(max-md) {
          @include font-size(24);
          letter-spacing: 0.96px;
        }
      }

      .about-cause-load {
        padding: 60px 0 70px;
        position: relative;
        margin-bottom: 70px;
        @include mq(max-md) {
          padding: 32px 0 52px;
          margin-bottom: 32px;
        }

        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 337px;
          background: url('../img/service/medical_institution/bg_plaid.png')
            top/25px auto;
          position: absolute;
          bottom: 0;
          z-index: -1;
          @include mq(max-md) {
            background-size: 16px auto;
            height: 112%;
          }
        }

        &::after {
          content: '';
          border-style: solid;
          border-width: 32px 36px 0;
          border-color: $color-primary-2 transparent transparent transparent;
          position: absolute;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
          @include mq(max-md) {
            border-width: 20px 22.5px 0;
            bottom: 0;
          }
        }

        .about-cause-load-list {
          @include service-list-cross;
          max-width: 580px;
          margin: 0 auto;
          @include mq(max-md) {
            width: vwsp(241);
            max-width: 437px;
          }
          @include mq(max-xs) {
            width: 100%;
            max-width: 241px;
          }
        }
      }

      .about-cause-result {
        .about-cause-result-tilte {
          @include font-size(28);
          letter-spacing: 1.12px;
          font-weight: 700;
          color: $color-primary-2;
          text-align: center;
          margin-bottom: 32px;
          @include mq(max-md) {
            @include font-size(22);
            letter-spacing: 1.32px;
          }
        }
      }

      .about-balloon {
        max-width: 440px;
      }
    }

    // だからこそ
    .about-rundown {
      padding: 112px 0 87px;
      background: $color-primary-2;
      position: relative;
      @include mq(max-md) {
        padding: 80px 0 72px;
      }

      &::before,
      &::after {
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        content: '';
        border-style: solid;
        border-width: 64px 92px 0;
        border-color: $color-secondary transparent transparent transparent;
        top: 0;
        @include mq(max-md) {
          border-width: 48px 68px 0;
        }
      }

      &::after {
        content: 'だからこそ';
        display: inline-block;
        @include font-size(19);
        line-height: 1;
        letter-spacing: em(1.6);
        font-weight: bold;
        top: 0;
        @include mq(max-md) {
          @include font-size(16);
        }
      }

      .about-rundown-title {
        @include font-size(28);
        letter-spacing: 1.12px;
        font-weight: 700;
        color: $white;
        text-align: center;
        margin-bottom: 20px;
        @include mq(max-md) {
          @include font-size(22);
          letter-spacing: 1.32px;
        }
      }

      .about-rundown-point {
        @include font-size(32);
        letter-spacing: 1.28px;
        font-weight: 700;
        text-align: center;
        background: $white;
        max-width: 920px;
        border-radius: 999px;
        margin: 0 auto 64px;
        padding: 16px 30px;
        @include mq(max-md) {
          @include font-size(22);
          margin-bottom: 48px;
        }
      }

      .about-balloon {
        max-width: 598px;

        .about-balloon-text {
          line-height: 1.8;
        }
      }
    }
  }

  // 「やくばと」でのWeb予約の流れ
  .reserve_area {
    @include service-section;
    background: $color-primary-7;
    position: relative;
    @include mq(max-md) {
      padding: 64px 0;
    }

    .reserve-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    .reserve-list {
      max-width: 960px;
      border: 3px solid $color-text;
      border-radius: 8px;
      display: flex;
      overflow: hidden;
      margin: 0 auto 48px;
      @include mq(max-md) {
        flex-direction: column;
      }

      .reserve-item {
        width: calc(100% / 3);
        background: $white;
        @include mq(max-md) {
          width: 100%;
        }

        &:not(:last-child) {
          border-right: 3px solid $color-text;
        }

        .reserve-item-number {
          @include font-size(20);
          letter-spacing: 1.2px;
          text-align: center;
          color: $white;
          padding: 0 10px;
          background: $color-primary-2;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq(max-md) {
            @include font-size(18);
            height: 51px;
          }
        }

        .reserve-item-contentes {
          padding: 24px 20px 20px;
          text-align: center;
          @include mq(max-md) {
            padding: 16px vwsp(24) 20px;
          }

          .reserve-item-contentes-title {
            @include font-size(24);
            letter-spacing: 0.96px;
            font-weight: 700;
            margin-bottom: 12px;
            @include mq(max-md) {
              @include font-size(20);
            }
          }

          .reserve-item-contentes-detail {
            @include font-size(18);
            line-height: 1.8;
            letter-spacing: 1.08px;
            @include mq(max-md) {
              @include font-size(16);
            }

            sup {
              top: em(4);
            }
          }

          .reserve-item-contentes-notes {
            @include font-size(10);
            letter-spacing: 0.72px;
            text-align: left;
            width: fit-content;
            margin: 17px auto 0;
          }
        }
      }
    }

    .reserve-point {
      @include service-panel;
      border-radius: 12px;
      padding: 38px 64px;
      position: relative;
      @include mq(max-md) {
        padding: 48px vwsp(24) 60px;
      }

      .reserve-point-list {
        @include service-list-circle;
        width: 84%;
        max-width: 652px;
        @include mq(max-lg) {
          width: 95%;
        }
        @include mq(max-md) {
          width: 100%;
        }
      }

      .reserve-point-img {
        width: 197px;
        position: absolute;
        left: calc(50% + 350px);
        bottom: -14px;
        transform: translateX(-50%);
        @include mq(max-md) {
          width: 105px;
          left: auto;
          right: 15px;
          bottom: -34px;
          transform: none;
        }
      }
    }
  }

  // 確定の予約日時は病院が決定それで本当にメリットがあるの?
  .merit_area {
    @include service-section(btn-above);

    .merit-title {
      display: inline-block;
      @include font-size(40);
      letter-spacing: 1.6px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 48px;
      position: relative;
      left: calc(50% + 30px);
      transform: translateX(-50%);
      @include mq(max-xl) {
        @include font-size(35);
      }
      @include mq(max-lg) {
        @include font-size(32);
      }
      @include mq(max-md) {
        @include font-size(22);
        letter-spacing: 1.32px;
        text-align: left;
        max-width: 304px;
        margin-bottom: 33px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .merit-panel {
      @include service-panel(border, medium);
      padding-bottom: 120px;
      @include mq(max-md) {
        padding-bottom: 48px;
      }
      position: relative;

      &::before {
        content: '';
        width: vwpc(140);
        height: vwpc(201);
        background: url('../img/service/medical_institution/merit_img.png')
          no-repeat center/contain;
        position: absolute;
        top: vwpc(-175);
        left: vwpc(57);
        @include mq(xxl) {
          width: 140px;
          height: 201px;
          top: -175px;
          left: 57px;
        }
        @include mq(max-md) {
          width: 74px;
          height: 106px;
          left: calc(50% + 114px);
          top: -88px;
          transform: translateX(-50%);
        }
      }

      .merit-panel-section {
        &:not(:last-child) {
          margin-bottom: 64px;
          @include mq(max-md) {
            margin-bottom: 32px;
          }
        }
      }

      .merit-panel-title {
        @include font-size(28);
        line-height: 1.8;
        letter-spacing: 1.12px;
        font-weight: 700;
        margin-bottom: 32px;
        @include mq(max-md) {
          @include font-size(18);
          line-height: 1.6;
          letter-spacing: 0.54px;
          margin-bottom: 24px;
        }
      }

      .merit-panel-text {
        @include font-size(18);
        line-height: 1.8;
        letter-spacing: 1.08px;
        margin-bottom: 20px;
        @include mq(max-md) {
          margin-bottom: 16px;
        }
        @include mq(max-md) {
          @include font-size(14);
          letter-spacing: 0.84px;
        }
      }

      .merit-panel-list-dot {
        list-style-type: disc;
        margin-left: em(28);
        margin-bottom: 20px;
        @include mq(max-md) {
          margin-left: em(20);
          margin-bottom: 16px;
        }

        li {
          @include font-size(18);
          line-height: 1.8;
          letter-spacing: 1.08px;
          @include mq(max-md) {
            @include font-size(14);
            letter-spacing: 0.84px;
          }
        }
      }
    }

    .merit-graph {
      @include service-graph;
      padding: 37px 10px 22px;
      position: relative;
      margin-bottom: 48px;
      @include mq(max-md) {
        padding: 121px 11px 22px;
        margin-bottom: 24px;
      }

      .graph-figure {
        max-width: 718px;
        position: relative;
        left: calc(50% + 9px);
        transform: translateX(-50%);
        @include mq(max-md) {
          max-width: 400px;
          left: 50%;
        }
      }

      .graph-balloon {
        @include service-balloon(right);
        @include font-size(22);
        letter-spacing: 1.32px;
        font-weight: 700;
        text-align: center;
        display: inline-block;
        padding: 22px vwpc(39) 26px;
        position: absolute;
        top: 47px;
        left: calc(50% - 133px);
        transform: translateX(-50%);
        @include mq(xxl) {
          padding: 22px 39px 26px;
        }
        @include mq(max-md) {
          @include font-size(16);
          letter-spacing: 0.96px;
          width: 100%;
          max-width: 240px;
          padding: 12px 10px;
          box-sizing: border-box;
          top: 29px;
          left: 50%;
          transform: translateX(-50%);
        }

        &::before,
        &::after {
          @include mq(max-md) {
            bottom: 0;
            right: auto;
            left: 50%;
            translate: -50% 100%;
          }
        }

        &::before {
          @include mq(max-md) {
            border-width: 12px 10px 0;
            border-color: $black transparent transparent;
          }
        }

        &::after {
          @include mq(max-md) {
            border-width: 7.3px 6.1px 0;
            border-color: $white transparent transparent;
          }
        }
      }
    }

    .merit-introduce {
      background: $color-primary-7;
      border-radius: 12px;
      padding: 26px 20px 28px;
      position: relative;
      @include mq(max-md) {
        padding: 32px 20px 58px;
      }

      .merit-introduce-contents {
        max-width: 477px;
        text-align: center;
        position: relative;
        left: calc(50% - 35px);
        transform: translateX(-50%);
        @include mq(max-md) {
          max-width: none;
          text-align: left;
          position: static;
          transform: none;
        }

        .contents-text {
          @include font-size(22);
          font-weight: 700;
          letter-spacing: 1.32px;
          @include mq(max-md) {
            @include font-size(18);
            letter-spacing: 1.08px;
          }

          &:first-of-type {
            margin-bottom: 12px;
          }

          sub {
            font-size: 72%;
          }
        }

        .contents-notes {
          @include font-size(12);
          letter-spacing: 0.72px;
          margin-top: 4px;
          text-align: left;
          @include mq(max-md) {
            @include font-size(10);
            letter-spacing: 0.6px;
          }
        }
      }

      .merit-introduce-img {
        position: absolute;
        width: vwpc(197);
        top: vwpc(-16);
        right: vwpc(-25);
        @include mq(xxl) {
          width: 197px;
          top: -16px;
          right: -25px;
        }
        @include mq(max-md) {
          width: 105px;
          top: auto;
          bottom: -27px;
          right: 5px;
        }
      }
    }
  }

  // おすすめポイント
  .point_area {
    @include service-section(btn-below);
    background: $color-primary-7;
    position: relative;

    .point-title {
      @include service-title-dot;
      margin-bottom: 80px;
      @include mq(max-md) {
        margin-bottom: 80px;
      }
    }

    .point-list {
      display: flex;
      flex-direction: column;
      gap: 80px 0;

      .point-item {
        @include service-panel($width: narrow);
        counter-increment: cnt;
        position: relative;
        @include mq(max-md) {
          padding: 56px vwsp(24) 48px;
        }

        .point-item-title {
          width: 100%;
          @include font-size(32);
          letter-spacing: 1.28px;
          font-weight: 700;
          display: flex;
          gap: 0 16px;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -36px;
          left: 50%;
          transform: translateX(-50%);
          @include mq(max-md) {
            @include font-size(22);
            letter-spacing: 1.32px;
            line-height: 1.4;
            align-items: flex-start;
            top: -26px;
            left: calc(50% - 8px);
          }

          &::before {
            content: '0' counter(cnt);
            font-family: Montserrat, sans-serif;
            @include font-size(72);
            line-height: 1;
            letter-spacing: 5.76px;
            color: $color-primary-2;
            @include mq(max-md) {
              @include font-size(48);
              letter-spacing: 3.84px;
            }
          }
        }

        .point-item-lead {
          max-width: 704px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0 24px;
          margin: 0 auto 51px;
          @include mq(max-md) {
            flex-direction: column;
            gap: 12px 0;
            margin-bottom: 32px;
          }

          .lead-text {
            flex: 1 1 0;
            @include font-size(20);
            line-height: 1.8;
            letter-spacing: 1.2px;
            @include mq(max-md) {
              @include font-size(14);
              letter-spacing: 0.84px;
            }
          }
        }

        .point-item-example-list {
          padding-top: 12px;

          .example-item {
            padding: 32px;
            @include mq(max-sm) {
              padding: 20px vwsp(17) 24px;
            }

            &:not(:last-child) {
              border-bottom: 3px solid $color-text;
            }

            .example-item-cross {
              @include service-list-cross(small);

              li {
                @include mq(max-md) {
                  padding: 27px 0 0;
                  text-align: center;
                }

                &::before {
                  @include mq(max-md) {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                }
              }
            }

            .example-item-number {
              @include font-size(18);
              letter-spacing: 1.08px;
              font-weight: 700;
              color: $color-primary-2;
              text-align: center;
              margin-bottom: 4px;
              @include mq(max-md) {
                @include font-size(16);
                letter-spacing: 0.96px;
                margin-bottom: 2px;
              }
            }

            .example-item-text {
              @include font-size(24);
              letter-spacing: 0.96px;
              font-weight: 700;
              text-align: center;
              @include mq(max-md) {
                @include font-size(18);
                letter-spacing: 1.08px;
                width: 96%;
                margin: 0 auto;
              }
            }
          }
        }

        .point-item-notes {
          line-height: 1.8;
          letter-spacing: 0.96px;
          margin-top: 12px;
          @include mq(max-md) {
            @include font-size(14);
            margin-top: 8px;
          }
        }

        .point-item-voice {
          margin-top: 64px;
          @include mq(max-md) {
            margin-top: 48px;
          }

          .voice-title {
            @include font-size(32);
            letter-spacing: 1.28px;
            font-weight: 700;
            color: $color-primary-2;
            text-align: center;
            margin-bottom: 48px;
            @include mq(max-md) {
              @include font-size(22);
              margin-bottom: 26px;
            }
          }

          .voice-list {
            max-width: 880px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 48px 0;
            @include mq(max-md) {
              gap: 24px 0;
            }

            .voice-item {
              max-width: 768px;
              display: flex;
              align-items: flex-end;
              gap: 0 20px;
              @include mq(max-md) {
                position: relative;
                padding-bottom: 35px;
              }

              .voice-item-img {
                width: 136px;
                @include mq(max-md) {
                  width: 90px;
                  position: absolute;
                  z-index: 1;
                }
              }

              .voice-item-text {
                flex: 1 1 0;

                .voice-item-text-balloon {
                  @include font-size(18);
                  line-height: 1.8;
                  letter-spacing: 1.08px;
                  font-weight: bold;
                  padding: 18px 32px;
                  background: $white;
                  border-radius: 17px;
                  border: 3px solid $color-text;
                  position: relative;
                  @include mq(max-md) {
                    @include font-size(16);
                    letter-spacing: 0.96px;
                  }
                  @include mq(max-sm) {
                    padding: 16px 17px;
                  }
                }

                .voice-item-text-figcaption {
                  letter-spacing: 0.96px;
                  margin-top: 16px;
                  @include mq(max-md) {
                    @include font-size(14);
                    letter-spacing: 0.84px;
                    width: vwsp(160);
                    min-height: 42px;
                    margin-top: 18px;
                    position: relative;
                    transform: translateX(-50%);
                    display: flex;
                  }
                }
              }

              &:nth-child(odd) {
                .voice-item-img {
                  @include mq(max-md) {
                    left: 0;
                    bottom: 0;
                  }
                }

                .voice-item-text-balloon {
                  @include service-balloon(left);

                  &::before,
                  &::after {
                    top: 75%;
                    @include mq(max-md) {
                      display: none;
                    }
                  }
                }

                .voice-item-text-figcaption {
                  @include mq(max-md) {
                    left: calc(50% + 36px);
                  }
                }
              }

              &:nth-child(even) {
                flex-direction: row-reverse;
                margin: 0 0 0 auto;
                @include mq(max-md) {
                  flex-direction: column-reverse;
                }

                .voice-item-img {
                  @include mq(max-md) {
                    right: 0;
                    bottom: 0;
                  }
                }

                .voice-item-text {
                  .voice-item-text-balloon {
                    @include service-balloon(right);

                    &::before,
                    &::after {
                      top: 75%;
                      @include mq(max-md) {
                        display: none;
                      }
                    }
                  }

                  .voice-item-text-figcaption {
                    text-align: right;
                    @include mq(max-md) {
                      text-align: left;
                      left: calc(50% - 20px);
                      justify-content: flex-end;
                    }
                  }
                }
              }
            }
          }
        }

        .point-item-graph {
          @include service-graph;
          padding: 37px 10px 25px;
          position: relative;
          margin-top: 48px;
          @include mq(max-md) {
            padding: 154px 12px 22px;
            margin-top: 32px;
          }

          .graph-figure {
            width: 90%;
            max-width: 644px;
            position: relative;
            left: calc(50% + vwpc(30));
            transform: translateX(-50%);
            @include mq(xxl) {
              left: calc(50% + 30px);
            }
            @include mq(max-lg) {
              width: 96%;
              left: calc(50% + 10px);
            }
            @include mq(max-md) {
              width: 100%;
              max-width: 400px;
              left: 50%;
            }

            .graph-figcaption {
              margin-top: 11px;
              padding-left: vwpc(23);
              text-align: left;
              @include mq(xxl) {
                padding-left: 23px;
              }
              @include mq(max-md) {
                margin-top: 35px;
                padding-left: 0;
              }
            }

            .graph-type {
              display: flex;
              align-items: center;
              gap: 0 16px;
              position: absolute;
              bottom: 0;
              right: 50px;
              @include mq(max-md) {
                gap: 0 13px;
                bottom: 26px;
                right: 23px;
              }

              .graph-type-item {
                font-weight: 700;
                letter-spacing: 0.96px;
                display: flex;
                align-items: center;
                gap: 0 8px;
                @include mq(max-md) {
                  @include font-size(12);
                  line-height: 1.8;
                  letter-spacing: 0.6px;
                  gap: 0 6px;
                }
                @include mq(max-sm) {
                  @include font-size(10);
                }

                &::before {
                  display: inline-block;
                  content: '';
                  width: 16px;
                  height: 16px;
                  @include mq(max-md) {
                    width: 12px;
                    height: 12px;
                  }
                }

                &.green {
                  &::before {
                    background: $color-primary-2;
                  }
                }

                &.yellow {
                  &::before {
                    background: $color-tertiary-4;
                  }
                }
              }
            }
          }

          .graph-balloon {
            font-size: vwpc(20);
            letter-spacing: 1.32px;
            font-weight: 700;
            text-align: center;
            display: inline-block;
            padding: vwpc(18) vwpc(26) vwpc(15);
            border: 3px solid $color-text;
            border-radius: 12px;
            background: $white;
            position: absolute;
            top: vwpc(54);
            left: calc(50% - vwpc(238));
            transform: translateX(-50%);
            @include mq(xxl) {
              @include font-size(20);
              padding: 18px 26px 15px;
              top: 54px;
              left: calc(50% - 238px);
            }
            @include mq(max-xl) {
              left: calc(50% - 180px);
            }
            @include mq(max-md) {
              @include font-size(14);
              letter-spacing: 0.96px;
              width: 100%;
              max-width: 240px;
              padding: 12px 10px 8px;
              box-sizing: border-box;
              top: 29px;
              left: 50%;
              transform: translateX(-50%);
            }

            .text-number {
              display: block;
              @include font-size(40);
              line-height: 1.4;
              letter-spacing: 1.6px;
              color: $color-tertiary-4;
              @include mq(max-md) {
                @include font-size(28);
              }

              sup {
                font-size: 35%;
                color: $color-text;
                top: em(10);
                @include mq(max-md) {
                  font-size: 45%;
                }
              }
            }
          }
        }

        &:nth-child(1) {
          .point-item-lead {
            .lead-img {
              width: 43%;
              max-width: 300px;
              @include mq(max-md) {
                width: 100%;
                max-width: 188px;
              }
            }
          }
        }

        &:nth-child(2) {
          .point-item-lead {
            .lead-img {
              width: 39%;
              max-width: 270px;
              @include mq(max-md) {
                width: 100%;
                max-width: 180px;
              }
            }
          }
        }

        &:nth-child(3) {
          .point-item-lead {
            max-width: 728px;
            gap: 0 32px;

            .lead-img {
              width: 49%;
              max-width: 351px;
              @include mq(max-md) {
                width: 100%;
                max-width: 234px;
              }
            }
          }
        }
      }
    }
  }

  // 導入事例
  .cases_area {
    @include service-section(btn-above);
    background: $white;

    .cases-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    .cases-panel {
      @include service-panel(border, wide);

      .cases-panel-section {
        &:not(:last-child) {
          margin-bottom: 48px;
        }
      }

      .cases-panel-title {
        @include font-size(28);
        line-height: 1.8;
        letter-spacing: 1.12px;
        font-weight: 700;
        margin-bottom: 8px;
        text-align: center;
        @include mq(max-md) {
          @include font-size(20);
          letter-spacing: 1.2px;
        }
      }

      .cases-panel-lead {
        letter-spacing: 0.96px;
        color: #868686;
        text-align: center;
        @include mq(max-md) {
          @include font-size(12);
          letter-spacing: 0.72px;
        }
      }
    }

    .cases-list,
    .cases-others-list {
      .cases-item-institution {
        color: $color-primary-2;
      }

      .cases-item-institution,
      .cases-item-title {
        font-weight: 700;
        text-decoration: underline;
        transition: all 0.6s;

        &:hover {
          text-decoration: none;
          opacity: 0.6;
        }
      }
    }

    .cases-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 256px));
      justify-content: center;
      gap: 32px;
      @include mq(max-md) {
        grid-template-columns: repeat(auto-fit, minmax(0, 300px));
      }

      .cases-item {
        .cases-item-img {
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 12px;
        }

        .cases-item-institution,
        .cases-item-soon {
          @include font-size(20);
          letter-spacing: 1.2px;
          font-weight: 700;
        }

        .cases-item-soon {
          color: #868686;
        }

        .cases-item-title {
          color: $color-text;
          letter-spacing: 0.96px;
          margin-top: 8px;
        }

        .cases-item-notes {
          @include font-size(14);
          letter-spacing: 0.84px;
          color: #868686;
          margin-top: 8px;
        }
      }
    }

    .cases-others-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      gap: 24px;
      margin-top: 32px;
      @include mq(max-md) {
        grid-template-columns: repeat(1, 1fr);
      }

      .cases-others-item {
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        padding: 16px;

        .cases-item-institution {
          @include font-size(18);
          letter-spacing: 1.08px;
          @include mq(max-md) {
            @include font-size(16);
            letter-spacing: 0.96px;
          }
        }
      }
    }
  }

  // 導入・運用もしっかりサポート
  .support_area {
    @include service-section(btn-below);
    position: relative;

    .support-title {
      @include service-title-dot;
      margin-bottom: 80px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    .support-panel {
      @include service-panel($width: wide);
      padding-top: 48px;
      @include mq(max-md) {
        padding-bottom: 48px;
      }
    }

    .support-lead {
      display: flex;
      align-items: center;
      gap: 12px 16px;
      max-width: 792px;
      margin: 0 auto 32px;
      @include mq(max-md) {
        flex-direction: column;
        margin-bottom: 40px;
      }

      .support-lead-text {
        flex: 1 1 0;
        @include font-size(24);
        font-weight: 700;
        line-height: 1.8;
        letter-spacing: 0.96px;
        @include mq(max-md) {
          @include font-size(16);
          text-align: center;
        }
      }

      .support-lead-img {
        width: 47%;
        max-width: 376px;
        @include mq(max-md) {
          width: 100%;
          max-width: 248px;
        }
      }
    }

    .support-list {
      @include service-list-circle;
      padding: 64px vwpc(47) 48px;
      @include mq(xxl) {
        padding: 64px 47px 48px;
      }
      @include mq(max-md) {
        padding: 32px vwsp(20) 24px;
      }
    }
  }

  // よくあるご質問
  .faq_area {
    @include service-section;
    background: $white;

    .faq-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    .faq-panel {
      @include service-panel(border, wide);

      .faq-list {
        max-width: 870px;
        margin: 0 auto;

        .faq-question {
          @include font-size(24);
          letter-spacing: 0.96px;
          font-weight: bold;
          position: relative;
          padding-left: 50px;
          margin-bottom: 17px;
          @include mq(max-md) {
            @include font-size(20);
            letter-spacing: 1.2px;
            padding-left: 36px;
            margin-bottom: 8px;
          }

          &::before {
            content: 'Q';
            font-family: Montserrat, sans-serif;
            @include font-size(40);
            line-height: 1;
            font-weight: bold;
            color: $color-primary-2;
            position: absolute;
            left: 0;
            top: -4px;
            @include mq(max-md) {
              @include font-size(28);
              top: 0;
            }
          }
        }

        .faq-answer {
          @include font-size(18);
          letter-spacing: 1.08px;
          line-height: 1.8;
          margin-left: 50px;
          @include mq(max-md) {
            @include font-size(16);
            letter-spacing: 0.96px;
            margin-left: 36px;
          }

          &:not(:last-of-type) {
            padding-bottom: 32px;
            margin-bottom: 32px;
            background: url('../img/service/medical_institution/line_dot.png')
              repeat-x bottom/11px auto;
            @include mq(max-sm) {
              padding-bottom: 16px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  // 機能一覧
  .feature_area {
    @include service-section(btn-above);

    .feature-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    .feature-wrap {
      max-width: 960px;
      margin: 0 auto;
      display: flex;
      @include mq(max-md) {
        flex-direction: column;
        gap: 32px 0;
      }
    }

    .feature-table-reserve,
    .feature-table-cost {
      border-collapse: separate;
      background: $white;

      th,
      td {
        @include font-size(20);
        letter-spacing: 1.2px;
        font-weight: 700;
        vertical-align: middle;
        border-right: 2px solid $color-text;
        border-bottom: 2px solid $color-text;
        padding: 19px 24px;
        @include mq(max-md) {
          @include font-size(16);
          letter-spacing: 0.96px;
          padding: 15px 16px;
        }
      }

      th {
        text-align: center;
      }

      thead {
        th {
          color: $white;
          background: $color-primary-2;
          border-top: 2px solid $color-text;
        }
      }

      tbody {
        th {
          background: $color-primary-7;
        }
      }
    }

    .feature-table-reserve {
      width: 63%;
      border-left: 2px solid $color-text;
      border-radius: 8px 0 0 8px;
      @include mq(max-md) {
        width: 100%;
      }

      thead {
        .reserve-heading {
          &:first-child {
            border-radius: 8px 0 0;
          }

          &:last-child {
            @include mq(max-md) {
              border-radius: 0 8px 0 0;
            }
          }
        }
      }

      tbody {
        tr {
          &:last-child {
            .reserve-heading {
              border-radius: 0 0 0 8px;
            }

            .reserve-data {
              @include mq(max-md) {
                border-radius: 0 0 8px;
              }
            }
          }
        }
      }
    }

    .feature-table-cost {
      width: 37%;
      @include mq(max-md) {
        width: 100%;
        border-left: 2px solid $color-text;
        border-radius: 8px 0 0 8px;
      }

      thead {
        .cost-heading {
          &:first-child {
            border-radius: 0 8px 0 0;
            @include mq(max-md) {
              border-radius: 8px 8px 0 0;
            }
          }
        }
      }

      tbody {
        .cost-data {
          &:first-child {
            border-radius: 0 0 8px;
            @include mq(max-md) {
              border-radius: 0 0 8px 8px;
            }
          }
        }
      }
    }
  }

  // 活用開始までの流れ
  .flow_area {
    @include service-section(btn-below);
    position: relative;
    background: $color-primary-7;

    .flow-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 40px;
      }
    }

    .flow-list {
      max-width: 960px;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 48px 0;
      @include mq(max-sm) {
        max-width: 400px;
        gap: 32px 0;
      }

      .flow-item {
        background: $white;
        border: 3px solid $color-text;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        @include mq(max-sm) {
          border-radius: 8px;
          flex-direction: column;
        }

        .flow-item-step {
          font-family: Montserrat, sans-serif;
          width: 200px;
          box-sizing: border-box;
          @include font-size(24);
          letter-spacing: 1.44px;
          line-height: 1.25;
          font-weight: bold;
          color: $white;
          background: $color-primary-2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @include mq(max-md) {
            @include font-size(20);
            width: 160px;
          }
          @include mq(max-sm) {
            width: 100%;
            min-height: auto;
            height: 52px;
            flex-direction: row;
            @include font-size(18);
          }

          .step-number {
            @include font-size(40);
            @include mq(max-md) {
              @include font-size(32);
            }
            @include mq(max-sm) {
              @include font-size(18);
              margin: 0 0 0 5px;
            }
          }
        }

        .flow-item-contents {
          flex: 1 1 0;
          padding: 32px;
          @include mq(max-md) {
            padding: 30px;
          }
          @include mq(max-sm) {
            padding: 20px 24px;
          }

          .flow-item-title {
            @include font-size(28);
            letter-spacing: 1.12px;
            color: $color-primary-2;
            font-weight: bold;
            margin-bottom: 8px;
            @include mq(max-md) {
              @include font-size(20);
            }
            @include mq(max-sm) {
              @include font-size(18);
              letter-spacing: 1.08px;
              text-align: center;
            }
          }

          .flow-item-text {
            @include font-size(20);
            line-height: 1.8;
            letter-spacing: 1.2px;
            @include mq(max-md) {
              @include font-size(16);
              letter-spacing: 0.96px;
              line-height: 1.8;
            }
          }
        }
      }
    }
  }

  // 私たちについて
  .aboutus_area {
    @include service-section;
    background: $white;

    .aboutus-title {
      @include service-title-dot;
      margin-bottom: 64px;
      @include mq(max-md) {
        margin-bottom: 48px;
      }
    }

    .aboutus-lead {
      @include font-size(20);
      letter-spacing: 1.2px;
      line-height: 1.8;
      font-weight: 700;
      text-align: center;
      max-width: 790px;
      margin: 0 auto 48px;
      @include mq(max-md) {
        @include font-size(18);
      }
      @include mq(max-sm) {
        @include font-size(16);
        letter-spacing: 0.96px;
        font-weight: 500;
        margin-bottom: 16px;
      }
    }

    .aboutus-logo {
      max-width: 333px;
      margin: 0 auto 64px;
      @include mq(max-md) {
        max-width: 250px;
      }
      @include mq(max-sm) {
        max-width: 196px;
        margin-bottom: 48px;
      }
    }

    .aboutus-list {
      max-width: 960px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      padding-top: 4px;
      background: url('../img/service/medical_institution/line_dot.png')
        repeat-x top left/11px auto;
      @include mq(max-sm) {
        background-position: top center;
      }

      .aboutus-term,
      .aboutus-description {
        @include font-size(20);
        line-height: 1.8;
        letter-spacing: 1.2px;
        box-sizing: border-box;
        background: url('../img/service/medical_institution/line_dot.png')
          repeat-x bottom left/11px auto;
        @include mq(max-sm) {
          background-position: bottom center;
          line-height: 1.73;
        }
      }

      .aboutus-term {
        font-weight: bold;
        width: 256px;
        padding: 32px 24px;
        @include mq(max-md) {
          @include font-size(18);
        }
        @include mq(max-sm) {
          letter-spacing: 1.08px;
          width: 100%;
          background: none;
          padding: 16px 12px 0;
        }
      }

      .aboutus-description {
        letter-spacing: em(0.5);
        width: calc(100% - 256px);
        padding: 32px 24px;
        @include mq(max-md) {
          @include font-size(18);
        }
        @include mq(max-sm) {
          @include font-size(16);
          letter-spacing: 0.96px;
          width: 100%;
          padding: 0 12px 16px;
        }
      }
    }
  }
}

// デモエリア
#demo {
  background: $color-primary-7;

  .demo-contents {
    position: relative;
    padding: vwpc(75) 0;
    @include mq(xxl) {
      padding: 75px 0;
    }
    @include mq(max-md) {
      padding: 48px 0 220px;
    }

    .demo-contents-text {
      width: 400px;
      margin-left: vwpc(50);
      @include mq(xxl) {
        margin-left: 50px;
      }
      @include mq(max-xxl) {
        margin-left: 0;
      }
      @include mq(max-md) {
        order: 2;
        width: 100%;
        margin-top: 0;
        text-align: center;
      }

      .demo-title {
        @include font-size(30);
        color: $color-primary-2;
        font-weight: bold;
        line-height: 1.46;
        margin-bottom: 32px;
        text-align: center;
        @include mq(max-xl) {
          @include font-size(24);
        }
        @include mq(max-md) {
          @include font-size(20);
          margin-bottom: 24px;
        }
      }

      .demo-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 27px 0;
        @include mq(max-md) {
          gap: 20px 0;
        }

        .demo-btn-item {
          width: 400px;
          @include mq(max-md) {
            width: 100%;
            max-width: 320px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .demo-contents-img {
      position: absolute;
      width: vwpc(688);
      left: calc(50% + vwpc(280));
      top: calc(50% + 40px);
      transform: translate(-50%, -50%);
      @include mq(xxl) {
        width: 688px;
        left: calc(50% + 280px);
      }
      @include mq(max-xl) {
        left: calc(50% + 240px);
      }
      @include mq(max-lg) {
        width: 44.643vw;
      }
      @include mq(max-md) {
        width: calc(100% + 37px);
        max-width: 361px;
        left: 50%;
        top: auto;
        bottom: -41px;
        transform: translateX(-50%);
      }
    }
  }
}
