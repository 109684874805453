// 下層ページ共通
.subpage {
  &.main {
    padding-top: 50px;
    @include mq(md) {
      padding-top: 80px;
    }
  }

  .section {
    padding-top: 40px;
    @include mq(md) {
      padding-top: 25px;
    }
  }

  .title-large {
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
    @include mq(md) {
      font-size: 36px;
      line-height: 54px;
    }
  }

  .title-middle {
    font-size: 26px;
    line-height: 39px;
    font-weight: bold;
    margin-bottom: 25px;
    @include mq(md) {
      font-size: 30px;
      line-height: 45px;
    }
  }

  .title-small {
    font-size: 24px;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 25px;
    @include mq(md) {
      font-size: 26px;
    }
  }

  .title-xsmall {
    font-size: 22px;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 25px;
    @include mq(md) {
      font-size: 24px;
    }
  }

  .text-lead {
    font-size: 16px;
    line-height: 1.88;
    margin-bottom: 40px;
    @include mq(md) {
      font-size: 18px;
      line-height: 1.67;
      text-align: center;
      margin-bottom: 60px;
    }
  }

  .contents_area {
    position: relative;
    padding: 87px 0 100px;
    @include mq(md) {
      padding: 135px 0 170px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 56px;
      background: url('../img/subpage_line.png') repeat-x center/1400px auto,
        $color-secondary;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .panel-white {
    padding: 60px vwsp(20);
    @include mq(md) {
      padding: 60px;
    }
  }
}

// 導入医療機関ページ
.medical_institution {
  .contents_area {
    padding-bottom: 0;
    @include mq(md) {
      padding-bottom: 170px;
    }
  }
}

// 規約ページ
.terms {
  .text,
  ol,
  ul {
    font-size: 16px;
    line-height: 31px;
    @include mq(md) {
      font-size: 18px;
      line-height: 34px;
    }
  }

  ol {
    > li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &.list-number-round {
      li {
        &::before {
          top: 6px;
          @include mq(md) {
            top: 8px;
          }
        }
      }
    }

    &.list-number {
      > li {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    ol {
      margin-top: 10px;
    }
  }

  .terms-contents {
    max-width: 1000px;
    margin: 0 auto;
    word-break: break-all;
  }

  .terms-item {
    max-width: 1000px;
    margin-bottom: 70px;
    @include mq(md) {
      margin-bottom: 87px;
    }

    &.is-anchor {
      margin-top: -50px;
      padding-top: 50px;
      @include mq(md) {
        margin-top: -80px;
        padding-top: 80px;
      }
    }
  }

  .terms-anchors {
    margin-bottom: 30px;
  }

  .terms-table-wrapper {
    overflow-x: auto;

    .table {
      width: 1000px;
    }
  }

  .terms-cell-company {
    width: 180px;
  }

  .terms-cell-service {
    width: 180px;
  }

  .terms-cell-data {
    width: 180px;
  }

  .terms-cell-purpose {
    width: 190px;
  }

  .terms-cell-optout {
    width: auto;
  }
}

// プライバシーポリシーページ
.privacy-policy {
  dl.business-operator {
    display: flex;
    margin-top: 30px;
  }

  dl.consultation-desk {
    margin-top: 30px;
  }

  .period {
    margin-bottom: 20px;
  }
}

// 404ページ
.notfound {
  .notfound-contents {
    padding-top: 30px;
    @include mq(md) {
      padding-top: 20px;
    }
  }

  .notfound-img {
    width: 100%;
    max-width: 324px;
    margin: 0 auto 50px;
    @include mq(md) {
      max-width: 603px;
    }
  }

  .notfound-title {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
  }
}
