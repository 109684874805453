// リスト
.list-number {
  list-style-type: decimal;
  margin-top: em(10);
  margin-left: em(24);

  > li {
    padding-left: em(8);
  }
}

.list-number-parentheses {
  margin-top: em(10);
  margin-left: em(20.8);

  > li {
    counter-increment: cnt-parentheses;
    position: relative;
    padding-left: em(8);

    &::before {
      content: '(' counter(cnt-parentheses) ')';
      display: inline-block;
      margin-left: em(-48);
      width: em(48);
      text-align: right;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.list-number-round {
  margin-top: em(10);

  > li {
    counter-increment: cnt-round;
    position: relative;
    padding-left: em(24);

    &::before {
      content: counter(cnt-round);
      border: 1px solid;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: em(20.8);
      width: em(20.8);
      font-size: 85%;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.list-disc {
  margin-top: em(10);
  margin-left: em(24);
  list-style-type: disc;
}

// 「病院さま」「薬局さま」「クリニックさま」ボタン
.list-service {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 335px;
  margin: 0 auto;

  .service-item {
    width: 100%;

    .service-item-link {
      background: $color-primary-2;
      height: 75px;
      border-radius: 5px;
      box-shadow: 0 5px 0 0 $color-primary-4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      @include transition;

      &:hover {
        background: $color-primary-4;
      }
    }

    .service-item-icon {
      margin-bottom: 8px;
    }

    .service-item-text {
      font-size: 14px;
      font-weight: bold;
      color: $white;
    }

    &.item-hospital,
    &.item-clinic {
      max-width: 42.667vw;
      @include mq(xs) {
        max-width: 160px;
      }

      .service-item-link {
        flex-direction: column;
      }
    }

    &.item-hospital {
      margin-right: 4vw;
      @include mq(xs) {
        margin-right: 15px;
      }

      .service-item-icon {
        width: 37px;
      }
    }

    &.item-clinic {
      .service-item-icon {
        width: 22px;
      }
    }

    &.item-pharmacy {
      margin-top: 10px;

      .service-item-link {
        height: 50px;
      }

      .service-item-icon {
        width: 26px;
        margin: 0 10px 0 -36px;
      }
    }
  }
}
