// 保険薬局さま向けサービス紹介ページ
.pharmacy {
  position: relative;
  overflow: hidden;
  @include mq(md) {
    overflow: visible;
  }

  .section {
    padding: 0;
  }

  // KV
  .kv {
    position: relative;
    padding: vwsp(70) 0 vwsp(61);
    @include mq(md) {
      padding: vwpc(20) 0 vwpc(68);
      margin-bottom: 52px;
    }
    @include mq(xxl) {
      padding: 20px 0 68px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      @include mq(md) {
        z-index: 1;
      }
    }

    &::before {
      width: vwsp(99);
      max-width: 150px;
      height: vwsp(108);
      background: url('../img/service/pharmacy/kv_icon_capsule_sp.png')
        no-repeat center/contain;
      top: vwsp(-25);
      right: vwsp(75);
      @include mq(md) {
        width: vwpc(173);
        height: vwpc(156);
        background-image: url('../img/service/pharmacy/kv_icon_capsule.png');
        top: vwpc(-108);
        right: auto;
        left: calc(50% - vwpc(304));
      }
      @include mq(xxl) {
        width: 173px;
        height: 156px;
        top: -108px;
        left: calc(50% - 304px);
      }
    }

    &::after {
      display: none;
      @include mq(md) {
        display: block;
        background: url('../img/service/pharmacy/kv_icon_tablet.png') no-repeat
          center/contain;
        width: vwpc(133);
        height: vwpc(120);
        bottom: 0;
        left: calc(50% + vwpc(172));
      }
      @include mq(xxl) {
        width: 133px;
        height: 120px;
        left: calc(50% + 172px);
      }
    }

    .kv_inner {
      @include mq(md) {
        position: relative;
      }
    }

    .kv-img {
      width: vwsp(345);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @include mq(md) {
        width: vwpc(1263);
      }
      @include mq(xxl) {
        width: 1263px;
      }
    }

    .kv-title {
      font-size: 24px;
      line-height: 37px;
      letter-spacing: 0.96px;
      font-weight: bold;
      color: $color-primary-2;
      text-align: center;
      margin: vwsp(-8) auto 0;
      width: vwsp(304);
      @include mq(md) {
        font-size: vwpc(44);
        line-height: vwpc(82);
        text-align: left;
        margin: 0;
        position: absolute;
        right: vwpc(80);
        top: calc(50%);
        transform: translateY(-50%);
        width: vwpc(417);
      }
      @include mq(xxl) {
        font-size: 44px;
        line-height: 82px;
        width: 417px;
        right: 80px;
      }
    }
  }

  // 「やくばと」とは？
  .section-about {
    background: url('../img/service/pharmacy/bg_wavy_line_green.png'),
      linear-gradient(
        180deg,
        $color-secondary 0%,
        $color-secondary 50%,
        $color-primary-7 50%,
        $color-primary-7 100%
      );
    background-size: 1400px auto;
    background-position: top 50% center;
    background-repeat: repeat-x;

    .about-panel {
      @include panel-border(4px, 20px);
      position: relative;
      padding: 39px vwsp(22);
      @include mq(md) {
        padding: vwpc(70) vwpc(78) 60px;
      }
      @include mq(xxl) {
        padding: 70px 78px 60px;
      }

      .about-panel-lead {
        @include panel-lead;
        @include mq(md) {
          margin-bottom: 55px;
        }
      }

      .about-panel-lead-title {
        @include panel-lead-title;
        font-size: vwsp(32);
        @include mq(xs) {
          font-size: 32px;
        }
        @include mq(md) {
          font-size: vwpc(46);
          width: vwpc(453);
          margin-right: vwpc(40);
        }
        @include mq(xxl) {
          font-size: 46px;
          width: 453px;
          margin-right: 40px;
        }
      }

      .about-panel-lead-text {
        @include panel-lead-text;
      }
    }

    .about-img {
      max-width: 284px;
      margin: 0 auto;
      @include mq(md) {
        max-width: 947px;
      }
    }

    .icon-deco-medicine-left-about {
      width: 100px;
      top: -19px;
      left: -39px;
      @include mq(md) {
        width: vwpc(160);
        top: vwpc(-28);
        left: vwpc(-58);
      }
      @include mq(xxl) {
        width: 160px;
        top: -28px;
        left: -58px;
      }
    }

    .icon-deco-medicine-right-about {
      width: 74px;
      bottom: -18px;
      right: -35px;
      @include mq(md) {
        width: vwpc(165);
        bottom: vwpc(-34);
        right: vwpc(-38);
      }
      @include mq(xxl) {
        width: 165px;
        bottom: -34px;
        right: -38px;
      }
    }

    .icon-deco-tablet-about {
      top: 41px;
      right: -30px;
      @include mq(md) {
        display: none;
      }
    }
  }

  // 利用イメージ
  .section-image {
    padding-top: 60px;
    background: $color-primary-7;
    @include mq(md) {
      padding-top: 120px;
    }

    .image-title {
      @include service-title-large;
      text-align: center;
      margin-bottom: 43px;
      @include mq(md) {
        margin-bottom: 60px;
      }
    }

    .image-list {
      .image-item {
        position: relative;
        z-index: 1;

        .image-item-inner {
          width: calc(100% - vwsp(40));
          background: $white;
          padding: 44px vwsp(20) 40px;
          box-sizing: border-box;
          position: relative;
          z-index: 1;
          @include mq(md) {
            display: flex;
            align-items: center;
            padding: 47px vwpc(24) 40px vwpc(40);
            max-width: 1000px;
          }
          @include mq(xxl) {
            padding: 47px 24px 40px 40px;
          }
        }

        &:nth-child(odd) {
          .image-item-inner {
            border-radius: 0 40px 40px 0;
            @include mq(md) {
              border-radius: 40px;
            }

            &::after {
              left: calc(50% - 10px);
            }
          }
        }

        &:nth-child(even) {
          .image-item-inner {
            border-radius: 40px 0 0 40px;
            margin-left: auto;
            @include mq(md) {
              border-radius: 40px;
            }
          }
        }

        &:not(:last-child) {
          padding-bottom: 40px;
          @include mq(md) {
            padding-bottom: 60px;
          }

          &::after {
            content: '';
            width: 5px;
            height: 60px;
            background: url('../img/service/pharmacy/image_bg_arrow.svg')
              no-repeat center/contain;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
          }
        }

        &:nth-last-child(2) {
          &::after {
            width: 46px;
            background-image: url('../img/service/pharmacy/image_bg_arrow_last.svg');
          }
        }

        .image-item-text {
          @include mq(md) {
            flex: 1 1 0;
          }

          .image-item-text-title {
            font-size: 20px;
            line-height: 1.5;
            font-weight: bold;
            margin-bottom: 20px;
            padding-left: 67px;
            @include mq(md) {
              font-size: 30px;
              padding-left: 94px;
            }
          }

          .image-item-text-detail {
            font-size: 18px;
            line-height: 1.89;
          }

          .image-item-text-note {
            @include text-note;
            margin-top: 8px;
          }
        }

        .image-item-img {
          max-width: 400px;
          margin: 20px auto 0;
          @include mq(md) {
            max-width: 266px;
            width: 29%;
            margin-top: 0;
          }
        }

        // アイコン
        .icon-deco-capsule-image-01 {
          top: -37px;
          left: 15px;
          transform: rotate(265deg);
          @include mq(md) {
            top: vwpc(-55);
            left: vwpc(40);
            transform: rotate(285deg);
          }
          @include mq(xxl) {
            top: -55px;
            left: 40px;
          }
        }

        .icon-deco-tablet-image-01 {
          bottom: -17px;
          right: 67px;
          transform: rotate(10deg);
          @include mq(md) {
            bottom: vwpc(-28);
            right: vwpc(240);
            transform: none;
          }
          @include mq(xxl) {
            bottom: -28px;
            right: 240px;
          }
        }

        .icon-deco-tablet-left-image-02 {
          display: none;
          @include mq(md) {
            display: block;
            top: vwpc(96);
            left: vwpc(-200);
            transform: rotate(285deg);
          }
          @include mq(xxl) {
            top: 96px;
            left: -200px;
          }
        }

        .icon-deco-tablet-right-image-02 {
          top: -27px;
          right: 63px;
          transform: rotate(90deg);
          @include mq(md) {
            top: vwpc(-40);
            right: vwpc(285);
          }
          @include mq(xxl) {
            top: -40px;
            right: 285px;
          }
        }

        .icon-deco-capsule-image-03 {
          top: -44px;
          right: 30px;
          transform: rotate(270deg);
          @include mq(md) {
            top: vwpc(-63);
            right: vwpc(210);
            transform: rotate(265deg);
          }
          @include mq(xxl) {
            top: -63px;
            right: 210px;
          }
        }

        .icon-deco-tablet-left-image-03 {
          bottom: -18px;
          left: 20px;
          transform: rotate(10deg);
          @include mq(md) {
            bottom: vwpc(-30);
            left: vwpc(236);
            transform: none;
          }
          @include mq(xxl) {
            bottom: -30px;
            left: 236px;
          }
        }

        .icon-deco-tablet-right-image-03 {
          display: none;
          @include mq(md) {
            display: block;
            right: vwpc(-200);
            transform: rotate(315deg);
          }
          @include mq(xxl) {
            right: -200px;
          }
        }

        .icon-deco-capsule-image-04 {
          display: none;
          @include mq(md) {
            display: block;
            top: 60px;
            left: vwpc(-140);
            transform: rotate(190deg);
          }
          @include mq(xxl) {
            right: -140px;
          }
        }

        .icon-deco-tablet-top-image-04 {
          top: -10px;
          right: 20px;
          transform: rotate(90deg);
          @include mq(md) {
            display: none;
          }
        }

        .icon-deco-tablet-bottom-image-04 {
          bottom: -25px;
          left: 60px;
          transform: rotate(90deg);
          @include mq(md) {
            bottom: vwpc(-30);
            left: auto;
            right: vwpc(270);
          }
          @include mq(xxl) {
            bottom: -30px;
            right: 270px;
          }
        }
      }
    }
  }

  // 提供価値
  .section-value {
    padding-top: 50px;
    background: $color-primary-7;
    @include mq(md) {
      padding-top: 187px;
    }

    .value-wrap {
      position: relative;
    }

    .value-text {
      background: $white;
      padding: 60px vwsp(25) 50px;
      border-radius: 0 0 60px 60px;
      position: relative;
      z-index: 0;
      @include mq(md) {
        width: 62%;
        max-width: 740px;
        padding: vwpc(173) vwpc(78) vwpc(163) vwpc(222);
        border-radius: 60px;
        margin: 0 0 0 auto;
        box-sizing: border-box;
      }
      @include mq(xxl) {
        padding: 173px 78px 163px 222px;
      }
    }

    .value-title {
      @include service-title-large;
      margin-bottom: 25px;
    }

    .value-detail {
      @include service-text-detail;
    }

    .value-img {
      border-radius: 60px 60px 0 0;
      overflow: hidden;
      position: relative;
      background: $color-secondary;
      padding: vwsp(30) vwsp(19);
      box-sizing: border-box;
      @include mq(md) {
        width: 50%;
        max-width: 600px;
        border-radius: 60px;
        padding: 52px 28px;
        position: absolute;
        top: -70px;
        left: 0;
        z-index: 1;
      }
    }

    .icon-deco-capsule-value {
      top: vwsp(-350);
      right: 0;
      transform: rotate(270deg);
      @include mq(md) {
        top: vwpc(-34);
        right: auto;
        left: calc(50% - vwpc(75));
        transform: translateX(-50%) rotate(290deg);
      }
      @include mq(xxl) {
        top: -34px;
        left: calc(50% - 75px);
      }
    }

    .icon-deco-tablet-value {
      bottom: -20px;
      right: 38px;
      @include mq(md) {
        bottom: vwpc(-20);
        right: vwpc(34);
      }
      @include mq(xxl) {
        bottom: -20px;
        right: 34px;
      }
    }
  }

  // 利用料金
  .section-price {
    padding: 61px 0 170px;
    background: $color-primary-7;
    @include mq(md) {
      padding: 125px 0 160px;
    }

    .price-panel {
      @include panel-border(4px, 20px);
      padding: 49px vwsp(22) 46px;
      position: relative;
      @include mq(md) {
        padding: vwpc(66) vwpc(52) vwpc(65);
      }
      @include mq(xxl) {
        padding: 66px 52px 65px;
      }

      .price-panel-lead {
        @include panel-lead;
        @include mq(md) {
          margin-bottom: 70px;
        }
      }

      .price-panel-lead-title {
        @include panel-lead-title;
        font-size: vwsp(28);
        @include mq(xs) {
          font-size: 28px;
        }
        @include mq(md) {
          font-size: vwpc(32);
          width: vwpc(166);
          margin-right: vwpc(38);
        }
        @include mq(xxl) {
          font-size: 32px;
          width: 166px;
          margin-right: 38px;
        }
      }

      .price-panel-lead-text {
        @include panel-lead-text;
        margin-bottom: 35px;
        @include mq(md) {
          max-width: 470px;
        }
      }
    }

    .icon-deco-capsule-left-price {
      top: 65px;
      left: -38px;
      transform: rotate(110deg);
      @include mq(md) {
        width: vwpc(91);
        top: vwpc(30);
        left: vwpc(-47);
      }
      @include mq(xxl) {
        width: 91px;
        top: 30px;
        left: -47px;
      }
    }

    .icon-deco-tablet-left-price {
      bottom: 161px;
      left: -30px;
      @include mq(md) {
        bottom: vwpc(53);
        left: vwpc(-30);
      }
      @include mq(xxl) {
        bottom: 53px;
        left: -30px;
      }
    }

    .icon-deco-tablet-right-price {
      top: -19px;
      right: 90px;
      @include mq(md) {
        top: vwpc(-30);
        right: vwpc(118);
      }
      @include mq(xxl) {
        top: -30px;
        right: 118px;
      }
    }

    .icon-deco-tablet-bottom-price {
      display: none;
      @include mq(md) {
        display: block;
        bottom: vwpc(-36);
        left: vwpc(84);
      }
      @include mq(xxl) {
        bottom: -36px;
        left: 84px;
      }
    }

    .icon-deco-capsule-right-price {
      width: 139px;
      right: -49px;
      bottom: -38px;
      @include mq(md) {
        width: vwpc(216);
        right: vwpc(-40);
        bottom: vwpc(-49);
      }
      @include mq(xxl) {
        width: 216px;
        right: -40px;
        bottom: -49px;
      }
    }
  }

  // ページ下部アイコン
  .service-icon {
    position: relative;
    z-index: 2;

    .icon-deco-yakubato-service {
      width: 205px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .icon-deco-tablet-service {
      display: none;
      @include mq(md) {
        display: block;
        bottom: vwpc(-28);
        left: vwpc(46);
      }
      @include mq(xxl) {
        display: block;
        bottom: -28px;
        left: 46px;
      }
    }
  }

  // アイコン 「01」〜「04」
  $colors: 'green', 'yellow';
  @each $color in $colors {
    @for $i from 1 through 4 {
      .icon-no-0#{$i}-#{$color} {
        position: relative;
        padding-left: 74px;
        @include mq(md) {
          padding-left: 96px;
        }

        &::before {
          content: '';
          display: block;
          width: 53px;
          height: 37px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: url('../img/service/pharmacy/icon_0#{$i}_#{$color}.svg')
            no-repeat
            center/contain;
          @include mq(md) {
            width: 73px;
            height: 49px;
          }
        }
      }
    }
  }
}
