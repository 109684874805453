.btn-search {
  @include btn($color-tertiary, $color-quaternary, $color-text);
  font-size: vwsp(25);
  letter-spacing: 2.4px;
  max-width: 335px;
  height: 55px;
  @include mq(xs) {
    font-size: 25px;
  }
}

.btn-install {
  @include btn($color-tertiary, $color-quaternary, $color-text);
  font-size: vwsp(20);
  letter-spacing: 2.4px;
  max-width: 295px;
  height: 55px;
  @include mq(xs) {
    font-size: 20px;
  }
}

.btn-back {
  @include btn-border($color-primary);
  font-size: 24px;
  max-width: 285px;
  height: 65px;
}

[class^='btn-request'] {
  font-size: vwsp(22);
  flex-direction: column;
  max-width: 610px;
  height: vwsp(90);
  @include mq(xs) {
    font-size: 22px;
    height: 90px;
  }
  @include mq(md) {
    flex-direction: row;
    font-size: 26px;
    height: 70px;
  }

  .logo {
    margin: vwsp(-5) 0 vwsp(6);
    @include mq(xs) {
      margin: -5px 0 6px;
    }
    @include mq(md) {
      margin: -5px 8px 0 0;
    }
  }
}

.btn-request-yakubato {
  @include btn($color-tertiary, $color-quaternary, $color-text);

  .logo {
    width: vwsp(125);
    @include mq(xs) {
      width: 125px;
    }
    @include mq(md) {
      width: 154px;
    }
  }
}

.btn-request-yakubato-hospital {
  @include btn($color-primary-2, $color-primary-4, $white);
}

.btn-request-yakubato-pharmacy {
  @include btn($color-tertiary, $color-quaternary, $color-text);
}

.btn-request-yakubato-hospital,
.btn-request-yakubato-pharmacy {
  .logo {
    width: vwsp(229);
    @include mq(xs) {
      width: 229px;
    }
    @include mq(md) {
      width: 283px;
    }
  }
}

// 医療機関さま向けのサービス紹介ページ
.btn-download,
.btn-contact {
  &::after {
    content: '';
    width: 32px;
    height: 32px;
    margin-left: 4px;
    @include mq(max-md) {
      width: 28px;
      height: 28px;
    }
  }
}

.btn-download {
  @include btn($color-primary-2, $color-primary-4, $white);
  @include font-size(22);
  letter-spacing: 1.32px;
  width: 400px;
  height: 70px;
  margin-bottom: 5px;
  @include mq(max-md) {
    @include font-size(18);
    letter-spacing: 1.08px;
    width: 320px;
    height: 56px;
  }
  @include mq(max-sm) {
    width: 100%;
    max-width: 320px;
  }

  &::after {
    background: url('../img/icon_download_white.svg') no-repeat center/cover;
  }
}

.btn-contact {
  @include btn($color-tertiary, $color-quaternary, $color-text);
  @include font-size(22);
  letter-spacing: 1.32px;
  width: 400px;
  height: 70px;
  margin-bottom: 5px;
  @include mq(max-md) {
    @include font-size(18);
    letter-spacing: 1.08px;
    width: 320px;
    height: 56px;
  }
  @include mq(max-sm) {
    width: 100%;
    max-width: 320px;
  }

  &::after {
    background: url('../img/icon_contact.svg') no-repeat center/cover;
  }
}

// お問い合わせボタン下テキスト
.btn-contact-text {
  @include font-size(16);
  letter-spacing: em(1);
  text-align: center;
  margin-top: 15px;
  @include mq(max-md) {
    @include font-size(14);
    margin-top: 11px;
  }
  @include mq(max-xs) {
    font-size: 3.733vw;
  }
}
