html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  color: $color-text;
  background: $color-secondary;
}

a {
  text-decoration: none;
  color: $color-link;
}

img {
  width: 100%;
  height: auto;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

sup {
  font-size: 60%;
  vertical-align: top;
  position: relative;
  top: em(-5);
}

wbr + span {
  display: inline-block;
}
