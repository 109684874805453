// TOPページ
.toppage {
  overflow: hidden;
  @include mq(top) {
    background: url('../img/top/bg_bottom.png'), url('../img/top/bg_cloud.png'),
      url('../img/top/bg.png');
    background-repeat: repeat-x, repeat, repeat;
    background-position: bottom 30px left -17px, bottom center, center;
    background-size: 1834px auto, 1455px auto, 1400px auto;
    background-attachment: fixed;
  }

  &.main {
    padding-top: 50px;
    @include mq(md) {
      padding-top: 0;
    }
  }

  .container-top {
    max-width: 1200px;
    padding: 0 vwsp(20);
    margin: 0 auto;
    @include mq(top) {
      padding: 0 20px;
    }
  }

  .title-balloon {
    font-size: vwsp(16);
    font-weight: bold;
    letter-spacing: 1.6px;
    position: relative;
    margin-bottom: 11px;
    border: 1px solid $color-primary-2;
    border-radius: 5px;
    background: $white;
    padding: 13px 10px 14px;
    text-align: center;
    box-shadow: 0 0 6px 0 rgba($color-primary-2, 0.4);
    @include mq(xs) {
      font-size: 16px;
    }

    &::before,
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: solid transparent;
      content: '';
    }

    &::before {
      border-color: transparent;
      border-top-color: $color-primary-2;
      border-width: 7px;
    }

    &::after {
      border-color: transparent;
      border-top-color: $white;
      border-width: 5px;
    }
  }

  .top-contents {
    @include mq(top) {
      max-width: 375px;
      margin: 0 auto;
      background: $color-secondary;
      box-shadow: 0 0 25px rgba($color-text, 0.2);
      overflow: hidden;
      position: relative;
      z-index: 500;
    }
  }

  .kv {
    .kv-top {
      height: 300px;
      background: url('../img/top/kv_bg.png') no-repeat center/ 408px auto;
      z-index: 1;
      position: relative;

      .kv-title-ribon {
        font-size: vwsp(20);
        font-weight: bold;
        line-height: 40px;
        color: $white;
        margin-bottom: 15px;
        letter-spacing: 1px;
        @include title-ribon(40px, $color-primary-2);
        padding: 0 13px;
        @include mq(xs) {
          font-size: 20px;
        }
      }

      .kv-title {
        font-size: vwsp(32);
        font-weight: bold;
        line-height: 1.37;
        max-width: 288px;
        margin: 0 auto;
        text-align: center;
        @include mq(xs) {
          font-size: 32px;
        }
      }
    }
  }

  .section-use {
    padding: 58px 0 75px;
    background: url('../img/top/use_bg_bottom.png') repeat-x bottom/ auto 45px,
      $color-primary-3;
    position: relative;
    margin-top: -38px;

    .use-title {
      font-size: vwsp(26);
      color: $white;
      font-weight: bold;
      margin-left: vwsp(-20);
      @include title-ribon(50px, $color-primary-2);
      z-index: 2;
      @include mq(xs) {
        font-size: 26px;
      }
      @include mq(top) {
        margin-left: -20px;
      }
    }

    .use-panel {
      @include panel-border(2px, 10px);
      padding: 42px 20px 15px;
      margin: -25px 0 20px;
    }

    .use-list {
      display: flex;

      .use-item {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;

        .use-item-img {
          margin-bottom: 6px;
        }

        .use-item-text {
          font-size: 14px;
          line-height: 1.36;
          font-weight: bold;
          text-align: center;
          margin: auto 0;
        }
      }
    }
  }

  .section-recommend {
    padding: 35px 0 30px;
    background: $color-primary-5;
    position: relative;

    .icon-deco-tablet-recommend {
      width: 106px;
      bottom: -22px;
      right: -10px;
    }

    .recommend-title {
      font-size: vwsp(26);
      font-weight: bold;
      margin-bottom: 26px;
      text-align: center;
      @include mq(xs) {
        font-size: 26px;
      }

      .icon-headline-line {
        @include title-line;
        padding: 0 vwsp(37);
        @include mq(xs) {
          padding: 0 37px;
        }

        &::before,
        &::after {
          width: 18px;
          height: 28px;
        }
      }
    }

    .recommend-list {
      .recommend-item {
        padding: 20px 23px;
        background: $white;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .recommend-item-img {
          margin-bottom: 20px;
        }

        .recommend-item-title,
        .recommend-item-text {
          font-weight: bold;
          text-align: center;
        }

        .recommend-item-title {
          font-size: 22px;
          line-height: 1.45;
          margin-bottom: 11px;
        }

        .recommend-item-text {
          font-size: 16px;
          line-height: 1.56;
        }
      }
    }
  }

  .section-try {
    padding: 30px 0 20px;
    position: relative;

    .try-title {
      font-size: vwsp(26);
      line-height: 1.46;
      font-weight: bold;
      text-align: center;
      padding-bottom: vwsp(40);
      margin-bottom: 15px;
      position: relative;
      @include mq(xs) {
        font-size: 26px;
        padding-bottom: 40px;
      }

      &::before {
        content: '';
        width: vwsp(349);
        height: vwsp(39);
        background: url('../img/top/try_title.png') no-repeat center/cover;
        position: absolute;
        left: calc(50% - vwsp(8));
        bottom: 0;
        transform: translateX(-50%);
        @include mq(xs) {
          width: 349px;
          height: 39px;
          left: calc(50% - 8px);
        }
      }
    }

    .try-list {
      display: flex;
      margin-bottom: 30px;

      .try-item {
        @include panel-border(2px, 10px);
        width: calc(100% / 2 - 12px);
        display: flex;
        flex-direction: column;
        padding: 21px 10px;

        &:not(:last-child) {
          margin-right: 23px;
        }

        .try-item-img {
          width: 76px;
          margin: 0 auto 12px;
        }

        .try-item-text {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin: auto 0;
        }
      }
    }
  }

  .section-app {
    padding: 20px 0 35px;
    position: relative;

    .app-panel {
      padding: 30px 3.125vw 40px;
      background: $white;
      @include mq(xs) {
        padding: 30px 20px 40px;
      }

      .app-title {
        font-size: 22px;
        line-height: 1.45;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
      }

      .app-wrap {
        display: flex;
        align-items: center;
        max-width: 364px;
        margin: 0 auto;

        .app-img {
          width: 34.667vw;
          @include mq(xs) {
            width: 130px;
          }
        }

        .app-list {
          flex: 1 1 0;

          .app-item {
            font-size: 14px;
            line-height: 1.7;
            font-weight: bold;
            position: relative;
            padding-left: 24px;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            &::before {
              content: '';
              display: block;
              width: 11px;
              height: 6px;
              border-left: 3px solid $color-primary-2;
              border-bottom: 3px solid $color-primary-2;
              transform: rotate(-45deg);
              position: absolute;
              left: 0;
              top: 5px;
            }
          }
        }
      }
    }
  }

  .section-service {
    background: $color-primary-5;
    padding: 30px 0 80px;
    position: relative;

    .icon-deco-smartphone-service {
      width: 77px;
      top: -25px;
      left: -18px;
    }

    .icon-deco-capsule-service {
      width: 103px;
      bottom: -19px;
      left: -14px;
    }

    .icon-deco-cafe-service {
      width: 102px;
      bottom: -19px;
      right: -6px;
    }

    .service-title {
      font-size: vwsp(26);
      line-height: 1.46;
      font-weight: bold;
      text-align: center;
      margin-bottom: 22px;
      @include mq(xs) {
        font-size: 26px;
      }
    }
  }

  .top-menu {
    @include mq(lg) {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .top-menu-inner {
      @include mq(lg) {
        height: 100%;
        max-width: 1287px;
        padding: 0 vwpc(50);
        margin: 0 auto;
      }

      .top-menu-wrap {
        @include mq(lg) {
          width: 100%;
          height: 100%;
          position: relative;
        }

        &::after {
          @include mq(lg) {
            content: '';
            width: 268px;
            height: 268px;
            background: url('../img/top/yakubato.png') repeat-x center/cover;
            position: absolute;
            top: 300px;
            left: calc(50% - 202px);
            transform: translateX(-50%);
          }
        }
      }

      .top-menu-bottom {
        display: none;
        @include mq(md) {
          display: block;
          position: fixed;
          right: vwpc(30);
          bottom: 0;
          width: 160px;
        }
        @include mq(lg) {
          position: absolute;
          right: 0;
          width: 210px;
        }

        .top-menu-bottom-qr {
          @include mq(md) {
            padding-bottom: 15px;
            background: linear-gradient(
              180deg,
              transparent 0%,
              transparent 10%,
              $white 10%,
              $white 100%
            );
            position: relative;
            filter: drop-shadow(0 0 15px rgba($color-text, 0.1));
          }

          &::after {
            @include mq(md) {
              content: '';
              border: 80px solid transparent;
              border-bottom: 53px solid #fff;
              position: absolute;
              bottom: calc(100% - 15px);
              left: 0;
              z-index: -1;
            }
            @include mq(lg) {
              border-width: 0 105px 58px;
            }
          }

          .qr-text {
            @include mq(md) {
              font-size: 13px;
              font-weight: bold;
              line-height: 1.38;
              text-align: center;
              margin-bottom: 10px;
            }
            @include mq(lg) {
              font-size: 16px;
            }
          }

          .qr-img {
            @include mq(md) {
              width: 75px;
              margin: 0 auto;
              @include mq(lg) {
                width: 80px;
              }
            }
          }
        }

        .top-menu-bottom-pagetop {
          @include mq(md) {
            background: $color-primary-3;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .pagetop-text {
            @include mq(md) {
              font-size: 14px;
              letter-spacing: 2px;
              font-weight: bold;
              color: $white;
              position: relative;
              padding-left: 26px;
            }
            @include mq(lg) {
              font-size: 16px;
            }

            &::before {
              @include mq(md) {
                content: '';
                width: 16px;
                height: 11px;
                background: url('../img/icon_arrow_white.svg') repeat-x
                  center/cover;
                position: absolute;
                left: 0;
                top: calc(50% + 1px);
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
}
