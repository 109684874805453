// TOPページ特有（769px〜）
.header-top {
  @include mq(lg) {
    height: 100%;
    z-index: 1;
    position: static;
  }

  &.is-scroll {
    @include mq(md) {
      background: none;
    }
  }

  .header-inner {
    @include mq(md) {
      padding: 0 vwpc(30);
    }
  }

  .header-logo {
    @include mq(lg) {
      width: 199px;
      position: absolute;
      top: 30px;
      left: 0;
    }
  }

  .header-toggle {
    @include mq(lg) {
      display: none;
    }
  }

  .header-nav {
    @include mq(lg) {
      width: 100%;
      height: 100%;
      background: none;
      position: static;
      transform: none;
    }

    .header-nav-right {
      @include mq(lg) {
        position: absolute;
        bottom: 0;
        right: 32px;
        margin-bottom: 303px;
      }

      &::after {
        @include mq(lg) {
          content: '';
          width: 295px;
          height: 401px;
          background: url('../img/top/menu_nomal_bg.png') no-repeat center/cover;
          position: absolute;
          top: -68px;
          right: -55px;
          z-index: -1;
        }
      }

      .header-nav-right-menu {
        @include mq(lg) {
          position: relative;
          margin-bottom: 329px;
        }
      }

      .header-nav-right-title {
        display: none;
        @include mq(lg) {
          display: block;
          font-size: 24px;
          font-weight: bold;
          color: $color-primary-2;
          margin-bottom: 23px;
          text-align: center;
        }
      }
    }

    .header-nav-left {
      @include mq(lg) {
        position: absolute;
        top: 120px;
        left: -15px;
      }
      @media screen and (min-width: 1100px) {
        top: 127px;
        left: 1vw;
      }
      @media screen and (min-width: 1300px) {
        left: 55px;
      }

      &::after {
        @include mq(lg) {
          content: '';
          width: 450px;
          height: 312px;
          background: url('../img/top/menu_service_bg.png') no-repeat
            center/cover;
          position: absolute;
          top: -50px;
          left: -92px;
          z-index: -1;
        }
        @media screen and (min-width: 1100px) {
          width: 505px;
          height: 384px;
          top: -74px;
          left: -105px;
        }
      }
    }

    .header-nav-menu {
      @include mq(lg) {
        background: none;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px 0;
      }

      .menu-item {
        @include mq(lg) {
          background: none;
          position: relative;
        }

        .menu-item-inner {
          @include mq(lg) {
            padding: 0;
          }
        }

        .menu-item-link {
          @include hover-color($color-text);
          @include mq(lg) {
            font-size: 16px;
            line-height: 2;
            position: relative;
          }

          &::before {
            @include mq(lg) {
              background: $color-primary-2;
              width: 8px;
              height: 3px;
              border-radius: 3px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -20px;
              opacity: 0;
              @include transition;
            }
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        .menu-item-headline {
          @include mq(xs) {
            font-size: 18px;
          }
          @include mq(lg) {
            font-size: 15px;
            line-height: 1.56;
            margin-bottom: 12px;
            cursor: auto;
            @include hover-color($color-text);
          }
          @media screen and (min-width: 1100px) {
            font-size: 18px;
          }
        }

        .icon-headline-line {
          @include mq(xs) {
            padding: 0 32px;
          }
          @include mq(lg) {
            padding: 0;
          }

          &::before,
          &::after {
            @include mq(xs) {
              width: 15px;
              height: 25px;
            }
            @include mq(lg) {
              display: none;
            }
          }
        }

        .header-nav-menu-child {
          &.list-service {
            @include mq(lg) {
              width: 275px;
              max-width: none;
            }
            @media screen and (min-width: 1100px) {
              width: 290px;
            }

            .service-item {
              .service-item-link {
                @include mq(lg) {
                  height: 65px;
                }
              }

              .service-item-icon {
                @include mq(lg) {
                  margin-bottom: 5px;
                }
              }

              &.item-hospital,
              &.item-clinic {
                @include mq(lg) {
                  max-width: 130px;
                }
                @media screen and (min-width: 1100px) {
                  max-width: 135px;
                }
              }

              &.item-hospital {
                @media screen and (min-width: 1100px) {
                  margin-right: 20px;
                }
              }

              &.item-pharmacy {
                .service-item-link {
                  height: 43px;

                  .service-item-icon {
                    @include mq(lg) {
                      margin: 0 5px 0 -31px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .is-open + .header-nav {
    transform: translateX(0);
    @include mq(lg) {
      transform: none;
    }
  }
}
